import { Injectable, Inject } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { DatePipe } from '@angular/common';

import { Router } from '@angular/router';
import { Location } from '@angular/common';


import { ToastrService } from 'ngx-toastr';
import { Sales_Details_Tab } from './sales/sales.service';
import { DOCUMENT } from '@angular/common';



import { ThemeSettingComponent } from './settings/theme-setting/theme-setting.component';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';


declare let $: any;

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public access_tocken = "";
  public Server_URL = "http://localhost:5837/";

  public Quotation_Format = "Format1";
  public Qubha_Quotation_Format = "Format1";

  public System_mac_address = "Not found"
  public Area_Map: boolean = false;
  public header_hide: boolean = false;

  
  public NT_Bill_Format = "NT_Format1"
  public Bill_Format = "Format1"
  public Bill_Format1 = "Format6";
  public Vadi_Format = "Format1";
  public NT_Sales_Disp_Text1 = "Open Invoice";

  public Sales_Disp_Text1 = "Open Invoice";
  public Sales_Disp_Text2 = "Open Invoice(with code)";
  public Sales_Disp_Text2_Visblle: boolean = false;
  public Sales_Disp_Text3_Visblle: boolean = false;
  public Rate_From_Item_Master: boolean = false;

  public TCS_Enable: boolean = true;
  public Balance_SMS = "false";
  
  public Customer_ID = [];
  public Purchse_Rate_Validate: boolean = false;

  public Sales_Entry_Page = "/sales/sales-entry";

  public M_Sales_Return_Entry = "/sales/mobile-sales-return-entry";

  public Purchase_entry_page = "/Purchase/purchase-entry";

  public Cpage = "Home";
  public key_value = "";

  public Filter_Type = "contain";

  public isRate_Edit: boolean = false;



  public NT_Printer: string = "false";
  public NT_Address: String = "http://localhost:5837/";

  public DB_Vadi_display: boolean = false;
  public Print_Button: boolean = false;
  public Sales_Profit: boolean = false;

  public Direct_Settlement: boolean = false;

  

  public Grid_MPR_Disp: boolean = false;

  public DF_Bill_Type = "Tax Invoice";
  public Ledger_Update: boolean = true;
  public Cust_Amt: boolean = false;
  public Hot_Stock_Display: boolean = false;

  public Lfrom = "34";
  public Top = 50;
  public Group_ID = "All"
  public Logo_location = "Top_qubha";
  public Logo_Name = "Qubha";
  public Logo_location_Loin = "Chef";
  public Logo_Sub_Name = "Soft";
  public Phone_No = "9597436220";

  public M_Sales_Titel = "Sales Return"
  public M_Sales_P_visible: boolean = true;

  public From_Mobile_Sales_Return: boolean = false;


  public From_ID = "0";
  public To_ID = "0";
  public Type = "Notification";

  public UOM_Price_List: boolean = false;



  public Qmenu: boolean = true;
  public STamilName: boolean = false;
  public Type_Based_Bill_No: boolean = false;
  public Item_Rate_Update: boolean = false;
  public Check_Stock: boolean = false;
  public Filter_text = "";

  public RC_Bill_Amt_Check: boolean = true;

  public Image_disp: boolean = false;
  public Ledger_Type = "1";

  public Group_Enable: boolean = false;
  public Print_Bill: boolean = false;

  public Save_Print: boolean = false;
  public Save_Print_Mobile: boolean = false;
  public Save_New: boolean = false;

  public Stockbase_Sales: boolean = false;


  //public Logo_Name = "GKDMK";
  //public Logo_location = "GKDMK";
  //public Logo_location_Loin = "GKDMK";


  //public Logo_Name="Qubha";
  //public Logo_location="Top_qubha";
  //public Logo_location_Loin="Login_buhari";
  Lable_Order_entry = "Order Entry";
  Lable_Takeaway = "Takeaway";
  Lable_Dining = "Dining";
  Lable_Purchase = "Purchase";
  Lable_Sales = "Sales";
  Lable_Receipt = "Receipt";
  Lable_Payment = "Payment";
  Lable_Expence = "Expence";
  Lable_Daybook = "Day Book";
  Lable_Receivable = "Receivable";
  Lable_Payable = "Payable";
  Lable_Item_Master = "Item Master";
  Lable_Ledger = "LedgerMaster";
  Lable_Reports = "Reports";
  Lable_Minimum_Stock = "Minimum Stock";

  public PRate_disp = "user"
  public U_Rights = ""
  public Item_Group_ = "1";
  public from_customer_page: boolean = false;
  public M_From="";

  public isadd_Page: boolean = false;
  constructor(@Inject(DOCUMENT) private document: Document, private toastr: ToastrService, public datePipe: DatePipe, public http: HttpClient, public router: Router, private _location: Location) {


    if (this.getData()) {

      this.data = JSON.parse(localStorage.getItem('User_Data'));

      try {
        this.Customer_Area = localStorage.getItem('Area');
      } catch { }
      this.Company = "_" + this.data.UM_Company;

   

    }

   

    this.W_height = window.innerHeight;

    this.W_width = window.innerWidth;

    var Today_Date = this.datePipe.transform(new Date(), "dd-MMM-yyyy");

    this.T_Date = this.datePipe.transform(new Date(), "yyyy-MM-dd");




    this.Pay_Date = this.datePipe.transform(new Date(), "yyyy-MM-dd");



    this.Y_Date = this.datePipe.transform(new Date().getDate() - 1, "dd-MMM-yyyy");
    this.Today_Date = Today_Date;



    this.S_From = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    this.S_To = this.datePipe.transform(new Date(), "yyyy-MM-dd");

    this.From_Date = { formatted: Today_Date };
    this.To_Date = { formatted: Today_Date };
    this.Date = { formatted: Today_Date };
    this.Date1 = { formatted: Today_Date };

    this.Due_Date = this.datePipe.transform(new Date(), "yyyy-MM-dd");

    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });
    if (this.Logo_Name == "GKDMK") {
      this.get_dmk_Data();
    }
    this.load_themes('');



  }



  

  public From_Purchase: boolean=false;
  public openModal: boolean = false;
  Open_Modal(Title, page) {
    this.openModal = true;
    $('#small_Module').modal('show');
  }

  Close_Modal() {
    this.openModal = false;
    $('#small_Module').modal('hide');
  }

  public SM_Row = [];
  public Header_Disp = "";

  public add_item_name = "/add-items1";
  btndisable: boolean = false;
  isload: boolean = false;
  public Company = "";
  public CREATED_BY = "";
  public Ref_ID = "0";
  public isadd = "0";
  public Edit_Row: any = {};
  public Selected_Row: any = {};
  public Default_ref_ID: string = "Role";
  public URL_Location = "";
  public Hotel_Reprint = "false";

  public Condi_Sales = "";

  public Checkin_Room_No = "";
  public Room_CIN_ID = "";

  public disp_category: boolean = false;
  public Dispg_code: boolean = true;
  public Dispg_uom: boolean = false;
  public Stock_Check: boolean = true;

  public Sales_Entry_Row_width =
    {
      ID: "5%",
      Code: "10%",
      Name: "23%",
      Desc: "17%",
      Rate: "15%",
      Qty: "15%",
      Amount: "15%"
    };


  public SER_Disp =
    {
      ID: true,
      Code: false,
      Name: true,
      Desc: true,
      Rate: true,
      Qty: true,
      Amount: true
    };




  public Print_Formats = [
    { Type: 'Sales', Format: 'Format1', Order: '', Disp_Text: 'Print Bill' },
    { Type: 'Sales', Format: 'Format2', Order: '', Disp_Text: 'Print Bill' },
    { Type: 'Sales', Format: 'Format3', Order: '', Disp_Text: 'Print Bill' },
    { Type: 'Sales', Format: 'Format4', Order: '', Disp_Text: 'Print Bill' },
    { Type: 'Sales', Format: 'Format5', Order: '', Disp_Text: 'Print Bill' },
    { Type: 'Sales', Format: 'PRS_A4', Order: '', Disp_Text: 'Open Invoice' },
    { Type: 'Sales', Format: 'PRS_A3', Order: '', Disp_Text: 'Print Bill' },
    { Type: 'Sales', Format: 'LSP', Order: '', Disp_Text: 'Print Bill' },
    { Type: 'Sales', Format: 'badhri', Order: '', Disp_Text: 'Print Bill' },
    { Type: 'Sales', Format: 'jaysri', Order: '', Disp_Text: 'Print Bill' },
    { Type: 'Sales', Format: 'PRS_NT_A3', Order: '', Disp_Text: 'Print Bill' },
    { Type: 'Sales', Format: 'Osai', Order: '', Disp_Text: 'Print Bill' },
    { Type: 'Sales', Format: 'Klisters', Order: '', Disp_Text: 'Print Bill' },

    { Type: 'Sales', Format: 'SSN', Order: '', Disp_Text: 'Print Bill' },
    { Type: 'Sales', Format: 'SBR_A3', Order: '', Disp_Text: 'Print Bill' },
    { Type: 'Sales', Format: 'Gnanavika_Bill', Order: '', Disp_Text: 'Print Bill' },
    { Type: 'Sales', Format: 'Gnanavi_New', Order: '', Disp_Text: 'Print Bill' },
    { Type: 'Sales', Format: 'jayashree', Order: '', Disp_Text: 'Print Bill' },
    { Type: 'Sales', Format: 'jayashree_New', Order: '', Disp_Text: 'Print Bill' },


  ];


  get_formats(data) {


    try {
      return this.Print_Formats.filter(e => e.Type == data);
    } catch {

      return [];
    }
  }

  public Sales_Temp_Row: any = {};
  public Item_Row: any = {};

  public Sales_Temp_Row1 = [];
  public Proforma_Details_GF = ['Customer_Name', 'Bill_No'];
  public BOM_Master_GF = [];
  public Amount_Col_GF = ['Pay_Mode','Amount'];

  public Bill_Print_GF = [];
  public Token_Master_GF = [];

  public Quatation_Details_GF = [];
  public Ledger_Master_GF = [];
  public Notification_GF = [];

  public Item_Master_GF = [];
  public Product_Master_GF = [];
  public Purchase_Master_GF = [];
  public Stock_Adjustment_GF = [];

  public Purchase_Order_Details_GF = [];
  public Member_Details_GF = [];
  public Area_Street_Details_GF = [];
  public Production_Details_GF = [];
  public Employee_Details_GF = [];
  public Vadi_Details_GF = [];

  public Menu_Master_GF = ['Module', 'Type', 'Display_Name', 'Route_Link', 'Order_No', 'Icon', 'Remark'];

  public Expense_Details_GF = ['Category_', 'Narration1', 'Amount'];
  public Contra_Details_GF = ['Naration', 'Amount'];
  public M_Sales_Details_GF = ['Customer_Name', 'Bill_No', 'Area'];
  public HSN_Details_GF = ['Customer_Name', 'Invoice_No', 'Invoice_Date'];

  public Sales_Overview_GF = ['Customer_Name', 'Bill_No', 'Area'];
  public Gst_Non_Tax_GF = ['Customer_Name', 'Bill_No', 'Area','Bill_Date','Sub_Total','GST_No'];

  public Sales_Details_GF = ['Customer_Name', 'Bill_No', 'Area'];
  public Delivery_Details_GF = ['Customer_Name', 'DC_No'];

  public Tharikar_Detail_GF = ['Customer_Name', 'Bill_No'];
  public Dyeing_Detail_GF = ['Customer_Name', 'Bill_No'];


  public Damage_Details_GF = ['Customer_Name', 'Damage_No'];
  public Minimum_Stock_GF = ['Item_Name', 'Stock', 'Ledger_name', 'Reorder_Level', 'Min_stock'];

  public Sales_Return_Details_GF = ['Customer_Name', 'Bill_No'];
  public Billwise_Purchase_GF = ['Supplier_Name', 'Bill_No', 'Bill_Date', 'Phone_No', 'Amount'];
  public Customerwise_GF = ['Customer_Name', 'Bill_No', 'Bill_Date', 'Area', 'Phone_No', 'Amount'];
  public Salea_overview_GF = ['Customer_Name', 'Bill_No', 'Bill_Date', 'Area', 'Return_Amount', 'GSTIN'];

  public Billwise_Track_GF = ['Bill_No', 'Bill_Date', 'Profit', 'P_Value', 'S_Value', 'Supplier'];
  public Itemwise_Track_GF = ['Item_Name', 'P_Qty', 'S_Qty', 'Stock_Qty', 'Profit', 'P_Value', 'S_Value', 'Stock_Value'];
  public Supplierwise_Track_GF = ['Supplier', 'S_Value', 'Profit', 'Stock_Value', 'P_Value'];
  public Categorywise_Track_GF = ['Category', 'P_Qty', 'S_Qty', 'Stock_Qty', 'Profit', 'P_Value', 'S_Value', 'Stock_Value'];
  public Price_List_GF = ['Category', 'Item_Name', 'MRP', 'Rate', 'Wholesale_Rate'];
  public Cash_Settlement_GF = ['Cash_Amt', 'Card_Amt', 'Bill_No', 'Bill_Date', 'Cheque_Amt', 'Credit_Amt,', 'Less_Amt'];

  public Track_GF = ['Item_Name', 'Supplier', 'Bill_No', 'Bill_Date', 'Profit', 'S_Value', 'P_Value'];


  public Billwise_Sales_GF = ['Customer_Name', 'Bill_No', 'Bill_Date', 'Phone_No', 'Amount'];
  public Delivery_Detail_GF = ['Customer_Name', 'Item_Name', 'Bill_No', 'Bill_Date', 'Phone_No', 'Amount'];

  public Billwise_Profit_GF = ['Customer_Name', 'Bill_No', 'Bill_Date', 'Phone_No', 'Amount', 'Profit'];
  public Itemwise_Purchase_GF = ['Item_Name', 'Amount', 'Qty'];
  public Productwise_Sales_GF = ['Item_Name', 'Amount', 'Qty'];
  public Userwise_GF = ['Item_Name', 'User_', 'Amount', 'Qty'];
  public Categorywise_Sales_GF = ['Category', 'Amount', 'Qty'];
  public Price_List_Cus_GF = ['Item_Name', 'MRP', 'Bill_No','Bill_Date','LRate'];

  public Itemwise_Sales_GF = ['Item_Name', 'Amount', 'Qty'];
  public Itemwise_Profit_GF = ['Item_Name', 'Amount', 'Qty', 'Profit'];
  public Categorywise_Profit_GF = ['Category', 'Amount', 'Qty', 'Profit'];

  public Userwise_Purchase_GF = ['User', 'Amount', 'count'];

  public Userwise_Sales_GF = ['User', 'Amount', 'count'];
  public Entrywise_col_GF = ['Ledger_Name', 'Bill_No', 'Amount', 'Receipt_No'];
  public Day_book_GF = ['Nar_Type', 'Created_by', 'Pay_Mode_', 'CR_AMT'];
  public Typewise_Daybook_GF = ['Nar_Type', 'DB_AMT', 'AC_Date', 'CR_AMT'];

  public Print_Bar_GF = ['Item_Name', 'Description', 'Uni_Code'];
  public Overall_GF = ['Desc', 'Credit', 'Debit'];
  public Saleswise_Profit_GF = ['Naration', 'CR_AMT', 'DB_Amt'];

  public Daybook_Amt_GF = ['Bank', 'Amount'];

  public Customer_ledger_GF = ['Customer_Name', 'Phone_No', 'Bill_No', 'Net_Amt'];
  public Billwise_out_GF = ['Customer_Name', 'Amount', 'Phone_No'];
  public Billwise_outstanding_GF = ['Customer_Name', 'Amount', 'Phone_No','Bill_No'];
  public Categorywise_outstanding_GF = ['Category', 'Net_Amt', 'Pending'];

  public Billwisepayable_out_GF = ['Customer_Name', 'Amount', 'Phone_No'];
  public Customerwise_outstanding_GF = ['Customer_Name', 'Amount', 'Phone_No'];

  public Customerwise_out_GF = ['Customer_Name', 'Amount', 'Phone_No'];
  public Supplierwise_out_GF = ['Customer_Name', 'Amount', 'Phone_No'];

  public Areawise_out_GF = ['Area', 'Amount', 'count'];
  public Streetwise_out_GF = ['Street', 'Amount', 'count'];

  public Entrywise_exp_GF = ['Category_', 'Narration', 'Created_by'];
  public Itemwise_exp_GF = ['Narration', 'Amount'];
  public Typewise_exp_GF = ['Category', 'Amount'];
  public Userwise_exp_GF = ['User', 'Amount'];
  public Userwise_col_GF = ['Created_By', 'Amount'];
  public Areawise_col_GF = ['Area', 'Amount'];
  public Paymodewise_col_GF = ['Pay_Mode_', 'Amount'];
  public Custemerwise_col_GF = ['Customer', 'Amount'];
  public Entrywise_order_GF = ['Order_No', 'Date', 'Customer_Name', 'Contact_No', 'Amount'];
  public Arearwise_sales_GF = ['Area', 'Amount'];
  public Sales_Personwise_sales_GF = ['Sales_person', 'Amount'];

  public Arearwise_Purchase_GF = ['Area', 'Amount'];

  public Paymodewise_sales_GF = ['Pay_Mode', 'Amount'];
  public Paymodewise_Purchase_GF = ['Pay_Mode', 'Amount'];
  public Supplierwise_purchase_GF = ['Supplier_Name', 'count', 'Amount'];

  public Customerwise_sales_GF = ['Customer_Name', 'count', 'Amount'];
  public Customerwise_Profit_GF = ['Customer_Name', 'count', 'Amount', 'Profit'];
  public Category_stock_GF = ['Category', 'Stock', 'Value'];

  public Current_stock_GF = ['Item_Name', 'Description', 'Category', 'Rate', 'Stock', 'Value', 'MRP'];
  public Itemwise_stock_GF = ['Vour_Type', 'Voucher_No', 'Voucher_Date_', 'Rate'];
  public Stock_report_GF = ['Item_Name', 'Category', 'Opening', 'Rate', 'Inward', 'Outward', 'Closing'];


  public Track_Report = [
    { Menu: 'Billwise', Link: '/report/billwise-track', class: '' },
    { Menu: 'Itemwise', Link: '/report/itemwise-track', class: '' },
    { Menu: 'Supplierwise', Link: '/report/supplier-track', class: '' },
    { Menu: 'Categorywise', Link: '/report/category-track', class: '' },
    { Menu: 'Track Details', Link: '/report/product-track', class: '' },

  ];
  public Sales_Return_Report = [
    { Menu: 'Sales Return', Link: '/report/sales-return-report', class: '' },


  ];
  public Price_List_Report = [
    { Menu: 'Price List', Link: '/reports/price-list', class: '' },


  ];
  public Salse_Report = [
    { Menu: 'Billwise', Link: '/report/billwise-sales', class: '' },
    { Menu: 'Itemwise', Link: '/report/itemwise-sales', class: '' },
    { Menu: 'Categorywise', Link: '/report/categorywise-sales', class: '' },

    { Menu: 'Userwise', Link: '/report/userwise-sales', class: '' },
    { Menu: 'Daywise', Link: '/report/daywise-sales', class: '' },
    { Menu: 'Areawise', Link: '/report/areawise-sales', class: '' },
    { Menu: 'Customerwise', Link: '/report/customerwise-sales', class: '' },
    { Menu: 'Sales-personwise', Link: '/report/sales-personwise', class: '' },
    { Menu: 'Paymodewise', Link: '/report/paymodewise-sales', class: '' },

  ];
  public Delivery_Report = [
    { Menu: 'Customerwise', Link: '/report/delivery-details', class: '' },
    { Menu: 'Productwise', Link: '/report/product-wise', class: '' },
    { Menu: 'Userwise', Link: '/user-wise', class: '' },
    { Menu: 'Brandwise', Link: '/report/brandwise-sales', class: '' },


  ];
  public Salse_Gst_Report = [
    { Menu: 'Sales GST', Link: '/report/gst-report', class: '' },
    { Menu: 'Sales GST(%)', Link: '/report/sales-gst-one', class: '' },
    { Menu: 'GST Non Tax ', Link: '/report/gst-non-tax', class: '' },
    { Menu: 'HSNwise Tax ', Link: '/report/hsnwise-report', class: '' },
    

  ];
  public Purchase_Report = [
    { Menu: 'Billwise', Link: '/report/billwise-purchase', class: '' },
    { Menu: 'Itemwise', Link: '/report/itemwise-purchase', class: '' },
    { Menu: 'Userwise', Link: '/report/userwise-purchase', class: '' },
    { Menu: 'Daywise', Link: '/report/daywise-purchase', class: '' },
    { Menu: 'Areawise', Link: '/report/areawise-purchase', class: '' },
    { Menu: 'supplierwise', Link: '/report/supplierwise-purchase', class: '' },
    { Menu: 'Paymodewise', Link: '/report/paymodewise-purchase', class: '' },

  ];
  public Profit_Report = [
    { Menu: 'Billwise-Profit', Link: '/Billwise-Profit', class: '' },
    { Menu: 'Itemwise-Profit', Link: '/Itemwise-Profit', class: '' },
    { Menu: 'Categorywise-Profit', Link: '/Categorywise-profit', class: '' },

    { Menu: 'Customerwise-Profit', Link: '/Customerwise-Profit', class: '' },
    { Menu: 'Sales-Profit', Link: 'Sales-profit', class: '' },
    { Menu: 'Over-all-Profit', Link: 'Over-all', class: '' },

  ];
  public Outstanding_Report = [
    { Menu: 'Billwise', Link: '/report/billwise-out' },
    { Menu: 'Customerwise', Link: '/report/customerwise-out' },
    { Menu: 'Areawise', Link: '/report/areawise-out' },
    { Menu: 'Due daywise', Link: '/report/duedaywise-out' },
    { Menu: 'Salespersonwise', Link: '/report/salespersonwise-out' },
    { Menu: 'streetwise-out', Link: '/report/streetwise-out' },

  ];
  public Categorywise_Outstanding = [
    { Menu: 'Categorywise', Link: '/report/categorywise_outstanding' },

    { Menu: 'Billwise', Link: '/report/billwise-outstanding' },
    { Menu: 'Customerwise', Link: '/report/customerwise-outstanding' },
    { Menu: 'Areawise', Link: '/report/areawisw-outstanding' },
 
  ];
  public Payable_Outstanding_Report = [
    { Menu: 'Billwise', Link: '/report/billwisepayable-out' },
    { Menu: 'Supplierwise', Link: '/report/supplierwise-out' },

  ];


  public User_Role_Menu = [
    { Menu: 'User Master', Link: '/user-details' },
    { Menu: 'Role Master', Link: '/role-master' },
    { Menu: 'Role Rights', Link: '/role-permissions' }

  ];
  public Takeaway_Menu = [
    { Menu: 'TAKE AWAY', Link: 'hotel/hotel-takeaway1' },
    { Menu: 'DINE-IN', Link: 'hotel/dining' },
    { Menu: 'PICKUP', Link: '#' }

  ];
  public Analysis_Menu = [
    { Menu: 'analysis', Link: '/analysis' },
    { Menu: 'analysis1', Link: '/analysis1' },
    { Menu: 'analysis2', Link: '/analysis2' }

  ];

  public Order_Report = [
    { Menu: 'Entrywise', Link: '/report/entrywise-order' },
    { Menu: 'Itemwise', Link: '/report/itemwise-order' },
    { Menu: 'Userwise', Link: '/report/userwise-order' },
    { Menu: 'Daywise', Link: '/report/daywise-order' },
    { Menu: 'Customerwise', Link: '/report/customerwise-order' },
    { Menu: 'Areawise', Link: '/report/areawise-order' },

  ];

  ''
  public Ledger_Report = [
    { Menu: 'Day Book', Link: '/report/day-book', class: '' },
    { Menu: 'Type wise', Link: '/report/typewise-daybook', class: '' },

    { Menu: 'Customer Ledger', Link: '/report/customer-ledger', class: '' },
    { Menu: 'Supplier Ledger', Link: '/report/supplier-ledger', class: '' }

  ];
  public Dashboard_Report = [
    { Menu: 'Sales Details', Link: '/hotel/hotel-dashboard', class: '' },
    { Menu: 'Analysis', Link: '/hotel/analysis-dashboard', class: '' },

    { Menu: 'Comparison', Link: '/hotel/comparison-dashboard', class: '' },

  ];



  public Stock_Report = [

    { Menu: 'Current Stock', Link: '/report/current-stock', class: '' },
    { Menu: 'Category Stock', Link: '/report/Categorywise-stock', class: '' },
    { Menu: 'Itemwise Stock', Link: '/report/itemwise-stock', class: '' },
    { Menu: 'Stock Report', Link: '/report/stock-report', class: '' }
  ];

  public  Purchase_History = [

    { Menu: 'Purchase History', Link: '/Purchase/purchase-history', class: '' },
   
  ];


  public Expense_Report = [
    { Menu: 'Entrywise', Link: '/report/entrywise-exp' },
    { Menu: 'Itemwise', Link: '/report/itemwise-exp' },
    { Menu: 'Typewise', Link: '/report/typewise-exp' },
    { Menu: 'Userwise', Link: '/report/userwise-exp' },
    { Menu: 'Paymodewise', Link: '/report/paymodewise-exp' },
    { Menu: 'Daywise', Link: '/report/daywise-exp' }
  ];


  public Collection_Report = [

    { Menu: 'Entrywise', Link: '/report/entrywise-col' },
    { Menu: 'Customerwise', Link: '/report/customerwise-col' },
    { Menu: 'Userwise', Link: '/report/userwise-col' },
    { Menu: 'Areawise', Link: '/report/areawise-col' },
    { Menu: 'Paymodewise', Link: '/report/paymodewise-col' }

  ];
  public Customer_Profile = [

    { Menu: 'Overview', Link: '/transaction/customer-overview' },
    { Menu: 'Ledger', Link: '/transaction/customer-ledger' },
    { Menu: 'Sales', Link: '/transaction/customer-sales' },
    { Menu: 'Purchase', Link: '/transaction/customer-purchase' },
    { Menu: 'Payment', Link: '/transaction/customer-payment' }

  ];

  public Dmk_Report = [

    { Menu: 'Entrywise', Link: '/dmk/entrywise' },
    { Menu: 'Statewise', Link: '/dmk/statewise' },
    { Menu: 'Thoguthiwise', Link: '/dmk/thoguthiwise' },
    { Menu: 'Districtwise', Link: '/dmk/districtwise' },

  ];
  public Customerwise_Export = [

    { Field: 'Bill_No', Name: 'Bill No', Align: '' },
    { Field: 'Customer_Name', Name: 'Customer Name', Align: '' },
    { Field: 'Area', Name: 'Area', Align: '' },

    { Field: 'Phone_No', Name: 'Phone No', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' },
    { Field: 'Payment', Name: 'Payment', Align: 'right' }

  ];
  public Billwise_sales_Export = [

    { Field: 'Bill_No', Name: 'Bill No', Align: '' },
    { Field: 'Bill_Date', Name: 'Date', Align: '' },
    { Field: 'Customer_Name', Name: 'Customer Name', Align: '' },
    { Field: 'Phone_No', Name: 'Phone No', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }

  ];

  public Customerwise_track_Export = [

    { Field: 'Bill_No', Name: 'Bill No', Align: '' },
    { Field: 'Date', Name: 'Date', Align: '' },
    { Field: 'Customer_Name', Name: 'Customer Name', Align: '' },
    { Field: 'Item_Name', Name: 'Item Name', Align: '' },
    { Field: 'Area', Name: 'Area', Align: '' },

    { Field: 'MRP', Name: 'MRP', Align: 'right' },
    { Field: 'Qty', Name: 'Qty', Align: 'right' },
    { Field: 'Free', Name: 'Free', Align: 'right' },
    { Field: 'Net_Amt', Name: 'Amount', Align: 'right' },

  ];

  public Productwise_track_Export = [

    { Field: 'Bill_No', Name: 'Bill No', Align: '' },
    { Field: 'Date', Name: 'Date', Align: '' },
    { Field: 'Customer_Name', Name: 'Customer Name', Align: '' },
    { Field: 'Item_Name', Name: 'Item Name', Align: '' },
    { Field: 'Qty', Name: 'Qty', Align: 'right' },
    { Field: 'Free', Name: 'Free', Align: 'right' },
    { Field: 'Unit_Price', Name: 'Rate', Align: 'right' },

  ];
  public Billwise_Track_Export = [

    { Field: 'Bill_No', Name: 'Bill No', Align: '' },
    { Field: 'Bill_Date', Name: 'Date', Align: '' },
    { Field: 'Supplier', Name: 'Supplier Name', Align: '' },
    { Field: 'P_Value', Name: 'P Value', Align: 'right' },
    { Field: 'S_Value', Name: 'S Value', Align: 'right' },
    { Field: 'Stock_Value', Name: 'Stock Value', Align: 'right' },
    { Field: 'Profit', Name: 'Profit', Align: 'right' }


  ];
  public Track_Export = [

    { Field: 'Bill_No', Name: 'Bill No', Align: '' },
    { Field: 'Bill_Date', Name: 'Date', Align: '' },
    { Field: 'Supplier', Name: 'Supplier Name', Align: '' },
    { Field: 'Item_Name', Name: 'Item Name', Align: '' },
    { Field: 'P_Qty', Name: 'P Qty', Align: 'right' },
    { Field: 'S_Qty', Name: 'S Qty', Align: 'right' },
    { Field: 'Stock_Qty', Name: 'Stock Qty', Align: 'right' },

    { Field: 'P_Value', Name: 'P Value', Align: 'right' },
    { Field: 'S_Value', Name: 'S Value', Align: 'right' },
    { Field: 'Stock_Value', Name: 'Stock Value', Align: 'right' },
    { Field: 'Profit', Name: 'Profit', Align: 'right' }


  ];
  public Supplierwise_Track_Export = [

    { Field: 'Supplier', Name: 'Supplier Name', Align: '' },
    { Field: 'P_Value', Name: 'P Value', Align: 'right' },
    { Field: 'S_Value', Name: 'S Value', Align: 'right' },
    { Field: 'Stock_Value', Name: 'Stock Value', Align: 'right' },
    { Field: 'Profit', Name: 'Profit', Align: 'right' }


  ];
  public Minimum_Stock_Export = [

    { Field: 'Ledger_name', Name: 'Supplier Name', Align: '' },
    { Field: 'Item_Name', Name: 'Item Name', Align: '' },
    { Field: 'Reorder_Level', Name: 'Reorder Level', Align: 'right' },
    { Field: 'Min_stock', Name: 'Min stock', Align: 'right' },
    { Field: 'Stock', Name: 'Stock', Align: 'right' }


  ];
  public Itemwise_Track_Export = [

    { Field: 'Item_Name', Name: 'Item Name', Align: '' },
    { Field: 'P_Qty', Name: 'P Qty', Align: 'right' },
    { Field: 'S_Qty', Name: 'S Qty', Align: 'right' },
    { Field: 'Stock_Qty', Name: 'Stock Qty', Align: 'right' },
    { Field: 'P_Value', Name: 'P Value', Align: 'right' },
    { Field: 'S_Value', Name: 'S Value', Align: 'right' },
    { Field: 'Stock_Value', Name: 'Stock Value', Align: 'right' },
    { Field: 'Profit', Name: 'Profit', Align: 'right' }


  ];
  public Categorywise_Track_Export = [

    { Field: 'Category', Name: 'Category', Align: '' },
    { Field: 'P_Qty', Name: 'P Qty', Align: 'right' },
    { Field: 'S_Qty', Name: 'S Qty', Align: 'right' },
    { Field: 'Stock_Qty', Name: 'Stock Qty', Align: 'right' },
    { Field: 'P_Value', Name: 'P Value', Align: 'right' },
    { Field: 'S_Value', Name: 'S Value', Align: 'right' },
    { Field: 'Stock_Value', Name: 'Stock Value', Align: 'right' },
    { Field: 'Profit', Name: 'Profit', Align: 'right' }


  ];
  public Delivery_Details_Export = [

    { Field: 'Bill_No', Name: 'Bill No', Align: '' },
    { Field: 'Bill_Date', Name: 'Date', Align: '' },
    { Field: 'Customer_Name', Name: 'Customer Name', Align: '' },
    { Field: 'Area', Name: 'Area', Align: '' },
    { Field: 'Item_Name', Name: 'Product Name', Align: '' },
    { Field: 'Qty', Name: 'Qty', Align: '' },
    { Field: 'Free', Name: 'Free', Align: '' },
    { Field: 'MRP', Name: 'MRP', Align: 'right' },


    { Field: 'Net_Amt', Name: 'Amount', Align: 'right' }

  ];

  public Billwise_Purchase_Export = [

    { Field: 'Bill_No', Name: 'Bill No', Align: '' },
    { Field: 'Bill_Date', Name: 'Date', Align: '' },
    { Field: 'Supplier_Name', Name: 'Suppier Name', Align: '' },
    { Field: 'Contact_No', Name: 'Phone No', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }

  ];
  public Billwise_Profit_Export = [

    { Field: 'Bill_No', Name: 'Bill No', Align: '' },
    { Field: 'Bill_Date', Name: 'Date', Align: '' },
    { Field: 'Customer_Name', Name: 'Customer Name', Align: '' },
    { Field: 'Phone_No', Name: 'Phone No', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' },
    { Field: 'Profit', Name: 'Profit', Align: 'right' }

  ];
  public Ledger_Pending_Export = [

    { Field: 'label', Name: 'Bill No', Align: '' },
    { Field: 'Bill_Date', Name: 'Date', Align: '' },
    { Field: 'days', Name: 'Days', Align: '' },
    { Field: 'Net_Amt', Name: 'Bill Amount', Align: 'right' },
    { Field: 'Bill_Amount', Name: 'Due Amount', Align: 'right' }

  ];

  public Daybook_Export = [

    { Field: 'AC_Date', Name: 'Date', Align: ' ' },
    { Field: 'Nar_Type', Name: 'Type', Align: ' ' },
    { Field: 'Narration1', Name: 'Narration', Align: ' ' },
    { Field: 'Pay_Mode_', Name: 'Pay Mode', Align: '' },
    { Field: 'Created_by', Name: 'User', Align: 'right' },
    { Field: 'CR_AMT', Name: 'Credit', Align: 'right' },
    { Field: 'DB_AMT', Name: 'Debit', Align: 'right' }

  ];
  public Typewise_Daybook_Export = [

    { Field: 'AC_Date', Name: 'Date', Align: ' ' },
    { Field: 'Nar_Type', Name: 'Type', Align: ' ' },
    { Field: 'CR_AMT', Name: 'Credit', Align: 'right' },
    { Field: 'DB_AMT', Name: 'Debit', Align: 'right' }

  ];
  public Overall_Export = [

    { Field: 'Desc', Name: 'Narration', Align: ' ' },
    { Field: 'Credit', Name: 'Credit', Align: 'right' },
    { Field: 'Debit', Name: 'Debit', Align: 'right' }

  ];
  public Sales_Profit_Export = [

    { Field: 'Naration', Name: 'Narration', Align: ' ' },
    { Field: 'CR_AMT', Name: 'Credit', Align: 'right' },
    { Field: 'DB_Amt', Name: 'Debit', Align: 'right' }

  ];
  public Areawise_sales_Export = [
    { Field: 'Area', Name: 'Area ', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }
  ];
  public Sales_Personwise_sales_Export = [
    { Field: 'Sales_person', Name: 'Sales Person ', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }
  ];
  public Areawise_Purchase_Export = [
    { Field: 'Area', Name: 'Area ', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }
  ];
  public Paymodewise_sales_Export = [
    { Field: 'Pay_Mode', Name: 'Pay Mode ', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }
  ];
  public Paymodewise_Purchase_Export = [
    { Field: 'Pay_Mode', Name: 'Pay Mode ', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }
  ];
  public Userwise_Export = [
    { Field: 'Item_Name', Name: 'Item Name', Align: '' },
    { Field: 'User_', Name: 'User', Align: '' },
    { Field: 'MRP', Name: 'MRP', Align: 'right' },
    { Field: 'Qty', Name: 'Qty', Align: 'right' },
    { Field: 'Free', Name: 'Free', Align: 'right' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' },
    { Field: 'Extra_Qty', Name: 'Extra.Qty', Align: 'right' }

  ];
  public Productwise_sales_Export = [
    { Field: 'Item_Name', Name: 'Item Name', Align: '' },
    { Field: 'MRP', Name: 'MRP', Align: 'right' },
    { Field: 'Qty', Name: 'Qty', Align: 'right' },
    { Field: 'Free', Name: 'Free', Align: 'right' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' },
    { Field: 'Extra_Qty', Name: 'Extra.Qty', Align: 'right' }

  ];
  public Itemwise_Sales_Return_Export = [
    { Field: 'Return_No', Name: 'Return No', Align: '' },
    { Field: 'Return_Date', Name: 'Return Date', Align: '' },
    { Field: 'Return_Type', Name: 'Return Type', Align: '' },

    { Field: 'Customer_Name', Name: 'Customer Name', Align: '' },
    { Field: 'Item_Name', Name: 'Item Name', Align: '' },
    { Field: 'MRP', Name: 'MRP', Align: 'right' },
    { Field: 'Qty', Name: 'Qty', Align: 'right' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' },

  ];
  public Itemwise_sales_Export = [
    { Field: 'Item_Name', Name: 'Item Name', Align: '' },
    { Field: 'Qty', Name: 'Quality', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }

  ];
  public Price_List_Report_Export = [
    { Field: 'Item_Name', Name: 'Item Name', Align: '' },
    { Field: 'Bill_Date', Name: 'Bill Date', Align: '' },

    { Field: 'LRate', Name: 'LRate', Align: '' },
    { Field: 'MRP', Name: 'MRP', Align: 'right' }

  ];
  public Categorywise_sales_Export = [
    { Field: 'Category', Name: 'Category', Align: '' },
    { Field: 'Qty', Name: 'Quality', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }

  ];
  public Itemwise_Purchase_Export = [
    { Field: 'Item_Name', Name: 'Item Name', Align: '' },
    { Field: 'Qty', Name: 'Quality', Align: '' },
    { Field: 'Amount', Name: 'P Amount', Align: 'right' },
    { Field: 'S_Rate', Name: 'S Amount', Align: 'right' },
    { Field: 'Profit', Name: 'Profit', Align: 'right' }

  ];
  public Itemwise_Profit_Export = [
    { Field: 'Item_Name', Name: 'Item Name', Align: '' },
    { Field: 'Qty', Name: 'Quality', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' },
    { Field: 'Profit', Name: 'Profit', Align: 'right' }

  ];
  public Categorywise_Profit_Export = [
    { Field: 'Category', Name: 'Category', Align: '' },
    { Field: 'Qty', Name: 'Quality', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' },
    { Field: 'Profit', Name: 'Profit', Align: 'right' },
    { Field: 'Profit_Per', Name: 'Profit(%)', Align: 'right' }

  ];
  public Userwise_sales_Export = [
    { Field: 'User', Name: 'User', Align: '' },
    { Field: 'count', Name: 'Count', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }

  ];
  public Userwise_Purchase_Export = [
    { Field: 'User', Name: 'User', Align: '' },
    { Field: 'count', Name: 'Count', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }

  ];

  public Daywise_sales_Export = [
    { Field: 'Date', Name: 'Date', Align: '' },
    { Field: 'count', Name: 'Count', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }

  ];
  public Daywise_Purchase_Export = [
    { Field: 'Purchase_Date_', Name: 'Date', Align: '' },
    { Field: 'count', Name: 'Count', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }

  ];

  public Customerwise_sales_Export = [
    { Field: 'Customer_Name', Name: 'Customer Name', Align: '' },
    { Field: 'count', Name: 'Count', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' },


  ];
  public Supplierwise_purchase_Export = [
    { Field: 'Supplier_Name', Name: 'Supplier Name', Align: '' },
    { Field: 'count', Name: 'Count', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' },


  ];
  public Customerwise_Profit_Export = [
    { Field: 'Customer_Name', Name: 'Customer Name', Align: '' },
    { Field: 'count', Name: 'Count', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' },
    { Field: 'Profit', Name: 'Profit', Align: 'right' }


  ];

  public Sales_personwise_sales_Export = [
    { Field: 'Date', Name: 'Date', Align: '' },
    { Field: 'count', Name: 'Count', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }

  ];

  public Billwise_outstanding_Export = [
    { Field: 'Bill_No', Name: 'Bill No', Align: '' },
    { Field: 'Bill_Date', Name: 'Bill Date', Align: '' },
    { Field: 'Customer_Name', Name: 'Customer Name', Align: '' },
    { Field: 'Phone_No', Name: 'Phone  No', Align: '' },
    { Field: 'Bill_Amount', Name: 'Bill Amt', Align: 'right' },
    { Field: 'Amount', Name: 'Due Amt', Align: 'right' }

  ];
  public Billwise_out_Export = [
    { Field: 'Bill_No', Name: 'Bill No', Align: '' },
    { Field: 'Bill_Date', Name: 'Bill Date', Align: '' },
    { Field: 'Customer_Name', Name: 'Customer Name', Align: '' },
    { Field: 'Phone_No', Name: 'Phone  No', Align: '' },
    { Field: 'Bill_Amount', Name: 'Bill Amt', Align: 'right' },
    { Field: 'Amount', Name: 'Due Amt', Align: 'right' }

  ];
  public Categorywise_out_Export = [
    { Field: 'Category', Name: 'Category', Align: '' },
   
    { Field: 'Net_Amt', Name: 'Bill Amt', Align: 'right' },
    { Field: 'Pending', Name: 'Due Amt', Align: 'right' }

  ];
  public Ledger_Overview_Export = [
    { Field: 'Date', Name: 'Date', Align: '' },
    { Field: 'Narration1', Name: 'Narration', Align: '' },
    { Field: 'Created_by', Name: 'User', Align: '' },
    { Field: 'CR_Amt', Name: 'Credit', Align: 'right' },
    { Field: 'DB_Amt', Name: 'Debit', Align: 'right' },

  ];
  public Billwisepayable_out_Export = [
    { Field: 'Bill_No', Name: 'Bill No', Align: '' },
    { Field: 'Bill_Date', Name: 'Bill Date', Align: '' },
    { Field: 'Customer_Name', Name: 'Customer Name', Align: '' },
    { Field: 'Phone_No', Name: 'Phone  No', Align: '' },
    { Field: 'Bill_Amount', Name: 'Bill Amt', Align: 'right' },
    { Field: 'Amount', Name: 'Due Amt', Align: 'right' }

  ];

  public Customerwise_out_Export = [

    { Field: 'Customer_Name', Name: 'Customer Name', Align: '' },
    { Field: 'count', Name: 'count', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }


  ];
  
  public Customerwise_outstanding_Export = [

    { Field: 'Customer_Name', Name: 'Customer Name', Align: '' },
    { Field: 'count', Name: 'count', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }


  ];
  public Supplierwise_out_Export = [

    { Field: 'Customer_Name', Name: 'Customer Name', Align: '' },
    { Field: 'count', Name: 'count', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }


  ];

  public Areawise_out_Export = [
    { Field: 'Area', Name: 'Area', Align: '' },
    { Field: 'count', Name: 'count', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }


  ];
  public Areawise_outstanding_Export = [
    { Field: 'Area', Name: 'Area', Align: '' },
    { Field: 'count', Name: 'count', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }


  ];
  public Streetwise_out_Export = [
    { Field: 'Street', Name: 'Street', Align: '' },
    { Field: 'count', Name: 'count', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }



  ];


  public Duedaywise_out_Export = [

    { Field: 'Dues', Name: 'Dues', Align: '' },
    { Field: 'count', Name: 'Bills Count', Align: '' },
    { Field: 'Amount', Name: 'Due Amt', Align: 'right' }

  ];

  public Salespersonwise_out_Export = [

    { Field: 'Sales_Person', Name: 'Sales Man', Align: '' },
    { Field: 'count', Name: 'Bills', Align: '' },
    { Field: 'Amount', Name: 'Due Amt', Align: 'right' }
  ];

  public Itemwise_order_Export = [

    { Field: 'Item_Name', Name: 'Item Name', Align: '' },
    { Field: 'Qty', Name: 'Qty', Align: '' },
    { Field: 'Net_Amt', Name: 'Amount', Align: 'right' }
  ];

  public Customerwise_order_Export = [
    { Field: 'Customer_Name', Name: 'Customer Name', Align: '' },
    { Field: 'count', Name: 'count', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }
  ];

  public Areawise_order_Export = [

    { Field: 'Area', Name: 'Area Name', Align: '' },
    { Field: 'count', Name: 'count', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }


  ];
  public Daywise_order_Export = [

    { Field: 'Order_Date_', Name: 'Date', Align: '' },
    { Field: 'count', Name: 'count', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }


  ];
  public Userwise_order_Export = [

    { Field: 'User', Name: 'User', Align: '' },
    { Field: 'count', Name: 'count', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }


  ];
  public Entrywise_order_Export = [

    { Field: 'Order_No', Name: 'Order No', Align: '' },
    { Field: 'Date', Name: 'Date    ', Align: '' },
    { Field: 'Customer_Name', Name: 'Customer Name         ', Align: '' },
    { Field: 'Contact_No', Name: 'Contact No', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }
  ];

  public Pending_order_Export = [

    { Field: 'Order_No', Name: 'Order No', Align: '' },
    { Field: 'Date', Name: 'Date    ', Align: '' },
    { Field: 'Customer_Name', Name: 'Customer Name         ', Align: '' },
    { Field: 'Contact_No', Name: 'Contact No', Align: '' },
    { Field: 'Net_Amt', Name: 'Amount', Align: 'right' }
  ];

  public Itemwisepending_order_Export = [

    { Field: 'Item_Name', Name: 'Item Name      ', Align: '' },
    { Field: 'Bag', Name: ' Bag    ', Align: 'right' },
    { Field: 'Pcs', Name: ' Kg     ', Align: 'right' },
    { Field: 'qty', Name: ' Total Kg ', Align: 'right' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }

  ];
  public Itemwise_return_Export = [

    { Field: 'Item_Name', Name: 'Item Name      ', Align: '' },
    { Field: 'Bag', Name: ' Bag    ', Align: 'right' },
    { Field: 'Pcs', Name: ' Kg     ', Align: 'right' },
    { Field: 'qty', Name: ' Total Kg ', Align: 'right' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' }

  ];
  public Price_List_Export = [
    { Field: 'Category', Name: 'Brand', Align: '' },
    { Field: 'Item_Name', Name: 'Item Name', Align: '' },
    { Field: 'Wholesale_Rate', Name: 'Wholesale Rate', Align: 'right' },
    { Field: 'Rate', Name: 'Rate', Align: 'right' },
    { Field: 'MRP', Name: 'MRP', Align: 'right' }

  ];
  public Monthlywise_Export = [
    { Field: 'Category', Name: 'Category', Align: '' },
    { Field: 'Jan', Name: 'Jan', Align: '' },
    { Field: 'Feb', Name: 'Feb', Align: '' },
    { Field: 'Mar', Name: 'Mar', Align: '' },
    { Field: 'Apr', Name: 'Apr', Align: '' },
    { Field: 'May', Name: 'May', Align: '' },
    { Field: 'Jun', Name: 'Jun', Align: '' },
    { Field: 'Jul', Name: 'Jul', Align: '' },
    { Field: 'Aug', Name: 'Aug', Align: '' },
    { Field: 'Sep', Name: 'Sep', Align: '' },
    { Field: 'Oct', Name: 'Oct', Align: '' },
    { Field: 'Nov', Name: 'Nov', Align: '' },
    { Field: 'Dec', Name: 'Dec', Align: '' },



  ];
  public Cash_Settlement_Export = [
    { Field: 'Bill_No', Name: 'Bill No', Align: '' },
    { Field: 'Bill_Date', Name: 'Bill Date', Align: '' },
    { Field: 'Cash_Amt', Name: 'Cash_Amt', Align: 'right' },
    { Field: 'Cheque_Amt', Name: 'Cheque Amt', Align: 'right' },
    { Field: 'Card_Amt', Name: 'Card Amt', Align: 'right' },
    { Field: 'Gpay_Amt', Name: 'Gpay Amt', Align: 'right' },
    { Field: 'Credit_Amt', Name: 'Credit Amt', Align: 'right' },
    { Field: 'Less_Amt', Name: 'Less Amt', Align: 'right' }

  ];
  public Item_Master_Export = [
    { Field: 'Display_order', Name: 'order', Align: '' },
    { Field: 'Category', Name: 'Category', Align: '' },
    { Field: 'Item_Name', Name: 'Item Name', Align: '' },
    { Field: 'Description', Name: 'Tamil Name', Align: '' },
    { Field: 'Bag_Qty', Name: 'Bag Qty', Align: '' },
    { Field: 'Rate', Name: 'Rate', Align: '' }
  ];
  public Pincode_Master_Export = [
    { Field: 'Pin_Code', Name: 'Pincode', Align: '' },
    { Field: 'Area', Name: 'Area', Align: '' },
    { Field: 'Distric', Name: 'Distric', Align: '' },
    { Field: 'Country', Name: 'Country', Align: '' },

  ];
  public Tocken_Master_Export = [
    { Field: 'Compnay_Name', Name: 'Compnay Name', Align: '' },
    { Field: 'Email_ID', Name: 'Email ID', Align: '' },
    { Field: 'Phone_No', Name: 'Phone No', Align: '' },
    { Field: 'Priority', Name: 'Priority', Align: '' },
    { Field: 'Module_Name', Name: 'Module Name', Align: '' },
    { Field: 'Tocken_Status', Name: 'Tocken Status', Align: '' }
  ];
  public Product_Master_Export = [
    { Field: 'Display_order', Name: 'order', Align: '' },
    { Field: 'Category', Name: 'Category', Align: '' },
    { Field: 'Item_Name', Name: 'Item Name', Align: '' },
    { Field: 'Description', Name: 'Tamil Name', Align: '' },
    { Field: 'Bag_Qty', Name: 'Bag Qty', Align: '' },
    { Field: 'Rate', Name: 'Rate', Align: '' }
  ];
  public BOM_Master_Export = [
    { Field: 'BOM_Name', Name: 'BOM Name', Align: '' },
    { Field: 'BOM_Qty', Name: 'BOM Qty', Align: '' },
    { Field: 'RM_Name', Name: 'RM Name', Align: '' },
    { Field: 'RM_Qty', Name: 'RM Qty', Align: '' },

  ];



  public Stock_report_Export = [
    { Field: 'Item_Name', Name: 'Item Name', Align: '' },
    { Field: 'Category', Name: 'Category', Align: '' },
    { Field: 'Rate', Name: 'Rate', Align: '' },
    { Field: 'Opening', Name: 'Opening', Align: '' },
    { Field: 'Inward', Name: 'Inward', Align: '' },
    { Field: 'Outward', Name: 'Outward', Align: 'right' },
    { Field: 'Closing', Name: 'Closing', Align: 'right' }

  ];
  public Curent_Stock_Export = [
    { Field: 'Category', Name: 'Category', Align: '' },

    { Field: 'Item_Name', Name: 'Item Name', Align: '' },
    { Field: 'MRP', Name: 'MRP', Align: 'right' },
    { Field: 'Rate', Name: 'Rate', Align: 'right' },
    { Field: 'Stock', Name: 'Stock', Align: 'right' },
    { Field: 'Value', Name: 'Value', Align: 'right' },


  ];
  public Category_Stock_Export = [
    { Field: 'Category', Name: 'Category', Align: '' },

    { Field: 'Stock', Name: 'Stock', Align: 'right' },
    { Field: 'Value', Name: 'Value', Align: 'right' },


  ];
  public Itemwise_Stock_Export = [
    { Field: 'Vour_Type', Name: 'Vour Type', Align: '' },
    { Field: 'Voucher_No', Name: 'Voucher No', Align: '' },
    { Field: 'Voucher_Date_', Name: 'Voucher Date', Align: '' },
    { Field: 'Rate', Name: 'Rate', Align: '' },
    { Field: 'Inward_Qty', Name: 'Inward Qty', Align: '' },
    { Field: 'Outward_Qty', Name: 'Outward Qty', Align: 'right' },
    { Field: 'Balance', Name: 'Balance', Align: 'right' },


  ];
  public Sales_Gst_Export = [
    { Field: 'Bill_No', Name: 'Bill No', Align: '' },
    { Field: 'Bill_Date', Name: 'Bill Date', Align: '' },
    { Field: 'Customer_Name', Name: 'Customer Name', Align: '' },
    { Field: 'GST_No', Name: 'GST No', Align: 'right' },
    { Field: 'Taxable_Amount', Name: 'Taxable', Align: 'right' },
    { Field: 'IGST_Amt', Name: 'IGST', Align: 'right' },
    { Field: 'SGST_Amt', Name: 'SGST', Align: 'right' },
    { Field: 'CGST_Amt', Name: 'CGST', Align: 'right' },
    { Field: 'Tax_Amt', Name: 'Tax', Align: 'right' },
    { Field: 'Net_Amt', Name: 'Amount', Align: 'right' }


  ];
  public HSNWise_Gst_Export = [
    { Field: 'Invoice_No', Name: 'Bill No', Align: '' },
    { Field: 'Invoice_Date', Name: 'Bill Date', Align: '' },
    { Field: 'Customer_Name', Name: 'Customer Name', Align: '' },
    { Field: 'GST_No', Name: 'GST No', Align: 'right' },
    { Field: 'HSN_Code', Name: 'HSN_Code', Align: 'right' },

    { Field: 'Qty', Name: 'Qty', Align: 'right' },

    { Field: 'Taxable', Name: 'Taxable', Align: 'right' },
    { Field: 'GST_Per', Name: 'GST(%)', Align: 'right' },

    { Field: 'IGST', Name: 'IGST', Align: 'right' },
    { Field: 'SGST', Name: 'SGST', Align: 'right' },
    { Field: 'Net', Name: 'Amount', Align: 'right' }


  ];
  public Sales_Gst_one_Export = [
    { Field: 'Bill_No', Name: 'Bill No', Align: '' },
    { Field: 'Bill_Date', Name: 'Bill Date', Align: '' },
    { Field: 'Customer_Name', Name: 'Customer Name', Align: '' },
    { Field: 'GST_No', Name: 'GST No', Align: 'right' },
    { Field: 'Area', Name: 'Area', Align: '' },
    { Field: 'Sub_Total', Name: 'Amount', Align: 'right' },
    { Field: 'Disc_Amt', Name: 'Disc Amt', Align: 'right' },
    { Field: 'Taxable_Amount', Name: 'Gross Amt', Align: 'right' },
    { Field: 'GST_Taxable_0', Name: '0PerAmt', Align: 'right' },
    { Field: 'GST_Taxable_5', Name: '5PerAmt', Align: 'right' },
    { Field: 'GST_Taxable_12', Name: '12PerAmt', Align: 'right' },
    { Field: 'GST_Taxable_18', Name: '18PerAmt', Align: 'right' },
    { Field: 'GST_Taxable_28', Name: '28PerAmt', Align: 'right' },
    { Field: 'GST_Tax_0', Name: '0Per', Align: 'right' },
    { Field: 'GST_Tax_5', Name: '5Per', Align: 'right' },
    { Field: 'GST_Tax_12', Name: '12Per', Align: 'right' },
    { Field: 'GST_Tax_18', Name: '18Per', Align: 'right' },
    { Field: 'GST_Tax_28', Name: '28Per', Align: 'right' },
    { Field: 's_Tax', Name: 'GST_Amt', Align: 'right' },
    { Field: 'Net_Amt', Name: 'Net Amt', Align: 'right' }


  ];
  public Gst_Non_Tax_Export = [
    { Field: 'Bill_No', Name: 'Bill No', Align: '' },
    { Field: 'Bill_Date', Name: 'Bill Date', Align: '' },
    { Field: 'Customer_Name', Name: 'Customer Name', Align: '' },
    { Field: 'GST_No', Name: 'GST No', Align: 'right' },
    { Field: 'Area', Name: 'Area', Align: '' },
    { Field: 'Sub_Total', Name: 'Amount', Align: 'right' },
    { Field: 'Disc_Amt', Name: 'Disc Amt', Align: 'right' },
    { Field: 'Taxable_Amount', Name: 'Gross Amt', Align: 'right' },
    { Field: 'GST_Taxable_0', Name: '0PerAmt', Align: 'right' },
    { Field: 'GST_Taxable_5', Name: '5PerAmt', Align: 'right' },
    { Field: 'GST_Taxable_12', Name: '12PerAmt', Align: 'right' },
    { Field: 'GST_Taxable_18', Name: '18PerAmt', Align: 'right' },
    { Field: 'GST_Taxable_28', Name: '28PerAmt', Align: 'right' },
    { Field: 'GST_Tax_0', Name: '0Per', Align: 'right' },
    { Field: 'GST_Tax_5', Name: '5Per', Align: 'right' },
    { Field: 'GST_Tax_12', Name: '12Per', Align: 'right' },
    { Field: 'GST_Tax_18', Name: '18Per', Align: 'right' },
    { Field: 'GST_Tax_28', Name: '28Per', Align: 'right' },
    { Field: 's_Tax', Name: 'GST_Amt', Align: 'right' },
    { Field: 'Net_Amt', Name: 'Net Amt', Align: 'right' }


  ];
  public B2C_Gst_Export = [
    { Field: 'Bill_No', Name: 'Bill No', Align: '' },
    { Field: 'Bill_Date', Name: 'Bill Date', Align: '' },
    { Field: 'Supplier_Name', Name: 'Supplier Name', Align: '' },
    { Field: 'GST_No', Name: 'GST No', Align: 'right' },
    { Field: 'Taxable_Amount', Name: 'Taxable', Align: 'right' },
    { Field: 'IGST_Amt', Name: 'IGST', Align: 'right' },
    { Field: 'SGST_Amt', Name: 'SGST', Align: 'right' },
    { Field: 'CGST_Amt', Name: 'CGST', Align: 'right' },
    { Field: 'Tax_Amt', Name: 'Tax', Align: 'right' },
    { Field: 'Net_Amt', Name: 'Amount', Align: 'right' }


  ];
  public Entrywise_Collection_Export = [
    { Field: 'Receipt_No', Name: 'Receipt No', Align: '' },
    { Field: 'RCDate_', Name: 'RC Date', Align: '' },
    { Field: 'Bill_No', Name: 'Bill_No', Align: '' },
    { Field: 'Ledger_Name', Name: 'Ledger Name', Align: '' },
    { Field: 'Created_By', Name: 'Created By', Align: '' },
    { Field: 'Pay_Mode_', Name: 'Pay Mode', Align: 'right' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' },


  ];
  public Areawise_Collection_Export = [
    { Field: 'Area', Name: 'Area', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' },


  ];
  public Paymodewise_Collection_Export = [
    { Field: 'Pay_Mode_', Name: 'Pay Mode', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' },


  ];
  public Customerwise_Collection_Export = [
    { Field: 'Ledger_Name', Name: 'Customer', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' },


  ];
  public Userwise_Collection_Export = [
    { Field: 'Created_By', Name: 'User', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' },


  ];
  public Ledger_Master_Exel_Export = [
    { Field: 'Ledger_Name', Name: 'Ledger Name', Align: '' },
    { Field: 'Short_Name', Name: 'Short Name', Align: '' },
    { Field: 'Phone_No', Name: 'Phone No', Align: '' },
    { Field: 'Area', Name: 'Area', Align: '' },
    { Field: 'Street', Name: 'Street', Align: '' },
    { Field: 'Address', Name: 'Address', Align: '' },
    { Field: 'Amount', Name: 'Amount', Align: 'right' },
    { Field: 'VC_Number', Name: 'VC Number', Align: '' },
    { Field: 'Join_Date', Name: 'Join Date', Align: '' },
    { Field: 'Status', Name: 'Status', Align: '' }

  ];
  public Customer_Ledger_Export = [
    { Field: 'Date', Name: 'Date', Align: 'center' },
    { Field: 'Narration1', Name: 'Narration', Align: 'center' },
    { Field: 'CR_Amt', Name: 'Credit', Align: 'right' },
    { Field: 'DB_Amt', Name: 'Debit', Align: 'right' }

  ];
  public Quotation_Export = [];
  public Purchase_Export = [];
  public Adjustment_Export = [];
  public M_Sales_Return_Export = [];

  public Sales_Export = [];
  public Delivery_Export = [];
  public Bill_Print_Export = [];
  public Sales_Overview_Export = [];

  public Tharikar_Export = [];
  public Dyeing_Export = [];
  public Damage_Export = [];

  public Sales_Return_Export = [];

  public Purchase_Order_Export = [];
  public Member_Registration_Export = [];
  public Proforma_Export = [];
  public Ledger_Master_Export = [];
  public Notification_Export = [];

  public Production_Export = [];
  public Employee_Details_Export = [];

  public img_visible: boolean = false;


  public Order_enty_type = "Type1";
  public page_Name = "Order_Entry";

  public from_customer: boolean = false;
  public from_customer_Code = "";

  public DF_Paymode = "244";
  public DF_Checkin_by = "40";
  public DF_Checkin_Type = "35";
  public DF_Stay_purpose = "31";

  public C_Sub_Total = 0;
  public C_Delivery_Charges = 0;
  public C_Taxable = 0;
  public C_IGST = 0;
  public C_SGST = 0;
  public C_CGST = 0;
  public C_Tax = 0;
  public C_Total_Payable = 0;
  public C_Savings = 0;

  public Item_Category = [];
  public Selected_item_Category = "";

  public Date;
  public Date1;
  public From_Date;
  public To_Date;

  public User_Type = "Admin";
  public Document_Type = "Front_Banner";
  public Phone_Pattern = "^((\\+91-?)|0)?[0-9]{10}$";
  public Receipt_Print = "false";

  public Side_Menu: boolean = false;
  public Today_Date = "";
  public S_From = "";
  public S_To = "";
  public S_Bill_Type = "All";
  public S_Thari_Status = "All";
  public S_GST_Type = "All";


  public Item_status = "active"
  public Category = "All";

  public S_Pay_Mode = "0";
  public S_Bill_Mode = "All";
  public S_Area = "All"
  public S_Customer = "All"
  public S_Customer_ = "0"
  public S_Sales_Order_by = "Bill_No";
  public S_Stock = "All"
  public S_Sales_Person = "All"

  public item = "";


  public Y_Date = "";
  public data: any = {};
  public Order_Status = "Pending";
  public Search_User = "All";


  public Customer_Area = "All";
  public item_name_disp = "English";

  public T_Date = "";

  public Pay_Date = "";


  public back() {
    this._location.back();
  }


  public datefromat(data) {
    return this.datePipe.transform(data, "yyyy-MM-dd");
  }

  public date_display(data) {
    return this.datePipe.transform(data, "dd-MMM-yyyy");
  }

  public Rights_Name = "";
  public Rights_Name_ = "";
  public Rights_ID = "";

  getData() {
    return JSON.parse(localStorage.getItem('User_Data'));
  }


  Cash_ID = "";
  Cheque_ID = "";
  Card_ID = "";
  GPay_ID = "";
  Prow = [];
  Pay_Mode_Rows = [];

  get_pay_mode_ID() {

    try {
      this.Prow = this.Reference_Rows.filter(e => e.Ref_ID == "Pay_Mode");
      this.Pay_Mode_Rows = this.Reference_Rows.filter(e => e.Ref_ID == "Pay_Mode");
      this.Cash_ID = this.Prow.filter(e => e.RGV_vDesciption.toLowerCase() == "cash")[0].RGV_iID;
      this.Cheque_ID = this.Prow.filter(e => e.RGV_vDesciption.toLowerCase() == "cheque")[0]["RGV_iID"];
      this.Card_ID = this.Prow.filter(e => e.RGV_vDesciption.toLowerCase() == "card")[0]["RGV_iID"];
      this.GPay_ID = this.Prow.filter(e => e.RGV_vDesciption.toLowerCase() == "gpay")[0]["RGV_iID"];
      this.Pay_Mode = this.Cash_ID;
    } catch { }
  }


  public pay_Mode_reset() {
    this.Pay_Mode = this.Cash_ID;
    this.Receiving_Bank = "0";
    this.Cheque_No = "";
    this.Cheque_Date = this.Today_Date;
    this.Card_Charge = "";
    this.Remarks = "";

  }


  public isEdit: boolean = false;
  public Pay_Mode = "";
  public Bill_Mode = "Cash";
  public Receiving_Bank = "0";
  public Cheque_No = "";
  public Cheque_Date = this.Today_Date;
  public Card_Charge = "";
  public Remarks = "";



  public Bill_No = "";
  public Bill_Date = "";
  public Due_Date = "";
  public Place_of_Supply = "";
  public Sales_Person = "";


  public Pay_Mode_ID = "";
  public User_Master_Rows = [];
  get_User_Master() {
    this.getc("Api/Common/get_User_Master").subscribe((res: any) => {
      this.User_Master_Rows = JSON.parse(res).record;
    });
  }

  public W_height = 0;
  public W_width = 0;

  lenth: number = 0;
  public order_Rows = [];
  public Orders_sort_by = "order_Date"

  public Invoice_Row_total = 0;
  public Invoice_Row_length = 0;
  public Invoice_Row = [];


  public Exp_Rows = [];
  public Exp_Total = 0;
  get_expense() {
    this.isload = true;
    this.Exp_Rows = [];
    this.get("Api/Transaction/get_Adjustments?From=" + this.S_From + "&To=" + this.S_To + "&Type=Expense&Category=" + this.S_Exp_Category + "&Pay_Mode=" + this.S_Pay_Mode).subscribe((res: any) => {
      this.Exp_Rows = JSON.parse(res).record;
      this.isload = false;
      try {
        this.Exp_Total = 0;
        this.Exp_Total = (this.Exp_Rows.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
      } catch
      { }
    });

  }

  public Income_Rows = [];
  public Income_Total = 0;
  get_Income() {
    this.isload = true;
    this.Income_Rows = [];
    this.get("Api/Transaction/get_Adjustments?From=" + this.S_From + "&To=" + this.S_To + "&Type=Income&Category=" + this.S_Exp_Category + "&Pay_Mode=" + this.S_Pay_Mode).subscribe((res: any) => {
      this.Income_Rows = JSON.parse(res).record;
      this.isload = false;
      try {
        this.Income_Total = 0;
        this.Income_Total = (this.Income_Rows.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
      } catch
      { }
    });

  }

  public Contra_Rows = [];
  public Contra_Total = 0;
  get_Contra() {
    this.isload = true;
    this.Contra_Rows = [];
    this.get("Api/Transaction/get_Contra?From=" + this.S_From + "&To=" + this.S_To + "&Pay_Mode=" + this.S_Pay_Mode).subscribe((res: any) => {
      this.Contra_Rows = JSON.parse(res).record;
      this.isload = false;
      try {
        this.Contra_Total = 0;
        this.Contra_Total = (this.Contra_Rows.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
      } catch
      { }
    });

  }

  public Collection_Row = [];
  public Customerwise_Coll_Row = [];
  public Userwise_Coll_Row = [];
  public Areawise_Coll_Row = [];
  public Paymodewise_Coll_Row = [];

  public Collection_Total_Amt = 0;



  get_Collections_Details() {

    this.isload = true;
    this.get("Api/Invoice/get_Amount_Collection?From=" + this.S_From + "&To=" + this.S_To + "&Customer=" + this.S_customer +"&Paymode=" + this.S_Pay_Mode + "&Area=" + this.S_Area + "&Pay_Mode=" + this.S_Pay_Mode + "&User=" + this.Search_User + "&order_by=Receipt_Date").subscribe((res: any) => {
      this.Collection_Row = JSON.parse(res).record;
      this.isload = false;



      var Ledger_Name = [...new Set(this.Collection_Row.map(item => item.Ledger_Name))];
      this.Customerwise_Coll_Row = [];
      for (let data of Ledger_Name) {
        var Amount_ = (this.Collection_Row.filter(e => e.Ledger_Name == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        this.Customerwise_Coll_Row.push({ Ledger_Name: data, Amount: Amount_ })
      }
      var Created_By = [...new Set(this.Collection_Row.map(item => item.Created_By))];
      this.Userwise_Coll_Row = [];
      for (let data of Created_By) {
        var Amount_ = (this.Collection_Row.filter(e => e.Created_By == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        this.Userwise_Coll_Row.push({ Created_By: data, Amount: Amount_ })
      }
      var Area = [...new Set(this.Collection_Row.map(item => item.Area))];
      this.Areawise_Coll_Row = [];
      for (let data of Area) {
        var Amount_ = (this.Collection_Row.filter(e => e.Area == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        this.Areawise_Coll_Row.push({ Area: data, Amount: Amount_ })
      }
      var Pay_Mode_ = [...new Set(this.Collection_Row.map(item => item.Pay_Mode_))];
      this.Paymodewise_Coll_Row = [];
      for (let data of Pay_Mode_) {
        var Amount_ = (this.Collection_Row.filter(e => e.Area == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        this.Paymodewise_Coll_Row.push({ Pay_Mode_: data, Amount: Amount_ })
      }




      try {

        this.Collection_Total_Amt = (this.Collection_Row.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);


      } catch
      {

      }


    });
  }




  S_State = "All";
  S_District = "All";
  S_Thoguthi = "All";
  S_Desgnation = "All";

  Member_Data_Row = [];
  Thoguthiwise_Member_Row = [];
  Statewise_Member_Row = [];
  Districtwise_Member_Row = [];

  Total_Members = [];
  get_Total_Members() {
    this.isload = true;
    this.getc("Api/Master/get_Total_Members").subscribe((res: any) => {
      this.isload = false;
      this.Total_Members = JSON.parse(res).record;
    });
  }

  get_Member_Data() {

    this.isload = true;
    this.get("Api/Master/get_Member_Data?From=" + this.S_From + "&To=" + this.S_To + "&State=" + this.S_State + "&District=" + this.S_District + "&Thoguthi=" + this.S_Thoguthi + "&Desgnation=" + this.S_Desgnation + "&User=" + this.Search_User + "&Order=Name").subscribe((res: any) => {
      this.Member_Data_Row = JSON.parse(res).record;
      this.isload = false;

      var Thoguthi = [...new Set(this.Member_Data_Row.map(item => item.Thoguthi))];
      this.Thoguthiwise_Member_Row = [];
      for (let data of Thoguthi) {
        var count = [...new Set((this.Member_Data_Row.filter(e => e.Thoguthi == data)).map(item => item.ID))].length;
        this.Thoguthiwise_Member_Row.push({ 'Name': data, 'Count': count })
      }


      var State = [...new Set(this.Member_Data_Row.map(item => item.State))];
      this.Statewise_Member_Row = [];
      for (let data of State) {
        var count = [...new Set((this.Member_Data_Row.filter(e => e.State == data)).map(item => item.ID))].length;
        this.Statewise_Member_Row.push({ 'Name': data, 'Count': count })
      }


      var District = [...new Set(this.Member_Data_Row.map(item => item.District))];
      this.Districtwise_Member_Row = [];
      for (let data of District) {
        var count = [...new Set((this.Member_Data_Row.filter(e => e.District == data)).map(item => item.ID))].length;
        this.Districtwise_Member_Row.push({ 'Name': data, 'Count': count })
      }

    });
  }




  public Exp_Category = [];
  public S_Exp_Category = "0";
  public Expenses_Row = [];
  public Exp_Category_Row = [];

  public Exp_Userwise_Row = [];
  public Exp_Paymodewise_Row = [];

  public Exp_Daywise_Row = [];
  public Exp_itemwise_Row = [];

  public Total_Expense_Amount = 0;


  get_Expense_Details() {

    this.isload = true;
    this.get("Api/Invoice/get_expenses?From=" + this.S_From + "&To=" + this.S_To + "&Type=Expense&Category=" + this.S_Exp_Category + "&Paymode=" + this.S_Pay_Mode + "&User=" + this.Search_User + "&order_by=Date").subscribe((res: any) => {
      this.Expenses_Row = JSON.parse(res).record;
      this.isload = false;



      try {

        this.Total_Expense_Amount = (this.Expenses_Row.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);


      } catch
      {

      }


      var Item = [...new Set(this.Expenses_Row.map(item => item.Category_))];
      this.Exp_Category_Row = [];
      for (let data of Item) {
        var Amount_ = (this.Expenses_Row.filter(e => e.Category_ == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        this.Exp_Category_Row.push({ Category: data, Qty: 0, Amount: Amount_ })
      }


      var Created_by = [...new Set(this.Expenses_Row.map(item => item.Created_by))];
      this.Exp_Userwise_Row = [];
      for (let data of Created_by) {
        var Amount_ = (this.Expenses_Row.filter(e => e.Created_by == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        this.Exp_Userwise_Row.push({ User: data, Qty: 0, Amount: Amount_ })
      }


      var Pay_Mode_ = [...new Set(this.Expenses_Row.map(item => item.Pay_Mode_))];
      this.Exp_Paymodewise_Row = [];
      for (let data of Pay_Mode_) {
        var Amount_ = (this.Expenses_Row.filter(e => e.Pay_Mode_ == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        this.Exp_Paymodewise_Row.push({ Pay_Mode_: data, Qty: 0, Amount: Amount_ })
      }


      var Date = [...new Set(this.Expenses_Row.map(item => item.Date))];
      this.Exp_Daywise_Row = [];
      for (let data of Date) {
        var Amount_ = (this.Expenses_Row.filter(e => e.Date == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        this.Exp_Daywise_Row.push({ Date: data, Qty: 0, Amount: Amount_ })
      }




      var Narration = [...new Set(this.Expenses_Row.map(item => item.Narration))];
      this.Exp_itemwise_Row = [];
      for (let data of Narration) {
        var Amount_ = (this.Expenses_Row.filter(e => e.Narration == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        this.Exp_itemwise_Row.push({ Narration: data, Qty: 0, Amount: Amount_ })
      }



    });

  }


  R_Bill_No="";

  public Item_Name_="";
  public Delivery_Details_Row = [];
  get_Delivery_Details() {
    this.isload = true;
    this.Invoice_Row_total = 0;
    this.Invoice_Row_length = 0;
    this.get("Api/Invoice/get_Delivery?From=" + this.S_From + "&To=" + this.S_To +"&User="+this.Search_User+ "&Area=" + this.S_Area + "&Customer=" + this.S_Customer_ + "&Category=" + this.I_Category +"&Item=" + this.I_Item +"&Category2=" + this.I_Category2 + "&order_by=y.Bill_Date desc").subscribe((res: any) => {
      this.Delivery_Details_Row = JSON.parse(res).record;
      this.isload = false;

      try
      {
    
       
for(var i=0;i<this.Delivery_Details_Row.length;i++)
{
 
 
  if(Number(this.Delivery_Details_Row[i]["Rate"])>0)
{
  this.Delivery_Details_Row[i]["Margin"] =((Number(this.Delivery_Details_Row[i]["MRP"])-Number(this.Delivery_Details_Row[i]["Unit_Price"]))/Number(this.Delivery_Details_Row[i]["MRP"])*100).toFixed(2);
}
else{
  this.Delivery_Details_Row[i]["Margin"]=0;
}

}
      }catch{}

      try {

        this.Invoice_Row_total = (this.Delivery_Details_Row.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        this.Invoice_Row_length = this.Delivery_Details_Row.length;

      } catch
      {

      }
    });
  }
  public Brandwise_Sales_Row = [];
  get_Brandwise_Details() {
    this.isload = true;
    this.Invoice_Row_total = 0;
    this.Invoice_Row_length = 0;
    this.get("Api/Invoice/get_Delivery?From=" + this.S_From + "&Bill_Type=" + this.S_Bill_Type + "&To=" + this.S_To + "&Bill_Mode=" + this.S_Bill_Mode + "&Pay_Mode=" + this.S_Pay_Mode + "&Sales_person=All&User=" + this.Search_User + "&Area=" + this.S_Area + "&Condi_Sales=" + this.Condi_Sales + "&order_by=x.Bill_Date desc").subscribe((res: any) => {
      this.Brandwise_Sales_Row = JSON.parse(res).record;
      this.isload = false;

      try {

        this.Invoice_Row_total = (this.Delivery_Details_Row.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        this.Invoice_Row_length = this.Delivery_Details_Row.length;

      } catch
      {

      }
    });
  }

  public Deleted_Order_GF = ['Table_', 'Item_Name', 'Created_by', 'Created_Date'];
  public Variable_Settings_GF = ['ID', 'S_Variable', 'S_Value', 'S_Default'];

  public Reference_Group_GF = ['ID', 'RG_iID', 'RG_vCode', 'RG_vDescription'];

  public Delete_Order_Details_Row = [];
  public Delete_Order_Details() {
    this.isload = true;
    this.Delete_Order_Details_Row = [];
    this.get("Api/Invoice/get_Delete_Order_Detail?From=" + this.S_From + "&To=" + this.S_To + "&User=" + this.Search_User + "&order_by=Created_Date").subscribe((res: any) => {
      this.isload = false;
      this.Delete_Order_Details_Row = JSON.parse(res).record;
    });

  }
  public Cusomer_Details_Rowdata = "";

  public Cusomer_Details_Row = [];
  public Customer_Details() {
    this.isload = true;
    this.Cusomer_Details_Row = [];
    this.get("Api/Invoice/get_Customer_Sales?Uni_Code=" + this.Cusomer_Details_Rowdata).subscribe((res: any) => {
      this.Cusomer_Details_Row = JSON.parse(res).record;
      this.isload = false;

    });

  }
  public Pending_Item_Rowdata = "";

  public Pending_Item_Row = [];
  public Pending_Item_Details() {
    this.isload = true;
    this.Pending_Item_Row = [];
    this.get("Api/Invoice/get_Pending_Item_Details?ID=" + this.Pending_Item_Rowdata).subscribe((res: any) => {
      this.Pending_Item_Row = JSON.parse(res).record;
      this.isload = false;

    });

  }
  public Delivery_Item_Rowdata = "";

  public Product_Item_Row = [];
  public Product_Delivery_Details() {
    this.isload = true;
    this.Product_Item_Row = [];
    this.get("Api/Invoice/get_Delivery_Details?ID=" + this.Delivery_Item_Rowdata + "&From=" + this.S_From + "&Bill_Type=" + this.S_Bill_Type + "&To=" + this.S_To + "&Bill_Mode=" + this.S_Bill_Mode + "&Pay_Mode=" + this.S_Pay_Mode + "&Sales_person=All&User=" + this.Search_User + "&Area=" + this.S_Area + "&Condi_Sales=" + this.Condi_Sales + "&order_by=x.Bill_Date desc").subscribe((res: any) => {
      this.Product_Item_Row = JSON.parse(res).record;
      this.isload = false;

    });

  }

  public Sales_Item_Rowdata = "";
  public Sales_Date = "";
  public Sales_Customer: any = {};

  public Sales_Item_Row = [];
  public Saleswise_Details() {
    this.isload = true;
    this.Sales_Item_Row = [];
    this.get("Api/Invoice/get_Saleswise_Item?ID=" + this.Sales_Item_Rowdata + "&From=" + this.S_From + "&Bill_Type=" + this.S_Bill_Type + "&To=" + this.S_To + "&Bill_Mode=" + this.S_Bill_Mode + "&Pay_Mode=" + this.S_Pay_Mode + "&Sales_person=All&User=" + this.Search_User + "&Area=" + this.S_Area + "&Condi_Sales=" + this.Condi_Sales + "&order_by=x.Bill_Date desc").subscribe((res: any) => {
      this.Sales_Item_Row = JSON.parse(res).record;
      this.Sales_Customer = this.Sales_Item_Row[0];
      this.isload = false;

    });

  }
  public Purchase_Item_Rowdata = "";
  public Purchase_Date = "";
  public Purchase_Customer: any = {};
  public Purchase_Item_Row = [];
  public Purchasewise_Details() {
  this.isload = true;
  this.Purchase_Item_Row = [];
  this.get("Api/Invoice/get_Purchasewise_Item?ID=" + this.Purchase_Item_Rowdata + "&From=" + this.S_From + "&To=" + this.S_To+ "&order_by=x.Bill_Date desc").subscribe((res: any) => {
  this.Purchase_Item_Row = JSON.parse(res).record;
  this.Purchase_Customer = this.Purchase_Item_Row[0];
      this.isload = false;

    });

  }
  public Track_Sum_Row = [];
  public Itemwise_Track_Row = [];
  public Billwise_Track_Sum_Row = [];
  public Product_Track_Row = [];
  public Supplierwise_Track_Row = [];
  public Categorywise_Track_Row = [];

  get_Track_Details() {
    this.isload = true;
    this.Invoice_Row_total = 0;
    this.Invoice_Row_length = 0;
    this.get("Api/Invoice/get_Product_Track?From=" + this.S_From + "&To=" + this.S_To + "&Stock=" + this.S_Stock +"&Purchase_Type=" + this.Purchase_Type + "&I_Category=" + this.I_Category + "&order_by=y.Bill_Date,y.Bill_no,x.ID desc").subscribe((res: any) => {
      this.Product_Track_Row = JSON.parse(res).record;
      this.isload = false;

      try {

        this.Invoice_Row_total = (this.Product_Track_Row.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        this.Invoice_Row_length = this.Product_Track_Row.length;

      } catch
      {

      }



      var Item = [...new Set(this.Product_Track_Row.map(item => item.Item_Name))];
      this.Itemwise_Track_Row = [];
      for (let data of Item) {
        var s_sum = (this.Product_Track_Row.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.S_Value), 0)).toFixed(2);
        var Stock_sum = (this.Product_Track_Row.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.Stock_Value), 0)).toFixed(2);
        var Profit_sum = (this.Product_Track_Row.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.Profit), 0)).toFixed(2);
        var sum = (this.Product_Track_Row.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.P_Value), 0)).toFixed(2);
        var P_Qty = (this.Product_Track_Row.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.P_Qty), 0)).toFixed(2);
        var S_Qty = (this.Product_Track_Row.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.S_Qty), 0)).toFixed(2);
        var Stock_Qty = (this.Product_Track_Row.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.Stock_Qty), 0)).toFixed(2);

        var rs = this.Product_Track_Row.filter(e => e.Item_Name == data);

        this.Itemwise_Track_Row.push({ 'Item_Name': data, 'P_Qty': P_Qty, 'S_Qty': S_Qty, 'Stock_Qty': Stock_Qty, 'S_Value': s_sum, 'P_Value': sum, 'Stock_Value': Stock_sum, 'Profit': Profit_sum })
      }
      var Item = [...new Set(this.Product_Track_Row.map(item => item.Category))];
      this.Categorywise_Track_Row = [];
      for (let data of Item) {
        var s_sum = (this.Product_Track_Row.filter(e => e.Category == data).reduce((sum, current) => sum + parseFloat(current.S_Value), 0)).toFixed(2);
        var Stock_sum = (this.Product_Track_Row.filter(e => e.Category == data).reduce((sum, current) => sum + parseFloat(current.Stock_Value), 0)).toFixed(2);
        var Profit_sum = (this.Product_Track_Row.filter(e => e.Category == data).reduce((sum, current) => sum + parseFloat(current.Profit), 0)).toFixed(2);
        var sum = (this.Product_Track_Row.filter(e => e.Category == data).reduce((sum, current) => sum + parseFloat(current.P_Value), 0)).toFixed(2);
        var P_Qty = (this.Product_Track_Row.filter(e => e.Category == data).reduce((sum, current) => sum + parseFloat(current.P_Qty), 0)).toFixed(2);
        var S_Qty = (this.Product_Track_Row.filter(e => e.Category == data).reduce((sum, current) => sum + parseFloat(current.S_Qty), 0)).toFixed(2);
        var Stock_Qty = (this.Product_Track_Row.filter(e => e.Category == data).reduce((sum, current) => sum + parseFloat(current.Stock_Qty), 0)).toFixed(2);

        var rs = this.Product_Track_Row.filter(e => e.Category == data);

        this.Categorywise_Track_Row.push({ 'Category': data, 'P_Qty': rs[0].P_Qty, 'S_Qty': rs[0].S_Qty, 'Stock_Qty': rs[0].Stock_Qty, 'S_Value': s_sum, 'P_Value': sum, 'Stock_Value': Stock_sum, 'Profit': Profit_sum })
      }




      this.Supplierwise_Track_Row = [];
      var Supplier = [...new Set(this.Product_Track_Row.map(item => item.Supplier))];
      for (let data of Supplier) {
        var s_sum = (this.Product_Track_Row.filter(e => e.Supplier == data).reduce((sum, current) => sum + parseFloat(current.S_Value), 0)).toFixed(2);
        var Stock_sum = (this.Product_Track_Row.filter(e => e.Supplier == data).reduce((sum, current) => sum + parseFloat(current.Stock_Value), 0)).toFixed(2);
        var Profit_sum = (this.Product_Track_Row.filter(e => e.Supplier == data).reduce((sum, current) => sum + parseFloat(current.Profit), 0)).toFixed(2);
        var sum = (this.Product_Track_Row.filter(e => e.Supplier == data).reduce((sum, current) => sum + parseFloat(current.P_Value), 0)).toFixed(2);
        var rs = this.Product_Track_Row.filter(e => e.Supplier == data);
        this.Supplierwise_Track_Row.push({ 'Supplier': data, 'P_Value': sum, 'S_Value': s_sum, 'Stock_Value': Stock_sum, 'Profit': Profit_sum });

      }


      this.Billwise_Track_Sum_Row = [];
      var Bill_No = [...new Set(this.Product_Track_Row.map(item => item.Bill_No))];
      for (let data of Bill_No) {
        var sum = (this.Product_Track_Row.filter(e => e.Bill_No == data).reduce((sum, current) => sum + parseFloat(current.P_Value), 0)).toFixed(2);
        var s_sum = (this.Product_Track_Row.filter(e => e.Bill_No == data).reduce((sum, current) => sum + parseFloat(current.S_Value), 0)).toFixed(2);
        var Stock_sum = (this.Product_Track_Row.filter(e => e.Bill_No == data).reduce((sum, current) => sum + parseFloat(current.Stock_Value), 0)).toFixed(2);
        var Profit_sum = (this.Product_Track_Row.filter(e => e.Bill_No == data).reduce((sum, current) => sum + parseFloat(current.Profit), 0)).toFixed(2);

        var rs = this.Product_Track_Row.filter(e => e.Bill_No == data);

        this.Billwise_Track_Sum_Row.push({ 'Bill_No': data, 'Bill_Date': rs[0].Date, 'Supplier': rs[0].Supplier, 'P_Value': sum, 'S_Value': s_sum, 'Stock_Value': Stock_sum, 'Profit': Profit_sum });


      }





    });

  }


  public Minimum_Stock_Row = [];
  public Minimum_Stock_count = 0;
  get_Minimum_Stock_Details() {
    this.Minimum_Stock_count = 0;
    this.isload = true;
    this.get("Api/Invoice/get_Minimum_Stock?I_Ledger_Name=" + this.I_Ledger_Name + "&brand=" + this.I_Category + "&order_by=Ledger_Name").subscribe((res: any) => {
      this.Minimum_Stock_Row = JSON.parse(res).record;
      this.isload = false;
      try {
        this.Minimum_Stock_count = this.Minimum_Stock_Row.length;
      } catch { }


    });

  }
  public Itemwise_Sales_Return_Row = [];
  get_Sales_Return_Details() {
    this.isload = true;
    this.Invoice_Row_total = 0;
    this.Invoice_Row_length = 0;
    this.get("Api/Invoice/get_Itemwise_Sales_Return?From=" + this.S_From + "&To=" + this.S_To + "&Brand=" + this.I_Category + "&Type=" + this.S_Return_Type + "&order_by=x.Bill_Date desc").subscribe((res: any) => {
      this.Itemwise_Sales_Return_Row = JSON.parse(res).record;
      this.isload = false;

      try {

        this.Invoice_Row_total = (this.Itemwise_Sales_Return_Row.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        this.Invoice_Row_length = this.Itemwise_Sales_Return_Row.length;

      } catch
      {

      }





    });

  }
  public Purchase_Type='Purchase';
  public Price_List_Row = [];
  get_Price_List_Details() {

    this.isload = true;
    this.Invoice_Row_total = 0;
    this.Invoice_Row_length = 0;
    this.get("Api/Invoice/get_Price_List_Report?ID=" + this.S_Customer).subscribe((res: any) => {
      this.Price_List_Row = JSON.parse(res).record;
      this.isload = false;

      try {

        this.Invoice_Row_total = (this.Itemwise_Sales_Return_Row.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        this.Invoice_Row_length = this.Itemwise_Sales_Return_Row.length;

      } catch
      {

      }





    });

  }
  public Sales_Personwise_Sum_Row = [];
  public Itemwise_Sales_Row = [];
  get_Sales_Details() {
    this.isload = true;
    this.Invoice_Row_total = 0;
    this.Invoice_Row_length = 0;
    this.Itemwise_Sales_Row = [];

    this.get("Api/Invoice/get_Itemwise?From=" + this.S_From +"&Customer="+this.S_customer +"&Bill_Type=" + this.S_Bill_Type + "&To=" + this.S_To + "&Bill_Mode=" + this.S_Bill_Mode + "&Pay_Mode=" + this.S_Pay_Mode +"&Category=" + this.Category + "&Sales_person=" + this.S_Sales + "&User=" + this.Search_User + "&Area=" + this.S_Area + "&Condi_Sales=" + this.Condi_Sales + "&order_by=x.Bill_Date desc").subscribe((res: any) => {
      this.Itemwise_Sales_Row = JSON.parse(res).record;
      this.isload = false;

      try {

        this.Invoice_Row_total = (this.Itemwise_Sales_Row.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        this.Invoice_Row_length = this.Itemwise_Sales_Row.length;

      } catch
      {

      }



      var Item = [...new Set(this.Itemwise_Sales_Row.map(item => item.Item_Name))];
      this.Item_wise_sales_Row = [];
      for (let data of Item) {
        var Amount_ = (this.Itemwise_Sales_Row.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        var Qty_ = (this.Itemwise_Sales_Row.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.Qty), 0)).toFixed(2);

        this.Item_wise_sales_Row.push({ Item_Name: data, Qty: parseFloat(Qty_), Amount: parseFloat(Amount_) })
      }

      var Item = [...new Set(this.Itemwise_Sales_Row.map(item => item.Category))];
      this.Categorywise_sales_Row = [];
      for (let data of Item) {
        var Amount_ = (this.Itemwise_Sales_Row.filter(e => e.Category == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        var Qty_ = (this.Itemwise_Sales_Row.filter(e => e.Category == data).reduce((sum, current) => sum + parseFloat(current.Qty), 0)).toFixed(2);

        this.Categorywise_sales_Row.push({ Category: data, Qty: parseFloat(Qty_), Amount: parseFloat(Amount_) })
      }


      this.Areawise_Sales_Sum_Row = [];
      var Area = [...new Set(this.Itemwise_Sales_Row.map(item => item.Area))];
      for (let data of Area) {
        var sum = (this.Itemwise_Sales_Row.filter(e => e.Area == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        this.Areawise_Sales_Sum_Row.push({ 'Area': data, 'Amount': parseFloat(sum) });
      }
      this.Sales_Personwise_Sum_Row = [];
      var Sales_person = [...new Set(this.Itemwise_Sales_Row.map(item => item.Sales_person))];
      for (let data of Sales_person) {
        var sum = (this.Itemwise_Sales_Row.filter(e => e.Sales_person == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        this.Sales_Personwise_Sum_Row.push({ 'Sales_person': data, 'Amount': parseFloat(sum) });
      }

      this.userwise_Sales_Sum_Row = [];
      var User = [...new Set(this.Itemwise_Sales_Row.map(item => item.User))];


      for (let data of User) {
        var sum = (this.Itemwise_Sales_Row.filter(e => e.User == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        var count = [...new Set((this.Itemwise_Sales_Row.filter(e => e.User == data)).map(item => item.Bill_No))].length;

        this.userwise_Sales_Sum_Row.push({ 'User': data, 'count': count, 'Amount': parseFloat(sum) });
      }


      this.Paymodewise_Sales_Sum_Row = [];
      var Pay_Mode_ = [...new Set(this.Itemwise_Sales_Row.map(item => item.Bill_Mode))];
      for (let data of Pay_Mode_) {
        var sum = (this.Itemwise_Sales_Row.filter(e => e.Bill_Mode == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        this.Paymodewise_Sales_Sum_Row.push({ 'Pay_Mode': data, 'Amount': (sum) });
      }



      this.Date_wise_sales_Row = [];
      var Bill_Date_ = [...new Set(this.Itemwise_Sales_Row.map(item => item.Bill_Date_))];
      for (let data of Bill_Date_) {
        var sum = (this.Itemwise_Sales_Row.filter(e => e.Bill_Date_ == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        var count = [...new Set((this.Itemwise_Sales_Row.filter(e => e.Bill_Date_ == data)).map(item => item.Bill_No))].length;

        this.Date_wise_sales_Row.push({ 'Date': data, 'count': count, 'Amount': sum });
      }
      this.Customerwise_sales_Row = [];
      var Customer_Name = [...new Set(this.Itemwise_Sales_Row.map(item => item.Customer_Name))];
      for (let data of Customer_Name) {
        var sum = (this.Itemwise_Sales_Row.filter(e => e.Customer_Name == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        var count = [...new Set((this.Itemwise_Sales_Row.filter(e => e.Customer_Name == data)).map(item => item.Bill_No))].length;

        this.Customerwise_sales_Row.push({ 'Customer_Name': data, 'count': count, 'Amount': sum });
      }


      this.Billwise_Sales_Sum_Row = [];
      var Bill_No = [...new Set(this.Itemwise_Sales_Row.map(item => item.Bill_No))];
      for (let data of Bill_No) {
        var sum = (this.Itemwise_Sales_Row.filter(e => e.Bill_No == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        var rs = this.Itemwise_Sales_Row.filter(e => e.Bill_No == data);

        this.Billwise_Sales_Sum_Row.push({ 'Bill_No': data, 'Bill_Date_': rs[0].Bill_Date, 'Bill_Date': rs[0].Bill_Date_, 'Customer_Name': rs[0].Customer_Name, 'Phone_No': rs[0].Contact_No, 'Amount':  parseFloat(sum)  });


      }




    });

  }



  public Productwise_Row = [];
  public Userwise_Row = [];
  public Customerwise_Row = [];
  public Itemwise_Sales_Row1 = [];
  get_Deliverywise_Details() {
    this.isload = true;
    this.Invoice_Row_total = 0;
    this.Invoice_Row_length = 0;
    this.get("Api/Invoice/get_Customerwise?From=" + this.S_From + "&Bill_Type=" + this.S_Bill_Type + "&To=" + this.S_To + "&Bill_Mode=" + this.S_Bill_Mode + "&Category=" + this.I_Category + "&Pay_Mode=" + this.S_Pay_Mode + "&Sales_person=All&User=" + this.Search_User + "&Area=" + this.S_Area + "&Condi_Sales=" + this.Condi_Sales + "&order_by=x.Bill_Date desc").subscribe((res: any) => {
      this.Itemwise_Sales_Row1 = JSON.parse(res).record;
      this.isload = false;

      try {

        this.Invoice_Row_total = (this.Itemwise_Sales_Row1.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        this.Invoice_Row_length = this.Itemwise_Sales_Row1.length;

      } catch
      {

      }
      var Userwise = [...new Set(this.Itemwise_Sales_Row1.map(item => item.Userwise))];
      this.Userwise_Row = [];
      for (let data of Userwise) {
        var Amount_ = (this.Itemwise_Sales_Row1.filter(e => e.Userwise == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        var Qty_ = (this.Itemwise_Sales_Row1.filter(e => e.Userwise == data).reduce((sum, current) => sum + parseFloat(current.Qty), 0)).toFixed(2);
        var Free_ = (this.Itemwise_Sales_Row1.filter(e => e.Userwise == data).reduce((sum, current) => sum + parseFloat(current.Free), 0)).toFixed(2);

        var rs = this.Itemwise_Sales_Row1.filter(e => e.Userwise == data);

        this.Userwise_Row.push({ User_: rs[0].User_, Profit: rs[0].Profit, Item_Name: rs[0].Item_Name, Qty: Qty_, Amount: Amount_, MRP: rs[0].MRP, Free: Free_, Extra_Qty: rs[0].Extra_Qty });

      }


      var Item = [...new Set(this.Itemwise_Sales_Row1.map(item => item.Item_ID))];
      this.Productwise_Row = [];
      for (let data of Item) {
        var Amount_ = (this.Itemwise_Sales_Row1.filter(e => e.Item_ID == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        var Qty_ = (this.Itemwise_Sales_Row1.filter(e => e.Item_ID == data).reduce((sum, current) => sum + parseFloat(current.Qty), 0)).toFixed(2);
        var Free_ = (this.Itemwise_Sales_Row1.filter(e => e.Item_ID == data).reduce((sum, current) => sum + parseFloat(current.Free), 0)).toFixed(2);

        var rs = this.Itemwise_Sales_Row1.filter(e => e.Item_ID == data);

        this.Productwise_Row.push({ Item_ID: data, Qty: Qty_, Amount: Amount_, MRP: rs[0].MRP, Free: Free_, Extra_Qty: rs[0].Extra_Qty, Item_Name: rs[0].Item_Name });
      }


      this.Customerwise_Row = [];
      var Bill_No = [...new Set(this.Itemwise_Sales_Row1.map(item => item.Bill_No))];
      for (let data of Bill_No) {
        var sum = (this.Itemwise_Sales_Row1.filter(e => e.Bill_No == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        var rs = this.Itemwise_Sales_Row1.filter(e => e.Bill_No == data);

        this.Customerwise_Row.push({ 'Bill_No': data, 'Bill_Date_': rs[0].Bill_Date, 'Bill_Date': rs[0].Bill_Date_, 'Customer_Name': rs[0].Customer_Name, 'Area': rs[0].Area, 'Phone_No': rs[0].Contact_No, 'Amount': sum, 'Payment': rs[0].Payment, });


      }


















    });

  }

  public Billwise_Purchase_Sum_Row = [];
  public Paymodewise_Purchase_Sum_Row = [];
  public Date_wise_Purchase_Row = [];
  public Supplierwise_Purchase = [];
  public userwise_Purchase_Sum_Row = [];
  public Areawise_Purchase_Sum_Row = [];
  public Item_wise_purchase_Row = [];
  public Itemwise_purchase_Row = [];
  get_Purchase_Report() {
    this.isload = true;
    this.Invoice_Row_total = 0;
    this.Invoice_Row_length = 0;
    this.get("Api/Invoice/get_Purchase_Report?From=" + this.S_From + "&To=" + this.S_To + "&Bill_Mode=" + this.S_Bill_Mode + "&Pay_Mode=" + this.S_Pay_Mode + "&Sales_person=All&User=" + this.Search_User + "&Area=" + this.S_Area + "&Purchase_Type="+this.Purchase_Type+"&Condi_Sales=" + this.Condi_Sales + "&order_by=x.Purchase_Date desc").subscribe((res: any) => {
      this.Itemwise_purchase_Row = JSON.parse(res).record;
      this.isload = false;

      try {

        this.Invoice_Row_total = (this.Itemwise_purchase_Row.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        this.Invoice_Row_length = this.Itemwise_purchase_Row.length;

      } catch
      {

      }



      var Item = [...new Set(this.Itemwise_purchase_Row.map(item => item.Item_Name))];
      this.Item_wise_purchase_Row = [];
      for (let data of Item) {
        var Amount_ = (this.Itemwise_purchase_Row.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        var Qty_ = (this.Itemwise_purchase_Row.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.Qty), 0)).toFixed(2);
        var Profit_ = (this.Itemwise_purchase_Row.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.Profit), 0)).toFixed(2);
        var S_Rate_ = (this.Itemwise_purchase_Row.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.S_Rate), 0)).toFixed(2);

        this.Item_wise_purchase_Row.push({ Item_Name: data, Qty: Qty_, Amount: Amount_, Profit: Profit_, S_Rate: S_Rate_ })
      }


      this.Areawise_Purchase_Sum_Row = [];
      var Area = [...new Set(this.Itemwise_purchase_Row.map(item => item.Area))];
      for (let data of Area) {
        var sum = (this.Itemwise_purchase_Row.filter(e => e.Area == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        this.Areawise_Purchase_Sum_Row.push({ 'Area': data, 'Amount': sum });
      }


      this.userwise_Purchase_Sum_Row = [];
      var User = [...new Set(this.Itemwise_purchase_Row.map(item => item.User))];


      for (let data of User) {
        var sum = (this.Itemwise_purchase_Row.filter(e => e.User == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        var count = [...new Set((this.Itemwise_purchase_Row.filter(e => e.User == data)).map(item => item.Bill_No))].length;

        this.userwise_Purchase_Sum_Row.push({ 'User': data, 'count': count, 'Amount': sum });
      }


      this.Paymodewise_Purchase_Sum_Row = [];
      var Pay_Mode_ = [...new Set(this.Itemwise_purchase_Row.map(item => item.Bill_Mode))];
      for (let data of Pay_Mode_) {
        var sum = (this.Itemwise_purchase_Row.filter(e => e.Bill_Mode == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        this.Paymodewise_Purchase_Sum_Row.push({ 'Pay_Mode': data, 'Amount': sum });
      }



      this.Date_wise_Purchase_Row = [];
      var Purchase_Date_ = [...new Set(this.Itemwise_purchase_Row.map(item => item.Purchase_Date_))];
      for (let data of Purchase_Date_) {
        var sum = (this.Itemwise_purchase_Row.filter(e => e.Purchase_Date_ == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        var count = [...new Set((this.Itemwise_purchase_Row.filter(e => e.Purchase_Date_ == data)).map(item => item.Purchase_No))].length;

        this.Date_wise_Purchase_Row.push({ 'Purchase_Date_': data, 'count': count, 'Amount': sum });
      }
      this.Supplierwise_Purchase = [];
      var Supplier_Name = [...new Set(this.Itemwise_purchase_Row.map(item => item.Supplier_Name))];
      for (let data of Supplier_Name) {
        var sum = (this.Itemwise_purchase_Row.filter(e => e.Supplier_Name == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        var count = [...new Set((this.Itemwise_purchase_Row.filter(e => e.Supplier_Name == data)).map(item => item.Bill_No))].length;

        this.Supplierwise_Purchase.push({ 'Supplier_Name': data, 'count': count, 'Amount': sum });
      }


      this.Billwise_Purchase_Sum_Row = [];
      var Purchase_No = [...new Set(this.Itemwise_purchase_Row.map(item => item.Purchase_No))];
      for (let data of Purchase_No) {
        var sum = (this.Itemwise_purchase_Row.filter(e => e.Purchase_No == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        var rs = this.Itemwise_purchase_Row.filter(e => e.Purchase_No == data);

        this.Billwise_Purchase_Sum_Row.push({ 'Purchase_No': data, 'Purchase_Date_': rs[0].Purchase_Date, 'Purchase_Date': rs[0].Purchase_Date_, 'Supplier_Name': rs[0].Supplier_Name, 'Contact_No': rs[0].Contact_No, 'Amount': sum });


      }




    });

  }
  public Over_all_Row = [];
  public Customerwise_Profit_Row = [];
  public Billwise_Profit_Sum_Row = [];
  public Item_wise_profit_Row = [];
  public Itemwise_Profit_Row = [];
  public Categorywise_Profit_Row = [];

  get_Profit_Details() {
    this.isload = true;
    this.Invoice_Row_total = 0;
    this.Invoice_Row_length = 0;
    this.get("Api/Invoice/get_Itemwise_Profit?From=" + this.S_From + "&To=" + this.S_To + "&Bill_Mode=" + this.S_Bill_Mode + "&Pay_Mode=" + this.S_Pay_Mode + "&Sales_person=All&User=" + this.Search_User + "&Area=" + this.S_Area + "&Condi_Sales=" + this.Condi_Sales + "&order_by=x.Bill_Date desc").subscribe((res: any) => {
      this.Itemwise_Profit_Row = JSON.parse(res).record;
      this.isload = false;

      try {

        this.Invoice_Row_total = (this.Itemwise_Profit_Row.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        this.Invoice_Row_length = this.Itemwise_Profit_Row.length;

      } catch
      {

      }



      var Item = [...new Set(this.Itemwise_Profit_Row.map(item => item.Item_Name))];
      this.Item_wise_profit_Row = [];
      for (let data of Item) {
        var Amount_ = (this.Itemwise_Profit_Row.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        var Qty_ = (this.Itemwise_Profit_Row.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.Qty), 0)).toFixed(2);
        var Profit_sum = (this.Itemwise_Profit_Row.filter(e => e.Item_Name == data).reduce((sum, current) => sum + parseFloat(current.Profit), 0)).toFixed(2);

        var rs = this.Itemwise_Profit_Row.filter(e => e.Item_Name == data);

        this.Item_wise_profit_Row.push({ Item_Name: data, Profit: Profit_sum, Qty: Qty_, Amount: Amount_ })
      }
      var Item = [...new Set(this.Itemwise_Profit_Row.map(item => item.Category))];
      this.Categorywise_Profit_Row = [];
      for (let data of Item) {
        var Amount_ = (this.Itemwise_Profit_Row.filter(e => e.Category == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        var Qty_ = (this.Itemwise_Profit_Row.filter(e => e.Category == data).reduce((sum, current) => sum + parseFloat(current.Qty), 0)).toFixed(2);
        var Profit_sum = (this.Itemwise_Profit_Row.filter(e => e.Category == data).reduce((sum, current) => sum + parseFloat(current.Profit), 0)).toFixed(2);
        var Profit_Per = ((Profit_sum/Amount_)*100).toFixed(2);
        var rs = this.Itemwise_Profit_Row.filter(e => e.Category == data);

        this.Categorywise_Profit_Row.push({ Category: data, Profit: Profit_sum, Qty: Qty_, Amount: Amount_ ,Profit_Per: Profit_Per,})
      }



      this.Customerwise_Profit_Row = [];
      var Customer_Name = [...new Set(this.Itemwise_Profit_Row.map(item => item.Customer_Name))];
      for (let data of Customer_Name) {
        var sum = (this.Itemwise_Profit_Row.filter(e => e.Customer_Name == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        var Profit_sum = (this.Itemwise_Profit_Row.filter(e => e.Customer_Name == data).reduce((sum, current) => sum + parseFloat(current.Profit), 0)).toFixed(2);

        var count = [...new Set((this.Itemwise_Profit_Row.filter(e => e.Customer_Name == data)).map(item => item.Bill_No))].length;
        var rs = this.Itemwise_Profit_Row.filter(e => e.Customer_Name == data);

        this.Customerwise_Profit_Row.push({ 'Customer_Name': data, 'count': count, 'Profit': Profit_sum, 'Amount': sum });
      }


      this.Billwise_Profit_Sum_Row = [];
      var Bill_No = [...new Set(this.Itemwise_Profit_Row.map(item => item.Bill_No))];
      for (let data of Bill_No) {
        var sum = (this.Itemwise_Profit_Row.filter(e => e.Bill_No == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        var Profit_sum = (this.Itemwise_Profit_Row.filter(e => e.Bill_No == data).reduce((sum, current) => sum + parseFloat(current.Profit), 0)).toFixed(2);

        var rs = this.Itemwise_Profit_Row.filter(e => e.Bill_No == data);

        this.Billwise_Profit_Sum_Row.push({ 'Bill_No': data, 'Bill_Date_': rs[0].Bill_Date, 'Profit': Profit_sum, 'Bill_Date': rs[0].Bill_Date_, 'Customer_Name': rs[0].Customer_Name, 'Phone_No': rs[0].Contact_No, 'Amount': sum });


      }




    });

  }
  public Overall_Total = 0;

  public Overall_Row = [];
  get_Overall_Profit_Details() {
    this.isload = true;
    this.Overall_Total = 0;
    this.get("Api/Invoice/get_Overall_Profit?From=" + this.S_From + "&To=" + this.S_To).subscribe((res: any) => {
      this.Overall_Row = JSON.parse(res).record;
      this.isload = false;

      try {
       
        this.Overall_Total = this.Overall_Row.reduce((sum, current) => sum + parseFloat(current.Net_Amt), 0);
        this.Sales_Profit_Credit = this.Overall_Row.reduce((sum, current) => sum + parseFloat(current.Credit), 0);
        this.Sales_Profit_Debit = this.Overall_Row.reduce((sum, current) => sum + parseFloat(current.Debit), 0);
        this.Sales_Profit_Total = this.Sales_Profit_Credit-this.Sales_Profit_Debit ;
      } catch { }

    });

  }
  public Sales_Profit_Credit = 0;
  public Sales_Profit_Debit = 0;
  public Sales_Profit_Total =0;
  public Sales_Profit_Row = [];
  get_Sales_Profit_Details() {
    this.isload = true;
    this.get("Api/Invoice/get_Sales_Profit?From=" + this.S_From + "&To=" + this.S_To).subscribe((res: any) => {
      this.Sales_Profit_Row = JSON.parse(res).record;
      this.isload = false;

      try {
        this.Sales_Profit_Credit = this.Sales_Profit_Row.reduce((sum, current) => sum + parseFloat(current.CR_AMT), 0);
        this.Sales_Profit_Debit = this.Sales_Profit_Row.reduce((sum, current) => sum + parseFloat(current.DB_Amt), 0);
        this.Sales_Profit_Total = this.Sales_Profit_Credit-this.Sales_Profit_Debit ;
  
      } catch { }
    });

  }
  public DB_Damage_Total = 0;
  public Damage_Row = [];
  get_Damage_Details() {
    this.isload = true;
    this.DB_Damage_Total = 0;
    this.get("Api/Invoice/get_Damage_Detail?From=" + this.S_From + "&To=" + this.S_To + "&User=" + this.Search_User + "&Bill_Type=" + this.S_Bill_Type + "&order_by=Damage_Date,Damage_No").subscribe((res: any) => {
      this.Damage_Row = JSON.parse(res).record;
      this.isload = false;

      try {
        this.DB_Damage_Total = this.Damage_Row.reduce((sum, current) => sum + parseFloat(current.Net_Amt), 0);
      } catch { }

    });

  }
  public Ledger_Overview_Row = [];
  get_Ledger_Overview() {
    this.isload = true;
    this.get("Api/Transaction/get_Ledger_Overview?ID=" + this.Ledger_ID + "&From=" + this.S_From + "&Bill_Type=" + this.S_Bill_Type + "&To=" + this.S_To + "&Area=" + this.S_Area).subscribe((res: any) => {
      this.Ledger_Overview_Row = JSON.parse(res).record;
      this.isload = false;

      try {
        this.Total = 0;
        this.cr = (this.Ledger_Overview_Row.reduce((sum, current) => sum + parseFloat(current.CR_Amt), 0)).toFixed(2);
        this.db = (this.Ledger_Overview_Row.reduce((sum, current) => sum + parseFloat(current.DB_Amt), 0)).toFixed(2);
        this.Total = this.db - this.cr;


      } catch
      { }

    });

  }
  public Sales_Overview_Row = [];
  get_Sales_Overview() {
    this.isload = true;
    this.get("Api/Transaction/get_Sales_Overview?ID=" + this.Ledger_ID + "&From=" + this.S_From + "&To=" + this.S_To + "&User=" + this.Search_User + "&Bill_Type=" + this.S_Bill_Type + "&Area=" + this.S_Area + "&order_by=Bill_Date,Bill_No").subscribe((res: any) => {

      this.Sales_Overview_Row = JSON.parse(res).record;
      this.isload = false;


    });

  }


  public PMwise_Sales_Row = [];
  get_PMwise_Sales() {
    this.isload = true;
    this.get("Api/Hotel/Paymodewise?From=" + this.S_From + "&To=" + this.S_To + "").subscribe((res: any) => {
      this.PMwise_Sales_Row = JSON.parse(res).record;
      this.isload = false;


    });

  }


  public Arwise_Sales_Row = [];
  get_Arwise_Sales() {
    this.isload = true;
    this.get("Api/Hotel/Areawise?From=" + this.S_From + "&To=" + this.S_To + "").subscribe((res: any) => {
      this.Arwise_Sales_Row = JSON.parse(res).record;
      this.isload = false;


    });

  }





  S_Data = "";
  public Sales_Row1 = [];
  get_Sale_Details1() {
    this.isload = true;
    this.DB_Sales_Total = 0;
    this.get("Api/Invoice/get_Sale_Detail1?Data=" + this.S_Data + "").subscribe((res: any) => {
      this.Sales_Row1 = JSON.parse(res).record;
      this.isload = false;
    });
  }

  M_Pr = "0";
  A_Pr = "0";
  E_Pr = "0";

  public Sales_Range: any = {};
  public Sales_Range1: any = {};
  get_Sales_Range() {
    this.Sales_Range = {};
    this.get("Api/Invoice/get_Sales_Range?From=" + this.S_From + "").subscribe((res: any) => {
      this.Sales_Range = JSON.parse(res).record[0];
      this.Sales_Range1 = JSON.parse(res).record[1];

      this.M_Pr = (((this.Sales_Range.Morning) / (this.Sales_Range.Total)) * 100).toFixed(0) + "%";
      this.A_Pr = (((this.Sales_Range.Afternoon) / (this.Sales_Range.Total)) * 100).toFixed(0) + "%";
      this.E_Pr = (((this.Sales_Range.Evening) / (this.Sales_Range.Total)) * 100).toFixed(0) + "%";

    });
  }



  Category_Sales = [];
  Category_Lable = [];
  Category_Amount = [];
  categorywise_Sales() {


    this.get("Api/Reports/get_category_Sales?Date=" + this.S_From + "").subscribe((res: any) => {
      this.Category_Sales = JSON.parse(res).record;

      for (var i = 0; i < this.Category_Sales.length; i++) {
        this.Category_Lable[i] = this.Category_Sales[i]["Category"];
        this.Category_Amount[i] = this.Category_Sales[i]["Amount"];
      }


    });
  }


  public DB_Sales_Total = 0;
  public Sales_Row = [];
  get_Sale_Details() {
    this.isload = true;
    this.DB_Sales_Total = 0;
    this.get("Api/Invoice/get_Sale_Detail?From=" + this.S_From + "&To=" + this.S_To + "&User=" + this.Search_User + "&Bill_Type=" + this.S_Bill_Type + "&Area=" + this.S_Area + "&order_by=Bill_Date,Bill_No").subscribe((res: any) => {
      this.Sales_Row = JSON.parse(res).record;
      this.isload = false;
      
      

      for(var i=0;i<this.Sales_Row.length;i++)
        {
          var stringValue="";
          stringValue = this.Sales_Row[i].Net_Amt;
          this.Sales_Row[i].Net_Amt= parseInt(stringValue);
        }
  
        
      try {
        this.DB_Sales_Total = this.Sales_Row.reduce((sum, current) => sum + parseFloat(current.Net_Amt), 0);
      } catch { }

    });

  }
  public DB_HSNWise_Total = 0;
  public HSNWise_GST_Row = [];
  get_HSNWise_GST() {
    this.isload = true;
    this.DB_HSNWise_Total = 0;
    this.HSNWise_GST_Row=[];
    this.get("Api/Invoice/get_HSNWise_GST?From=" + this.S_From + "&To=" + this.S_To + "&User=" + this.Search_User + "&Bill_Type=" + this.S_Bill_Type+"&GST_No=" + this.S_GST_Type+"&Area=" + this.S_Area + "&order_by=Bill_Date,Bill_No").subscribe((res: any) => {
    this.HSNWise_GST_Row = JSON.parse(res).record;
    this.isload = false;

        
      try {
        this.DB_HSNWise_Total = this.HSNWise_GST_Row.reduce((sum, current) => sum + parseFloat(current.Net_Amt), 0);
      } catch { }

    });

  }
  public Retrun_Status="Pending";

  public M_Sales_Total = 0;
  public Return_Pending_Count = 0;

  public M_Sales_Return_Row = [];
  get_M_Sale_Return_Details() {
    this.isload = true;
    this.M_Sales_Total = 0;
    this.get("Api/Invoice/get_M_Sale_Return?Retrun_Status="+this.Retrun_Status+"&From=" + this.S_From + "&To=" + this.S_To + "&User=" + this.Search_User + "&Bill_Type=" + this.S_Bill_Type + "&Area=" + this.S_Area + "&order_by=Bill_Date,Bill_No").subscribe((res: any) => {
      this.M_Sales_Return_Row = JSON.parse(res).record;
      this.isload = false;


      try {
        this.M_Sales_Total = this.M_Sales_Return_Row.reduce((sum, current) => sum + parseFloat(current.Net_Amt), 0);
        this.Return_Pending_Count = this.M_Sales_Return_Row.filter(e => e.Retrun_Status == "Pending").length;

      } catch { }

    });

  }
  public Sales_Gst_Row = [];
  get_Sale_Gst_Details() {
    this.isload = true;
    this.get("Api/Invoice/get_Sale_Gst_Detail?From=" + this.S_From + "&To=" + this.S_To + "&User=" + this.Search_User + "&Bill_Type=" + this.S_Bill_Type + "&GST_No=" + this.S_GST_Type + "&Area=" + this.S_Area + "&order_by=Bill_Date,Bill_No").subscribe((res: any) => {
      this.Sales_Gst_Row = JSON.parse(res).record;
      this.isload = false;


      try {
        this.DB_Sales_Total = this.Sales_Row.reduce((sum, current) => sum + parseFloat(current.Net_Amt), 0);
      } catch { }

    });

  }
  public Gst_Non_Tax_Row = [];
  get_Gst_Non_Tax_Details() {
    this.isload = true;
    this.get("Api/Invoice/get_Gst_Non_Tax_Detail?From=" + this.S_From + "&To=" + this.S_To + "&User=" + this.Search_User + "&Bill_Type=" + this.S_Bill_Type + "&GST_No=" + this.S_GST_Type + "&Area=" + this.S_Area + "&order_by=Bill_Date,Bill_No").subscribe((res: any) => {
      this.Gst_Non_Tax_Row = JSON.parse(res).record;
      this.isload = false;


    });

  }
  public DB_Delivery_Total = 0;
  public Delivery_Row = [];
  get_Delivery_Challan_Details() {
    this.isload = true;
    this.DB_Delivery_Total = 0;
    this.get("Api/Invoice/get_Delivery_Detail?From=" + this.S_From + "&To=" + this.S_To + "&User=" + this.Search_User + "&Bill_Type=" + this.S_Bill_Type + "&order_by=DC_Date,DC_No").subscribe((res: any) => {
      this.Delivery_Row = JSON.parse(res).record;
      this.isload = false;

      try {
        this.DB_Delivery_Total = this.Delivery_Row.reduce((sum, current) => sum + parseFloat(current.Net_Amt), 0);
      } catch { }

    });

  }
  public DB_Dyeing_Total = 0;
  public Dyeing_Row = [];
  get_Dyeing_Details() {
    this.isload = true;
    this.DB_Tharikar_Total = 0;
    this.get("Api/Invoice/get_Dyeing_Detail?From=" + this.S_From + "&To=" + this.S_To + "&Thari_Status=" + this.S_Thari_Status + "&User=" + this.Search_User + "&order_by=Bill_Date,Bill_No").subscribe((res: any) => {
      this.Dyeing_Row = JSON.parse(res).record;
      this.isload = false;

      try {
        this.DB_Dyeing_Total = this.Dyeing_Row.reduce((sum, current) => sum + parseFloat(current.Net_Amt), 0);
      } catch { }

    });

  }
  public Print_Total = 0;
  public Print_Row = [];
  get_Print_Details() {
    this.isload = true;
    this.Print_Total = 0;
    this.get("Api/Invoice/get_Print_Barcode?From=" + this.S_From + "&To=" + this.S_To + "&Bill_Mode=" + this.S_Bill_Mode).subscribe((res: any) => {
      this.Print_Row = JSON.parse(res).record;
      this.isload = false;

      try {
        this.Print_Total = this.Print_Row.reduce((sum, current) => sum + parseFloat(current.Net_Amt), 0);
      } catch { }

    });

  }
  public DB_Tharikar_Total = 0;
  public Tharikar_Row = [];
  get_Tharikar_Details() {
    this.isload = true;
    this.DB_Tharikar_Total = 0;
    this.get("Api/Invoice/get_Tharikar_Detail?From=" + this.S_From + "&To=" + this.S_To + "&Thari_Status=" + this.S_Thari_Status + "&User=" + this.Search_User + "&order_by=Bill_Date,Bill_No").subscribe((res: any) => {
      this.Tharikar_Row = JSON.parse(res).record;
      this.isload = false;

      try {
        this.DB_Tharikar_Total = this.Tharikar_Row.reduce((sum, current) => sum + parseFloat(current.Net_Amt), 0);
      } catch { }

    });

  }
  public DB_Sales_Return_Total = 0;
  public Sales_Return_Row = [];
  get_Sale_Return_Details() {
    this.isload = true;
    this.DB_Sales_Return_Total = 0;
    this.get("Api/Invoice/get_Sale_Return_Detail?From=" + this.S_From + "&To=" + this.S_To + "&User=" + this.Search_User + "&order_by=Return_No").subscribe((res: any) => {
      this.Sales_Return_Row = JSON.parse(res).record;
      this.isload = false;

      try {
        this.DB_Sales_Return_Total = this.Sales_Return_Row.reduce((sum, current) => sum + parseFloat(current.Net_Amt), 0);
      } catch { }

    });

  }

  public DB_Vadi_Total = 0;
  public Vadi_No = [];
  public Vadi_Details_Row = [];
  get_Vadi_Details() {
    this.isload = true;
    this.DB_Vadi_Total = 0;
    this.get("Api/Invoice/get_Vadi_Detail?From=" + this.S_From + "&To=" + this.S_To + "&User=" + this.Search_User + "&order_by=Vadi_Date,Vadi_No").subscribe((res: any) => {
      this.Vadi_Details_Row = JSON.parse(res).record;

      this.Vadi_No = this.Vadi_No;
      this.isload = false;
      try {
        this.DB_Vadi_Total = this.Vadi_Details_Row.reduce((sum, current) => sum + parseFloat(current.Net_Amt), 0);
      } catch { }

    });
  }

  public Production_Row = [];
  get_Production_Details() {
    this.isload = true;
    this.get("Api/Invoice/get_Production_Detail?From=" + this.S_From + "&To=" + this.S_To + "&User=" + this.Search_User + "&order_by=Pro_No").subscribe((res: any) => {
      this.Production_Row = JSON.parse(res).record;
      this.isload = false;
    });

  }


  public Production_issue_Row = [];
  get_Production_issue_Details() {
    this.isload = true;
    this.get("Api/Invoice/get_Production_issue_Detail?From=" + this.S_From + "&To=" + this.S_To + "&User=" + this.Search_User + "&order_by=Pro_No").subscribe((res: any) => {
      this.Production_issue_Row = JSON.parse(res).record;
      this.isload = false;
    });

  }

  public Proforma_Row = [];
  get_P_Invoice_Details() {
    this.isload = true;
    this.get("Api/Invoice/get_P_Invoice_Detail?From=" + this.S_From + "&To=" + this.S_To + "&User=" + this.Search_User + "&order_by=Bill_No").subscribe((res: any) => {
      this.Proforma_Row = JSON.parse(res).record;
      this.isload = false;
    });

  }
  public Quatation_Row = [];
  get_Quatation_Details() {
    this.isload = true;
    this.get("Api/Invoice/get_Quotation_Detail?From=" + this.S_From + "&To=" + this.S_To + "&User=" + this.Search_User + "&order_by=Bill_No").subscribe((res: any) => {
      this.Quatation_Row = JSON.parse(res).record;
      this.isload = false;
    });

  }


  public Ledger_Group = [];
  get_Ledger_Group() {
    this.isload = true;
    this.getc("Api/Master/get_Ledger_Group").subscribe((res: any) => {
      this.Ledger_Group = JSON.parse(res).record;
      this.isload = false;
      console.log()
    });

  }

  public S_Group = 0;
  public S_Category = 0;
  public S_Brand = 0;
  public Opening_Entry_Rows;
  public selected_Item: any = {}

  public Item_Group = [];
  get_Item_Group() {
    this.isload = true;
    this.getc("Api/Master/get_Item_Group").subscribe((res: any) => {
      this.Item_Group = JSON.parse(res).record;
      this.isload = false;
    });

  }

  public Category_Stock = [];
  public Current_Stock = [];

  get_Current_Stock_Details() {
    this.isload = true;
    this.get("Api/Reports/get_Currenct_Stock?From=" + this.S_From + "&To=" + this.S_To + "&Group=" + this.I_Group +"&Rate=" + this.I_Rate + "&Category=" + this.I_Category + "&Brand=" + this.I_Brand).subscribe((res: any) => {
      this.Current_Stock = JSON.parse(res).record;
      this.isload = false;
      try {

        this.Category_Stock = [];
        var Category = [...new Set(this.Current_Stock.map(item => item.Category))];
        for (let data of Category) {
          var sum = (this.Current_Stock.filter(e => e.Category == data).reduce((sum, current) => sum + parseFloat(current.Stock), 0)).toFixed(2);
          var sum2 = (this.Current_Stock.filter(e => e.Category == data).reduce((sum, current) => sum + parseFloat(current.Value), 0)).toFixed(2);



          this.Category_Stock.push({ 'Category': data, 'Stock': sum, 'Value': sum2, });

        }
      } catch { }


      this.isload = false;
    });

  }

  public Stock_Report_Row = [];
  get_Stock_Report() {
    this.isload = true;
    this.get("Api/Reports/get_Stock_Report?From=" + this.S_From + "&To=" + this.S_To + "&Group=" + this.I_Group).subscribe((res: any) => {
      this.Stock_Report_Row = JSON.parse(res).record;
      this.isload = false;
    });

  }





  public S_Item_ID = "";
  public S_Item_Name = "";
  public Item_Stock = [];

  public check_Unicode: boolean = false;

  get_Item_Stock_Details() {
    this.isload = true;
    this.get("Api/Reports/get_Item_Stock?Item_ID=" + this.S_Item_ID + "&From=" + this.S_From + "&To=" + this.S_To + "").subscribe((res: any) => {
      this.Item_Stock = JSON.parse(res).record;
      this.isload = false;
      try {
        this.Item_Stock[0].Balance = Number(this.Item_Stock[0].Inward_Qty) - Number(this.Item_Stock[0].Outward_Qty);
        for (var i = 1; i < this.Item_Stock.length; i++) {
          this.Item_Stock[i].Balance = (Number(this.Item_Stock[i - 1].Balance)) + (Number(this.Item_Stock[i].Inward_Qty) - Number(this.Item_Stock[i].Outward_Qty));
        }

      }
      catch { }
    });
  }

  public Item_History_Row=[];
  get_Purchase_Item_History() {
    this.isload = true;
    this.Item_History_Row=[];
    this.get("Api/Reports/get_Purchase_Item_History?Item_ID=" + this.S_Item_ID + "&From=" + this.S_From + "&To=" + this.S_To + "").subscribe((res: any) => {
      this.Item_History_Row = JSON.parse(res).record;
      this.isload = false;
  
    });
  }
  public DB_Stock_Total = 0;
  public Stock_Row = [];
  get_Adjustment_Details() {
    this.isload = true;
    this.DB_Stock_Total = 0;
    this.get("Api/Invoice/get_Stock_Adjustment_Detail?From=" + this.S_From + "&Bill_Type=" + this.S_Bill_Type + "&To=" + this.S_To + "&User=" + this.Search_User + "&order_by=Adj_No").subscribe((res: any) => {
      this.Stock_Row = JSON.parse(res).record;
      this.isload = false;
      try {
        this.DB_Stock_Total = this.Stock_Row.reduce((sum, current) => sum + parseFloat(current.Net_Amt), 0);
      } catch { }

    });

  }


  public lable_ = "";
  public DB_Purchase_Total = 0;
  public Purchase_Row = [];
  get_Purchase_Details() {
    this.isload = true;
    this.DB_Purchase_Total = 0;
    this.get("Api/Invoice/get_Purchase_Detail?From=" + this.S_From + "&Bill_Type=" + this.S_Bill_Type + "&To=" + this.S_To + "&User=" + this.Search_User + "&order_by=Purchase_No").subscribe((res: any) => {
      this.Purchase_Row = JSON.parse(res).record;
      this.isload = false;
      try {
        this.DB_Purchase_Total = this.Purchase_Row.reduce((sum, current) => sum + parseFloat(current.Net_Amt), 0);
      } catch { }

    });

  }
  public DB_Sales_Return_Details_Total = 0;
  public Sales_Return_Details_Row = [];
  get_Sales_Return() {
    this.isload = true;
    this.DB_Sales_Return_Details_Total = 0;
    this.Sales_Return_Details_Row=[];
    this.get("Api/Invoice/get_Sales_Return_Detail?From=" + this.S_From + "&Bill_Type=" + this.S_Bill_Type + "&To=" + this.S_To + "&User=" + this.Search_User + "&Status=" + this.Sales_Return_Status + "&order_by=Purchase_No").subscribe((res: any) => {
      this.Sales_Return_Details_Row = JSON.parse(res).record;
      this.isload = false;
      try {
        this.DB_Sales_Return_Details_Total = this.Sales_Return_Details_Row.reduce((sum, current) => sum + parseFloat(current.Net_Amt), 0);
      } catch { }

    });

  }
  public Purchase_Order_Row = [];
  get_Purchase_Order_Details() {
    this.isload = true;
    this.get("Api/Invoice/get_Purchase_Order_Detail?From=" + this.S_From + "&To=" + this.S_To + "&User=" + this.Search_User + "&order_by=PO_NO").subscribe((res: any) => {
      this.Purchase_Order_Row = JSON.parse(res).record;
      this.isload = false;
    });

  }


  L = [];
  get_Array(data) {
    this.L = data.split(",");

    return this.L;
  }

  Date_wise_sales_Row = [];
  Item_wise_sales_Row = [];
  Categorywise_sales_Row = [];

  Areawise_Sales_Sum_Row = [];
  userwise_Sales_Sum_Row = [];
  Paymodewise_Sales_Sum_Row = [];
  Billwise_Sales_Sum_Row = [];
  Customerwise_sales_Row = [];



  public S_customer = "0";
  public S_Sales = "All";
  public S_Return_Type = "All";

  public Outstand_Amt = 0;
  public Outstanding_Rows = [];
  public selected_Out_customer: any = {}
  public selected_Out_supplier: any = {}
  public selected_customer: any = {}

  public DB_Customer_Total = 0;
  public Billwise_Out = [];
  public Customerwise_Out = [];
  public Areawise_Out = [];
  public Streetwise_Out = [];
  public Sales_Personwise_Out = [];
  public Duedays_Out = [];
  public select_mode="Pending";

  get_OutStanding() {
    this.isload = true;
    this.DB_Customer_Total = 0;
    this.get("Api/Invoice/get_Outstanding?Date=" + this.S_To + "&Sales_person=" + this.S_Sales + "&Area=" + this.S_Area + "&Customer=0&Area_Map=" + this.Area_Map + "&Rights=" + this.Rights_Name + "&User_ID=" + this.Current_User.UM_ID +  "&select_mode=" + this.select_mode +"&Order_by=Bill_Date").subscribe((res: any) => {
      this.isload = false;
      this.Outstanding_Rows = JSON.parse(res).record;

      this.Billwise_Out = [];
      var Bill_No = [...new Set(this.Outstanding_Rows.map(item => item.Bill_No))];
      for (let data of Bill_No) {
        var sum = (this.Outstanding_Rows.filter(e => e.Bill_No == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        var rs = this.Outstanding_Rows.filter(e => e.Bill_No == data);

        this.Billwise_Out.push({ 'Bill_No': data, 'Bill_Date': rs[0].Bill_Date_, 'Customer_Name': rs[0].Customer_Name, 'Phone_No': rs[0].Contact_No, 'Bill_Amount': rs[0].Bill_Amount, 'Amount': sum });

      }




      this.Customerwise_Out = [];
      var Ledger_ID = [...new Set(this.Outstanding_Rows.map(item => item.Ledger_ID))];


     
      for (let data of Ledger_ID) {
        var sum = (this.Outstanding_Rows.filter(e => e.Ledger_ID == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        var count = [...new Set((this.Outstanding_Rows.filter(e => e.Ledger_ID == data)).map(item => item.Bill_No))].length;


        var crow = this.Ledger_Master_Rows.filter(e => e.ID == data)[0];


        try {
          if (Number(sum) != 0) {
            this.Customerwise_Out.push({ 'Customer_Name': crow.Ledger_Name, 'Customer_ID': crow.ID, 'Area': crow.Area, 'Street': crow.Street, 'Phone_No': crow.Phone_Number, 'count': count, 'Amount': sum });
          }
        } catch { }
        try {
          this.DB_Customer_Total = this.Outstanding_Rows.reduce((sum, current) => sum + parseFloat(current.Amount), 0);
        } catch { }
      }


      this.Areawise_Out = [];
      var Area = [...new Set(this.Outstanding_Rows.map(item => item.Area))];
      for (let data of Area) {
        var sum = (this.Outstanding_Rows.filter(e => e.Area == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        var count = [...new Set((this.Outstanding_Rows.filter(e => e.Area == data)).map(item => item.Ledger_ID))].length;

        this.Areawise_Out.push({ 'Area': data, 'count': count, 'Amount': sum });
      }

      this.Streetwise_Out = [];
      var Street = [...new Set(this.Outstanding_Rows.map(item => item.Street))];
      for (let data of Street) {
        var sum = (this.Outstanding_Rows.filter(e => e.Street == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        var count = [...new Set((this.Outstanding_Rows.filter(e => e.Street == data)).map(item => item.Ledger_ID))].length;

        this.Streetwise_Out.push({ 'Street': data, 'count': count, 'Amount': sum });
      }



      this.Sales_Personwise_Out = [];
      var Sales_Person = [...new Set(this.Outstanding_Rows.map(item => item.Sales_Person))];
      for (let data of Sales_Person) {
        var sum = (this.Outstanding_Rows.filter(e => e.Sales_Person == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        var count = [...new Set((this.Outstanding_Rows.filter(e => e.Sales_Person == data)).map(item => item.Bill_No))].length;

        this.Sales_Personwise_Out.push({ 'Sales_Person': data, 'count': count, 'Amount': sum });
      }





      this.Duedays_Out = [];
      var Dues = [...new Set(this.Outstanding_Rows.map(item => item.Dues))];
      for (let data of Dues) {
        var sum = (this.Outstanding_Rows.filter(e => e.Dues == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        var count = [...new Set((this.Outstanding_Rows.filter(e => e.Dues == data)).map(item => item.Bill_No))].length;

        this.Duedays_Out.push({ 'Dues': data, 'count': count, 'Amount': sum });
      }




      try {

        this.Outstand_Amt = (this.Outstanding_Rows.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);


      } catch
      {

      }


    });
  }
  public Billwise_Outstanding = [];
  public Customerwise_Outstanding = [];
  public Areawise_Outstanding = [];
  public Categorywise_Outstanding_Row = [];
  public Categorywise_Outstanding_ = [];


  get_Category_OutStanding() {
    this.isload = true;
    this.DB_Customer_Total = 0;
    this.get("Api/Invoice/get_Categorywise_Outstanding?Category=" + this.I_Category+ "&Area=" + this.S_Area ).subscribe((res: any) => {
      this.isload = false;
      this.Categorywise_Outstanding_Row = JSON.parse(res).record;

      this.Billwise_Outstanding = [];
      var Bill_No = [...new Set(this.Categorywise_Outstanding_Row.map(item => item.Bill_No))];
      for (let data of Bill_No) {
        var sum = (this.Categorywise_Outstanding_Row.filter(e => e.Bill_No == data).reduce((sum, current) => sum + parseFloat(current.Pending), 0)).toFixed(2);
        var sum1 = (this.Categorywise_Outstanding_Row.filter(e => e.Bill_No == data).reduce((sum, current) => sum + parseFloat(current.Net_Amt), 0)).toFixed(2);

        var rs = this.Categorywise_Outstanding_Row.filter(e => e.Bill_No == data);

        this.Billwise_Outstanding.push({ 'Bill_No': data, 'Bill_Date': rs[0].Bill_Date, 'Customer_Name': rs[0].Customer_Name, 'Phone_No': rs[0].Contact_No,'Bill_Amount': sum1, 'Amount': sum });

      }




      this.Customerwise_Outstanding = [];
      var Customer_Name = [...new Set(this.Categorywise_Outstanding_Row.map(item => item.Customer_Name))];


      for (let data of Customer_Name) {
        var sum = (this.Categorywise_Outstanding_Row.filter(e => e.Customer_Name == data).reduce((sum, current) => sum + parseFloat(current.Pending), 0)).toFixed(2);
        var count = [...new Set((this.Categorywise_Outstanding_Row.filter(e => e.Customer_Name == data)).map(item => item.Bill_No))].length;
        var rs = this.Categorywise_Outstanding_Row.filter(e => e.Customer_Name == data);




        try {
          if (Number(sum) != 0) {
            this.Customerwise_Outstanding.push({ 'Customer_Name': rs[0].Customer_Name, 'Area': rs[0].Area, 'count': count, 'Amount': sum });
          }
        } catch { }
        try {
          this.DB_Customer_Total = this.Categorywise_Outstanding_Row.reduce((sum, current) => sum + parseFloat(current.Amount), 0);
        } catch { }
      }


      this.Areawise_Outstanding = [];
      var Area = [...new Set(this.Categorywise_Outstanding_Row.map(item => item.Area))];
      for (let data of Area) {
        var sum = (this.Categorywise_Outstanding_Row.filter(e => e.Area == data).reduce((sum, current) => sum + parseFloat(current.Pending), 0)).toFixed(2);

        var count = [...new Set((this.Categorywise_Outstanding_Row.filter(e => e.Area == data)).map(item => item.Customer_Name))].length;

        this.Areawise_Outstanding.push({ 'Area': data, 'count': count, 'Amount': sum });
      }

 

      this.Categorywise_Outstanding_ = [];
      var Category = [...new Set(this.Categorywise_Outstanding_Row.map(item => item.Category))];
      for (let data of Category) {
        var sum = (this.Categorywise_Outstanding_Row.filter(e => e.Category == data).reduce((sum, current) => sum + parseFloat(current.Pending), 0)).toFixed(2);
        var sum1 = (this.Categorywise_Outstanding_Row.filter(e => e.Category == data).reduce((sum, current) => sum + parseFloat(current.Net_Amt), 0)).toFixed(2);


        this.Categorywise_Outstanding_.push({ 'Category': data, 'Net_Amt': sum1, 'Pending': sum });
      }


      try {

        this.Outstand_Amt = (this.Categorywise_Outstanding_Row.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);


      } catch
      {

      }


    });
  }
  public Customer_Balance = 0;
  public DB_payable = 0;
  public Payable_Outstand_Amt = [];
  public Supplierwise_Out = [];
  public Billwise_Payable_Out = [];
  public Payable_Outstanding_Rows = [];
  get_Payable_OutStanding() {
    this.isload = true;
    this.DB_payable = 0;
    this.get("Api/Invoice/get_Purchase_outstanding?Date=" + this.S_To + "&Sales_person=" + this.S_Sales_Person + "&Area=" + this.S_Area + "&Customer=0&Area_Map=" + this.Area_Map + "&Rights=" + this.Rights_Name + "&User_ID=" + this.Current_User.UM_ID + "&Order_by=Bill_Date").subscribe((res: any) => {
      this.isload = false;

      this.Payable_Outstanding_Rows = JSON.parse(res).record;

      this.Billwise_Payable_Out = [];
      var Purchase_No = [...new Set(this.Payable_Outstanding_Rows.map(item => item.Purchase_No))];
      for (let data of Purchase_No) {
        var sum = (this.Payable_Outstanding_Rows.filter(e => e.Purchase_No == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        var rs = this.Payable_Outstanding_Rows.filter(e => e.Purchase_No == data);

        this.Billwise_Payable_Out.push({ 'Bill_No': rs[0].Bill_No, 'Purchase_No': data, 'Bill_Date': rs[0].Bill_Date_, 'Customer_Name': rs[0].Customer_Name, 'Phone_No': rs[0].Contact_No, 'Bill_Amount': rs[0].Bill_Amount, 'Amount': sum });
      }
      this.Supplierwise_Out = [];
      var Ledger_ID = [...new Set(this.Payable_Outstanding_Rows.map(item => item.Ledger_ID))];


      console.log(Ledger_ID);
      for (let data of Ledger_ID) {
        var sum = (this.Payable_Outstanding_Rows.filter(e => e.Ledger_ID == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        var count = [...new Set((this.Payable_Outstanding_Rows.filter(e => e.Ledger_ID == data)).map(item => item.Bill_No))].length;

        var crow = this.Ledger_Master_Rows.filter(e => e.ID == data)[0];


        try {
          if (Number(sum) != 0) {
            this.Supplierwise_Out.push({ 'Customer_Name': crow.Ledger_Name, 'Customer_ID': crow.ID, 'Area': crow.Area, 'Street': crow.Street, 'Phone_No': crow.Phone_Number, 'count': count, 'Amount': sum });
          }
        } catch { }
      }
      try {

        this.Payable_Outstand_Amt = (this.Payable_Outstanding_Rows.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);


      } catch
      {

      }
      try {

        this.DB_payable = (this.Payable_Outstanding_Rows.reduce((sum, current) => sum + parseFloat(current.Amount), 0));


      } catch
      {

      }



    });

  }





  public Typewise_DayBook_Row = [];

  public focus_type = "name";
  public S_Bank = "0";
  public day_Book_Short_by = "AC_Date";
  public cr = 0;
  public db = 0;
  public Total = 0;
  public Rows = [];
  public Day_Book_Row = [];
  public get_day_Book() {
    this.isload = true;
    this.Day_Book_Row = [];

    this.get("Api/Invoice/get_Daybook?From=" + this.S_From + "&To=" + this.S_To + "&Bank=" + this.S_Bank + "&Pay_Mode=" + this.S_Pay_Mode + "&User=" + this.Search_User + "&Order_by=" + this.day_Book_Short_by).subscribe((res: any) => {

      this.Day_Book_Row = JSON.parse(res).record;
      this.isload = false;
      this.Total = 0;
      this.cr = (this.Day_Book_Row.reduce((sum, current) => sum + parseFloat(current.CR_AMT), 0)).toFixed(2);
      this.db = (this.Day_Book_Row.reduce((sum, current) => sum + parseFloat(current.DB_AMT), 0)).toFixed(2);
      this.Total = this.cr - this.db;

      var Item = [...new Set(this.Day_Book_Row.map(item => item.Nar_Type))];
      this.Typewise_DayBook_Row = [];
      for (let data of Item) {
        var cr = (this.Day_Book_Row.filter(e => e.Nar_Type == data).reduce((sum, current) => sum + parseFloat(current.CR_AMT), 0)).toFixed(2);
        var db = (this.Day_Book_Row.filter(e => e.Nar_Type == data).reduce((sum, current) => sum + parseFloat(current.DB_AMT), 0)).toFixed(2);
        var rs = this.Day_Book_Row.filter(e => e.Nar_Type == data);

        this.Typewise_DayBook_Row.push({ Nar_Type: data, AC_Date: rs[0].AC_Date, CR_AMT: cr, DB_AMT: db })
      }

    });

  }

  public DayBook_amt_Row = [];
  public get_dayBook_Amt() {
    this.isload = true;
    this.DayBook_amt_Row = [];
    this.get("Api/invoice/get_daybook_Amt?From=").subscribe((res: any) => {

      this.DayBook_amt_Row = JSON.parse(res).record;
      this.isload = false;



    });

  }


  public dashboard_data() {
    this.get_Sale_Details();
    this.get_Purchase_Details();
    this.get_expense();
    this.get_Recipts();
    // this.get_Ledger_Master();
    //this.get_Item_Master();
    this.get_OutStanding();
    this.get_Payable_OutStanding();
    this.get_day_Book();
    this.get_Payment();

  }

  public Receipt_Row = [];
  public Receipt_Row_Total = 0;
  public Receipt_Row_Cout = 0;
  public DB_Receipt = 0;
  get_Recipts() {
    this.isload = true;
    this.Receipt_Row_Total = 0;
    this.DB_Receipt = 0;

    this.get("Api/Transaction/get_Collection_Details?From=" + this.S_From + "&To=" + this.S_To).subscribe((res: any) => {
      this.Receipt_Row = JSON.parse(res).record;
      this.isload = false;
      try {

        this.Receipt_Row_Total = (this.Receipt_Row.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        this.Receipt_Row_Cout = this.Receipt_Row.length;
      } catch { }
      try {
        this.DB_Receipt = this.Receipt_Row.reduce((sum, current) => sum + parseFloat(current.Amount), 0);
      } catch { }

    });

  }
  public Receipt_AMT=0;
  public Ledger_Receipt_Rows=[]
  get_Ledger_Recipts(data) {
    this.isload = true;

    this.get("Api/Transaction/get_Receipt_Details?From=" + this.S_From + "&To=" + this.S_To+"&Ledger="+data).subscribe((res: any) => {
      this.Ledger_Receipt_Rows = JSON.parse(res).record;
      this.isload = false;
      this.Receipt_AMT = (this.Ledger_Receipt_Rows.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

    });

  }

  public DB_Payment = 0;
  Payment_Details_Row = [];
  Payment_Row_Total = 0;
  Payment_Row_Cout = 0;
  get_Payment() {
    this.isload = true;
    this.DB_Payment = 0;
    this.get("Api/Transaction/get_Paid_Details?From=" + this.S_From + "&To=" + this.S_To).subscribe((res: any) => {
      this.Payment_Details_Row = JSON.parse(res).record;
      this.isload = false;
      try {

        this.Payment_Row_Total = (this.Payment_Details_Row.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        this.Payment_Row_Cout = this.Payment_Details_Row.length;
      } catch
      {

      }
      try {

        this.DB_Payment = (this.Payment_Details_Row.reduce((sum, current) => sum + parseFloat(current.Amount), 0));
      } catch
      {

      }
    });

  }
  Payment_Total_Amt = 0;
  Ledger_Payment_Row = [];
  get_Ledger_Payment(data) {
    this.isload = true;
    this.Payment_Total_Amt = 0;

    this.get("Api/Transaction/get_Ledger_Paid_Details?From=" + this.S_From + "&To=" + this.S_To+"&Ledger="+data).subscribe((res: any) => {
      this.Ledger_Payment_Row = JSON.parse(res).record;
      this.Payment_Total_Amt = (this.Ledger_Payment_Row.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
      this.isload = false;

    });

  }
  public Due_Amt=0;
  public get_Daybook_Open_Row = [];
  public get_Daybook_Open() {
    this.isload = true;
    this.Day_Book_Row = [];
    this.get("Api/Invoice/get_Daybook_Open?From=" + this.S_From + "&To=" + this.S_To + "&Bank=" + this.S_Bank + "&Pay_Mode=" + this.S_Pay_Mode + "&User=" + this.Search_User + "&Order_by=" + this.day_Book_Short_by).subscribe((res: any) => {

      this.isload = false;
      this.get_Daybook_Open_Row = JSON.parse(res).record;
    });

  }




  public isorder_Load = false;
  public order_Rows_Total = 0;
  public order_Len = 0;
  get_orders() {
    this.isorder_Load = true;
    this.order_Rows_Total = 0;
    this.order_Len = 0;
    this.isload = true;
    this.get("Api/Invoice/get_Order_details?Status=" + this.Order_Status + "&From=" + this.S_From + "&To=" + this.S_To + "&User=" + this.Search_User + "&Area=" + this.Customer_Area + "&Order_by=" + this.Orders_sort_by).subscribe((res: any) => {
      this.isorder_Load = false;
      this.isload = false;


      this.order_Rows = JSON.parse(res).record;
      try {

        this.order_Rows_Total = (this.order_Rows.reduce((sum, current) => sum + parseFloat(current.Net_Amt), 0)).toFixed(2);
        this.order_Len = this.order_Rows.filter(e => e.Order_Status == "Pending").length;

        this.lenth = this.order_Rows.length;
      } catch
      {
        this.lenth = 0;
      }
    });


  }


  Sales_vs_RM_Rows = [];
  I_Sales_vs_RM_Rows = [];
  get_Sales_vs_RM() {

    this.isload = true;
    this.get("Api/Hotel/get_Sales_vs_RM?From=" + this.S_From).subscribe((res: any) => {
      this.isorder_Load = false;
      this.isload = false;
      this.Sales_vs_RM_Rows = JSON.parse(res).record;



      this.I_Sales_vs_RM_Rows = [];
      var Area = [...new Set(this.Sales_vs_RM_Rows.map(item => item.RM_Name))];


      for (let data of Area) {
        var sum = (this.Sales_vs_RM_Rows.filter(e => e.RM_Name == data).reduce((sum, current) => sum + parseFloat(current.RM_Used), 0)).toFixed(2);

        this.I_Sales_vs_RM_Rows.push({ 'Item_Name': data, 'Qty': sum });
      }



      for (var i = 1; i < this.Sales_vs_RM_Rows.length; i++) {
        if (this.Sales_vs_RM_Rows[i - 1]["Item_Name"] == this.Sales_vs_RM_Rows[i]["Item_Name"]) {
          this.Sales_vs_RM_Rows[i]["Status"] = "0"
        }

      }

    });


  }

  public Dashboard_Rows = [];
  get_Dashboard_Analysis() {

    this.isload = true;
    this.Dashboard_Rows = [];
    this.get("Api/Reports/get_Hourly_Sales?Date=" + this.S_From).subscribe((res: any) => {
      this.isorder_Load = false;
      this.isload = false;
      this.Dashboard_Rows = JSON.parse(res).record;


    });


  }


  public rowdata: any;
  get_Orders1() {


    Promise.all([this.get("Api/Invoice/get_Order_details?Status=" + this.Order_Status + "&From=" + this.S_From + "&To=" + this.S_To + "&User=" + this.Search_User + "&Area=" + this.Customer_Area + "&Order_by=" + this.Orders_sort_by).toPromise()]).then(([res]) => {
      if (res) {
        alert("Test");
        console.log(res);
      }
    });

  }


  load_themes(data) {
    var d = "default.css";
    try {
      d = localStorage.getItem('Theme');
    } catch { }

    if (d != "default.css" && d != null) {

      this.loadStyle(d);

    }
  }

  loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];

    let themeLink = this.document.getElementById(
      'client-theme'
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = `${styleName}`;

      head.appendChild(style);
    }
  }

  open_Quotation(data) {



    if (this.Quotation_Format == "Format1") {
      var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + data.Quote_No.replace(/[^a-zA-Z0-9 ]/g, "_") + "&File_Type=pdf&Quote_No=" + data.Quote_No;
      window.open(this.Server_URL + "PDF/Export_Quotation_1?" + parm, "_blank");
    }
    else if (this.Quotation_Format == "Format2") {
      window.open(this.Server_URL + "report/Quotation?Bill_No=" + data.Quote_No + "&Company=" + this.Company, "_blank");

    }
    else if (this.Quotation_Format == "Klisters") {
      window.open(this.Server_URL + "report/Klisters_Quotation_Bill?Bill_No=" + data.Quote_No + "&Company=" + this.Company, "_blank");

    }
    else if (this.Quotation_Format == "Qubha_Quotation") {
      window.open(this.Server_URL + "report/Qubha_Quotation_Bill?Bill_No=" + data.Quote_No + "&Company=" + this.Company, "_blank");

    }
    else if (this.Quotation_Format == "Qubha") {
      window.open(this.Server_URL + "report/Qubha_Quotation_Bill_W?Bill_No=" + data.Quote_No + "&Company=" + this.Company, "_blank");

    }
  }




  public Itemwise_order = [];
  public All_order_Rows = [];
  public Entry_wise_Order = [];
  public Areawise_Order = [];
  public Userwise_Order = [];
  public Cusomerwise_Order = [];
  public Daywise_Order = [];



  get_orders1() {
    this.isorder_Load = true;
    this.order_Rows_Total = 0;
    this.isload = true;
    this.get("Api/Invoice/get_Orders?Status=" + this.Order_Status + "&From=" + this.S_From + "&To=" + this.S_To + "&User=" + this.Search_User + "&Area=" + this.Customer_Area + "&Order_by=x.order_Date").subscribe((res: any) => {
      this.isorder_Load = false;
      this.isload = false;
      this.All_order_Rows = JSON.parse(res).record;

      console.log(this.All_order_Rows);
      this.Entry_wise_Order = [];
      var Order_No = [...new Set(this.All_order_Rows.map(item => item.Order_No))];
      for (let data of Order_No) {
        var sum = (this.All_order_Rows.filter(e => e.Order_No == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        var rs = this.All_order_Rows.filter(e => e.Order_No == data);

        this.Entry_wise_Order.push({ 'Order_No': data, 'Date': rs[0].Order_Date_, 'Customer_Name': rs[0].Customer_Name, 'Contact_No': rs[0].Contact_No, 'Amount': sum });

      }



      var Item = [...new Set(this.All_order_Rows.map(item => item.Item_ID))];
      this.Itemwise_order = [];
      for (let data of Item) {
        var Amount_ = (this.All_order_Rows.filter(e => e.Item_ID == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);
        var Qty_ = (this.All_order_Rows.filter(e => e.Item_ID == data).reduce((sum, current) => sum + parseFloat(current.Qty), 0)).toFixed(2);


        var rs = this.Item_Master_Rows.filter(e => e.ID == data);


        this.Itemwise_order.push({ 'Bag_Qty': rs[0].Bag_Qty, 'Item_Name': rs[0].Item_Name, 'Disp_Order': rs[0].Display_Order, 'Qty': Qty_, 'Net_Amt': Amount_ })
      }



      this.Areawise_Order = [];
      var Area = [...new Set(this.All_order_Rows.map(item => item.Area))];
      for (let data of Area) {
        var sum = (this.All_order_Rows.filter(e => e.Area == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        var count = [...new Set((this.All_order_Rows.filter(e => e.Area == data)).map(item => item.Order_No))].length;

        this.Areawise_Order.push({ 'Area': data, 'count': count, 'Amount': sum });
      }


      this.Userwise_Order = [];
      var Created_by = [...new Set(this.All_order_Rows.map(item => item.Created_by))];
      for (let data of Created_by) {
        var sum = (this.All_order_Rows.filter(e => e.Created_by == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        var count = [...new Set((this.All_order_Rows.filter(e => e.Created_by == data)).map(item => item.Order_No))].length;

        this.Userwise_Order.push({ 'User': data, 'count': count, 'Amount': sum });
      }

      this.Cusomerwise_Order = [];
      var Customer_Name = [...new Set(this.All_order_Rows.map(item => item.Customer_Name))];
      for (let data of Customer_Name) {
        var sum = (this.All_order_Rows.filter(e => e.Customer_Name == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        var count = [...new Set((this.All_order_Rows.filter(e => e.Customer_Name == data)).map(item => item.Order_No))].length;

        this.Cusomerwise_Order.push({ 'Customer_Name': data, 'count': count, 'Amount': sum });
      }
      this.Daywise_Order = [];
      var Order_Date_ = [...new Set(this.All_order_Rows.map(item => item.Order_Date_))];
      for (let data of Order_Date_) {
        var sum = (this.All_order_Rows.filter(e => e.Order_Date_ == data).reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);

        var count = [...new Set((this.All_order_Rows.filter(e => e.Order_Date_ == data)).map(item => item.Order_Date_))].length;

        this.Daywise_Order.push({ 'Order_Date_': data, 'count': count, 'Amount': sum });
      }



    });


  }

  public Area_Row = [];

  public Itemwise_Pending_Rows = [];
  public Item_Pending_Total = 0;
  public Item_Pending_Qty = 0;
  get_items_pending() {
    this.isload = true;
    this.Item_Pending_Total = 0;

    this.get("Api/Invoice/get_itemwise_Pending?Status=" + this.Order_Status + "&From=" + this.S_From + "&To=" + this.S_To + "&User=" + this.Search_User + "&Area=" + this.Customer_Area +"&Brand=" + this.I_Category +"&Item=" + this.I_Item + "&Order_by=i.Display_Order").subscribe((res: any) => {
      this.isload = false;
      this.Itemwise_Pending_Rows = JSON.parse(res).record;

      this.Item_Pending_Total = (this.Itemwise_Pending_Rows.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);


      this.Item_Pending_Qty = (this.Itemwise_Pending_Rows.reduce((sum, current) => sum + parseFloat(current.qty), 0)).toFixed(2);

    });
  }
  public Itemwise_Return_Rows = [];
  public Item_Return_Total = 0;
  public Item_Return_Qty = 0;
  get_items_Return() {
    this.isload = true;
    this.Item_Return_Total = 0;
    this.get("Api/Invoice/get_itemwise_Return?From=" + this.S_From + "&To=" + this.S_To + "&User=" + this.Search_User + "&Bill_Type=" + this.S_Bill_Type + "&Area=" + this.S_Area + "&order_by=Bill_Date,Bill_No").subscribe((res: any) => {
      this.isload = false;
      this.Itemwise_Return_Rows = JSON.parse(res).record;

      this.Item_Return_Total = (this.Itemwise_Return_Rows.reduce((sum, current) => sum + parseFloat(current.Amount), 0)).toFixed(2);


      this.Item_Return_Qty = (this.Itemwise_Return_Rows.reduce((sum, current) => sum + parseFloat(current.qty), 0)).toFixed(2);

    });
  }

  public Stock_Mismatch_Rows = [];
  public Stock_Mismatch_Rows_Count = 0;

  get_Stock_Mismatch() {
    this.isload = true;
    this.Stock_Mismatch_Rows = [];
    this.get("Api/Invoice/get_Stock_Mismatch?").subscribe((res: any) => {
    this.isload = false;
    this.Stock_Mismatch_Rows = JSON.parse(res).record;
    try {
      this.Stock_Mismatch_Rows_Count = this.Stock_Mismatch_Rows.length;

    } catch { }
    });
  }
  public Selected_Company: any = {};
  public Selected_Customer: any = {};
  public Current_User: any = {};

  public Order_No = "0";

  public states = [
    "Ariyalur",
    "Chengalpattu",
    "Chennai",
    "Coimbatore",
    "Cuddalore",
    "Dharmapuri",
    "Dindigul",
    "Erode",
    "Kallakurichi",
    "Kanchipuram",
    "Kanyakumari",
    "Karur",
    "Krishnagiri",
    "Madurai",
    "Mayiladuthurai",
    "Nagapattinam",
    "Namakkal",
    "Nilgiris",
    "Perambalur",
    "Pudukkottai",
    "Ramanathapuram",
    "Ranipet",
    "Salem",
    "Sivaganga",
    "Tenkasi",
    "Thanjavur",
    "Theni",
    "Thoothukudi",
    "Tiruchirappalli",
    "Tirunelveli",
    "Tirupattur",
    "Tiruppur",
    "Tiruvallur",
    "Tiruvannamalai",
    "Tiruvarur",
    "Vellore",
    "Viluppuram",
    "Virudhunagar"
  ];





  get_Category() {

    this.getc("Api/Master/get_Item_Category").subscribe((res: any) => {
      this.Item_Category = JSON.parse(res).record;



    });
  }

  public Document_Upload_Rows = [];
  get_Douments() {
    this.getc("Api/Master/get_Document_Upload").subscribe((res: any) => {
      this.Document_Upload_Rows = JSON.parse(res).record;
    });
  }






  public customerwise_Pending_Rows = [];
  get_customerwise_pending() {
    this.getc("Api/Invoice/get_customerwise_Pending").subscribe((res: any) => {
      this.customerwise_Pending_Rows = JSON.parse(res).record;
    });

  }


  public Field_Setting = [];
  get_Field_Setting() {
    this.getc("Api/Setting/get_Field_Setting").subscribe((res: any) => {
      this.Field_Setting = JSON.parse(res).record;

    });

  }
  public Field_Setting_Table_Row = [];
  get_Field_Setting_Table() {
    this.getc("Api/Setting/get_Field_Setting_Table").subscribe((res: any) => {
      this.Field_Setting_Table_Row = JSON.parse(res).record;

    });
  }
  public Bill_Format_Row = [];
  get_Bill_Format() {
    this.getc("Api/Setting/get_Bill_Format").subscribe((res: any) => {
      this.Bill_Format_Row = JSON.parse(res).record;

    });

  }

  F_Row = [];
  get_fields_of(Table) {

    this.F_Row = this.Field_Setting.filter(e => String(e.Table_Name).toLowerCase() == String(Table + "" + this.Company).toLowerCase()).filter(e => e.Visible == "True");
    return this.F_Row.sort((a, b) => Number(a["Order_No"]) - Number((b["Order_No"])));

  }

  get_grid_fields_of(Table) {

    this.F_Row = this.Field_Setting.filter(e => String(e.Table_Name).toLowerCase() == String(Table + "" + this.Company).toLowerCase()).filter(e => e.GVisible == "True");
    return this.F_Row.sort((a, b) => Number(a["GOrder"]) - Number((b["GOrder"])));

  }

  get_pos_field(Table, pos) {

    this.F_Row = this.Field_Setting.filter(e => String(e.Table_Name).toLowerCase() == String(Table + "" + this.Company).toLowerCase()).filter(e => e.Visible == "True");
    this.F_Row = this.F_Row.filter(e => String(e.Posision).toLowerCase() == String(pos).toLowerCase());
    return this.F_Row.sort((a, b) => Number(a["Order_No"]) - Number((b["Order_No"])));

  }


  public Advance_Booking_Rows = [];
  get_Advance_Book() {
    this.getc("Api/Transaction/get_Advance_Booking").subscribe((res: any) => {
      this.Advance_Booking_Rows = JSON.parse(res).record;
    });
  }


  public get_datas() {

    //this.get_Ledger_Master();


  }

  public Sales_Rows = [];
  get_Sales_Rows() {
    this.getc("Api/Transaction/get_Sales").subscribe((res: any) => {
      this.Sales_Rows = JSON.parse(res).record;
    });
  }


  public Menus_Rows: any = {};


  public Menu_Rows = [];
  public Label_Menu_Rows = [];
  get_Role_Rights(Role) {
    this.get("Api/Setting/get_Menu_for_user?Rights=" + Role).subscribe((res: any) => {
      this.Menu_Rows = JSON.parse(res).record;
      this.Side_Menu = true;
      try {
        this.Label_Menu_Rows = this.Menu_Rows.filter(e => e.Type == "Label");
        this.Label_Menu_Rows = this.Label_Menu_Rows.filter(e => e.Parent_ID == "0");
        this.Label_Menu_Rows.sort((a, b) => Number(a["Order_No"]) - Number((b["Order_No"])));
      } catch { }

    });
  }


  get_ref(data) {


    try {
      return this.Reference_Rows.filter(e => e.Ref_ID == data);
    } catch {

      return [];
    }
  }

  get_child(parent) {
    var data = this.Menu_Rows.filter(e => e.Parent_ID == parent).sort((a, b) => Number(a["Order_No"]) - Number((b["Order_No"])));

    return data;
  }

  get_childs(parent) {
    var data = this.Menu_Rows.filter(e => e.Module == parent).sort((a, b) => Number(a["Order_No"]) - Number((b["Order_No"])));

    return data;
  }

  public Menu_data = "";
  public Menu_Master_Rows = [];
  public Parent_Menus = [];
  get_Menu_Master() {
    this.getc("Api/Setting/get_Menu_master").subscribe((res: any) => {
      this.Menu_Master_Rows = JSON.parse(res).record;

      this.Parent_Menus = this.Menu_Master_Rows.filter(e => e.Parent_ID == "0");



    });
  }


  public Item_Master_Rows = [];
  public company_master = [];



  loading = false;

  public Perment_Item_Master_Row = [];
  public Reset_Item_Master_Row = [];


  public Item_Orderby_Name = "Category,Item_Name";
  public Item_Orderby_Product = "Item_Name";

  get_Company_Master() {



    this.getc("Api/Common/get_companys").subscribe((res: any) => {
      this.company_master = JSON.parse(res).record;



    });
  }


  public Sub_Category = "All";

  public Items_Rows = [];
  public I_Category = "All";
  public I_Category2 = "All";
  public I_Ledger_Name = "All";
  public I_Rate = "Purchase Rate";

  public I_Brand = "All";
  public I_Item = "";
  public I_Group = "0";

  get_Items() {

    this.loading = true;
    this.isload = true;
    this.Items_Rows = [];
    this.get("Api/Master/get_Items?Category=" + this.I_Category + "&Brand=" + this.I_Brand + "&Item=" + this.I_Item + "&Order_by=" + this.Item_Orderby_Name).subscribe((res: any) => {
      this.Items_Rows = JSON.parse(res).record;

      this.loading = false;
      this.isload = false;


    });
  }

  public Item_Row_Cout = 0;
  public Item_Master_RM_Rows = [];
  public hotel_Menu: boolean = false;
  public RM_all_item: boolean = true;
  public sub_category_Rows = [];

  sub_category_Rows_(data) {

    console.log(this.Item_Sub_Category);
    this.sub_category_Rows = this.Item_Sub_Category.filter(e => e.category == data);
  }
  Item_Sub_Category = [];
  Sub_Category_() {


    this.getc("Api/Master/get_Item_Sub_Category").subscribe((res: any) => {
      this.Item_Sub_Category = JSON.parse(res).record;

      localStorage.setItem('Sub_Cate_Row', res);

    });
  }
public Item_Master_MRP=[];
  get_Item_Name_MRP() {


    this.loading = true;
    this.isload = true;
    this.Item_Master_MRP = [];
    this.get("Api/Master/get_Item_Name_MRP?").subscribe((res: any) => {
      this.Item_Master_MRP = JSON.parse(res).record;


      this.loading = false;
      this.isload = false;


    });
  }

  get_Item_Master() {


    this.loading = true;
    this.isload = true;
    this.Item_Master_Rows = [];
    this.get("Api/Master/get_Item_Master?Order_by=" + this.Item_Orderby_Name + "&Category=" + this.I_Category.replace("&", "~") + "&Sub_Category=" + this.Sub_Category + "&Item_Status=" + this.Item_status).subscribe((res: any) => {
      this.Item_Master_Rows = JSON.parse(res).record;


      try {


        for (var i = 0; i < this.Item_Master_Rows.length; i++) {


          if (Number(this.Item_Master_Rows[i]["Rate"]) > 0) {
            this.Item_Master_Rows[i]["Margin"] = ((((Number(this.Item_Master_Rows[i]["MRP"]) * Number(this.Item_Master_Rows[i]["Box_Qty"])) / Number(this.Item_Master_Rows[i]["Rate"])) * 100) - 100).toFixed(2);
          }
          else {
            this.Item_Master_Rows[i]["Margin"] = 0;
          }

        }


      } catch { }

      this.Perment_Item_Master_Row = JSON.parse(res).record;
      this.Reset_Item_Master_Row = JSON.parse(res).record;

      if (this.RM_all_item == true) {
        this.Item_Master_RM_Rows = this.Perment_Item_Master_Row;
      } else {
        this.Item_Master_RM_Rows = this.Perment_Item_Master_Row.filter(e => e.Item_Group == "2");
      }



      this.loading = false;
      this.isload = false;


      try {
        this.Item_Row_Cout = this.Item_Master_Rows.length;

      } catch { }

      this.Item_Category = [...new Set(this.Item_Master_Rows.filter(e => e.Item_Group == '1').map(item => item.Category))];

      // console.log(this.Item_Category);
    });
  }
  public Token_Orderby_Name = "Created_Date,Compnay_Name";
  public Token_Row_Cout = 0;
  public Token_Entry_Rows = [];
  get_Token_Master() {


    this.loading = true;
    this.isload = true;
    this.Item_Master_Rows = [];
    this.get("Api/Master/get_Token_Master?Order_by=" + this.Token_Orderby_Name).subscribe((res: any) => {
      this.Token_Entry_Rows = JSON.parse(res).record;

      this.loading = false;
      this.isload = false;


      try {
        this.Token_Row_Cout = this.Token_Entry_Rows.length;

      } catch { }


      // console.log(this.Item_Category);
    });
  }
  public Pincode_Entry_Rows = [];
  get_Pincode_Master() {


    this.loading = true;
    this.isload = true;
    this.Item_Master_Rows = [];
    this.get("Api/Master/get_Pincode_Master?").subscribe((res: any) => {
      this.Pincode_Entry_Rows = JSON.parse(res).record;

      this.loading = false;
      this.isload = false;



    });
  }
  public Bill_Print_Setting_Rows = [];
  get_Bill_Print_Setting() {

    this.loading = true;
    this.isload = true;
    this.Bill_Print_Setting_Rows = [];
    this.getc("Api/Setting/get_Print_Bill_Setting").subscribe((res: any) => {
      this.Bill_Print_Setting_Rows = JSON.parse(res).record;

      this.loading = false;
      this.isload = false;
      // console.log(this.Item_Category);
    });
  }

  public Bill_Print_Setting_Rows1 = [];
  get_Bill_Print_Settingc() {

    this.loading = true;
    this.isload = true;
    this.Bill_Print_Setting_Rows1 = [];
    this.getc("Api/Setting/get_Print_Bill_Settingc").subscribe((res: any) => {
      this.Bill_Print_Setting_Rows1 = JSON.parse(res).record;

      this.loading = false;
      this.isload = false;
      // console.log(this.Item_Category);
    });
  }



  get_Print_type(data) {


    try {
      return this.Bill_Print_Setting_Rows1.filter(e => e.Print_Type.toLowerCase() == data.toLowerCase());
    } catch {

      return [];
    }
  }

  // sssssss
  public Item_Master_Rows1 = [];
  get_Price_Item_Master1() {

    this.loading = true;
    this.isload = true;
    this.Item_Master_Rows1 = [];
    this.get("Api/Master/get_Price_List?I_Category=" + this.I_Category).subscribe((res: any) => {
      this.Item_Master_Rows1 = JSON.parse(res).record;

      this.Perment_Item_Master_Row = JSON.parse(res).record;
      this.Reset_Item_Master_Row = JSON.parse(res).record;







      this.loading = false;
      this.isload = false;


      try {
        this.Item_Row_Cout = this.Item_Master_Rows.length;

      } catch { }

      this.Item_Category = [...new Set(this.Item_Master_Rows.map(item => item.Category))];

      // console.log(this.Item_Category);
    });
  }
  public Cash_Settlement = [];

  public Cash_Settlement_Rows = [];
  get_Cash_Settlement() {

    this.loading = true;
    this.isload = true;
    this.Cash_Settlement_Rows = [];
    this.get("Api/Invoice/get_Cash_Settlement?From=" + this.S_From + "&To=" + this.S_To + "&Sales_person=" + this.S_Sales + "&User=" + this.Search_User + "&Area=" + this.S_Area + "&order_by=x.Bill_No desc").subscribe((res: any) => {

      this.Cash_Settlement_Rows = JSON.parse(res).record;







      this.loading = false;
      this.isload = false;



      // console.log(this.Item_Category);
    });
  }
  public Reset_Product_Master_Rows = [];
  public Perment_Product_Master_Rows = [];
  public Product_Master_Rows = [];
  public Product_Row_Cout = 0;
  public Product_Master_RM_Rows = [];
  get_Product_Master() {

    this.loading = true;
    this.isload = true;
    this.Product_Master_Rows = [];
    this.get("Api/Master/get_Product_Master?Order_by=" + this.Item_Orderby_Product).subscribe((res: any) => {
      this.Product_Master_Rows = JSON.parse(res).record;

      this.Perment_Product_Master_Rows = JSON.parse(res).record;
      this.Reset_Product_Master_Rows = JSON.parse(res).record;

      this.Product_Master_RM_Rows = this.Perment_Product_Master_Rows.filter(e => e.Item_Group == "2");
      console.log(this.Product_Master_RM_Rows);
      this.loading = false;
      this.isload = false;


      try {
        this.Product_Row_Cout = this.Product_Master_Rows.length;

      } catch { }

      //   this.Item_Category = [...new Set(this.Item_Master_Rows.map(item => item.Category))];

      // console.log(this.Item_Category);
    });
  }
  public BOM_Master_Rows = [];
  public FG_ID = "0";
  get_BOM_Master() {
    this.loading = true;
    this.isload = true;
    this.BOM_Master_Rows = [];
    this.get("Api/Master/get_BOM_Master_By_FG?FG_ID=" + this.FG_ID + "&Order_by=RM_Order").subscribe((res: any) => {
      this.BOM_Master_Rows = JSON.parse(res).record;
      this.loading = false;
      this.isload = false;
    });
  }
 
  public Table_Master_Rows = [];
  public Perment_Table_Master_Rows = [];
  public Reset_Table_Master_Row = [];
  public Table_Orderby_Name = "No_of_chairs";


  Num(data) {
    return Number(data).toFixed(2);
  }


  
  ago(value: any, args?: any): any {

    
    if (value) {
        const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
        if (seconds < 29) // less than 30 seconds ago will show as 'Just now'
            return 'Just now';
        const intervals = {
            'year': 31536000,
            'month': 2592000,
            'week': 604800,
            'day': 86400,
            'hour': 3600,
            'minute': 60,
            'second': 1
        };
        let counter;
        for (const i in intervals) {
            counter = Math.floor(seconds / intervals[i]);
            if (counter > 0)
                if (counter === 1) {
                    return counter + ' ' + i + ' ago'; // singular (1 day ago)
                } else {
                    return counter + ' ' + i + 's ago'; // plural (2 days ago)
                }
        }
    }
    return value;
}



  get_Table_Master() {
    this.loading = true;
    this.isload = true;
    this.Table_Master_Rows = [];
    this.get("Api/Master/get_Table_Master?Order_by=" + this.Table_Orderby_Name).subscribe((res: any) => {
      this.Table_Master_Rows = JSON.parse(res).record;
      this.Perment_Table_Master_Rows = JSON.parse(res).record;
      this.Reset_Table_Master_Row = JSON.parse(res).record;
      this.loading = false;
      this.isload = false;
      //   this.Item_Category = [...new Set(this.Item_Master_Rows.map(item => item.Category))];

      // console.log(this.Item_Category);
    });
  }

  public Seraial_No_Settings_Rows = [];
  public Perment_Seraial_No_Settings_Rows = [];
  public Reset_Seraial_No_Settings_Row = [];
  public Orderby_Name = "Name";

  get_Seraial_No_Settings() {

    this.loading = true;
    this.isload = true;
    this.Seraial_No_Settings_Rows = [];
    this.get("Api/Setting/get_Seraial_No_Settings?Order_by=" + this.Orderby_Name).subscribe((res: any) => {
      this.Seraial_No_Settings_Rows = JSON.parse(res).record;
      this.Perment_Seraial_No_Settings_Rows = JSON.parse(res).record;
      this.Reset_Seraial_No_Settings_Row = JSON.parse(res).record;
      this.loading = false;
      this.isload = false;
      //   this.Item_Category = [...new Set(this.Item_Master_Rows.map(item => item.Category))];

      // console.log(this.Item_Category);
    });
  }
  public Variable_Settings_Rows = [];
  public Perment_Variable_Settings_Rows = [];
  public Reset_Variable_Settings_Row = [];

  get_Variable_Settings() {

    this.loading = true;
    this.Variable_Settings_Rows = [];
    this.getc("Api/Setting/get_variable_Settings").subscribe((res: any) => {
      this.Variable_Settings_Rows = JSON.parse(res).record;
      this.Perment_Variable_Settings_Rows = JSON.parse(res).record;
      this.Reset_Variable_Settings_Row = JSON.parse(res).record;
      this.loading = false;

    });
  }

  public Reference_Group_Rows = [];

  get_Reference_Group() {

    this.loading = true;
    this.Reference_Group_Rows = [];
    this.getc("Api/Setting/get_Reference_Group").subscribe((res: any) => {
      this.Reference_Group_Rows = JSON.parse(res).record;

      this.loading = false;

    });
  }
  public Feedback_Rows = [];

  get_Feedback_Report() {

    this.loading = true;
    this.Feedback_Rows = [];
    this.get("Api/Reports/get_Feedback_Report?From=" + this.S_From + "&To=" + this.S_To).subscribe((res: any) => {
      this.Feedback_Rows = JSON.parse(res).record;

      this.loading = false;

    });
  }
  public Notification_Rows = [];

  get_Notification_Details() {

    this.loading = true;
    this.Notification_Rows = [];
    this.getc("Api/Master/get_Notification").subscribe((res: any) => {
      this.Notification_Rows = JSON.parse(res).record;

      this.loading = false;

    });
  }
  public Search_Row = [];

  public load_from_make_invoice: boolean = false;
  public Stcok_Row = [];
  public P_Stcok_Row = [];
  public item_Category_ = "";
  get_Item_Master1(id) {
    this.Stcok_Row = [];
    this.loading = true;

    this.get("Api/Master/get_Item_Stock_bycustomer1?Ledger_ID=" + id + "&Category=" + this.item_Category_ + "&Check_Stock=" + this.Check_Stock + "&Order_by= x.Category,Item_Name&Order_No=" + this.Order_No).subscribe((res: any) => {
      this.Stcok_Row = JSON.parse(res).record;
      this.P_Stcok_Row = JSON.parse(res).record;
      this.loading = false;
      this.load_Temp_Order_Row()
    });
  }



  get_Item_Master_Datas(id) {
    this.Stcok_Row = [];
    this.loading = true;

    this.get("Api/Master/get_Item_Stock_byCategory?Ledger_ID=" + id + "&Category=" + this.item_Category_ + "&Check_Stock=" + this.Check_Stock + "&Order_by= x.Category,Item_Name&Order_No=" + this.Order_No).subscribe((res: any) => {
      this.Stcok_Row = JSON.parse(res).record;
      this.P_Stcok_Row = JSON.parse(res).record;
      this.loading = false;
      this.load_Temp_Order_Row()
    });
  }

  public Stock_Category_Row = [];
  get_stcok_Category() {
    this.Stock_Category_Row = [];
    this.loading = true;

    this.get("Api/Master/get_stcok_Category?").subscribe((res: any) => {
      this.Stock_Category_Row = JSON.parse(res).record;
      this.loading = false;
      this.load_Temp_Order_Row()
    });
  }
  public Dashboard_Row = [];
  get_Dashboard() {
    this.Dashboard_Row = [];
    this.loading = true;

    this.get("Api/Master/get_dashboard?").subscribe((res: any) => {
      this.Dashboard_Row = JSON.parse(res).record;
      this.loading = false;
    });
  }
  public Menu_Row = [];
  get_Menu_master() {
    this.Menu_Row = [];
    this.loading = true;

    this.get("Api/Master/get_Menu_master?").subscribe((res: any) => {
      this.Menu_Row = JSON.parse(res).record;
      this.loading = false;
    });
  }
  load_Temp_Order_Row() {

    try {
      var Qty = 0;
      var Rate = 0;
      var Free = 0;

      var Disc_Per_ = 0;
      var Disc_Amount_ = 0;
      var Disc_Type_ = "Per";

      this.Stcok_Row.forEach(x => x.Model = "0");

      for (var i = 0; i < this.Stcok_Row.length; i++) {
        try {

          Qty = this.Temp_Order_Row.filter(e => e.Uni_Code == this.Stcok_Row[i]["Uni_Code"]).filter(e => e.Item_ID == this.Stcok_Row[i]["Item_ID"])[0]["Order_Qty"];
          Rate = this.Temp_Order_Row.filter(e => e.Uni_Code == this.Stcok_Row[i]["Uni_Code"]).filter(e => e.Item_ID == this.Stcok_Row[i]["Item_ID"])[0]["Order_Rate"];
          Free = this.Temp_Order_Row.filter(e => e.Uni_Code == this.Stcok_Row[i]["Uni_Code"]).filter(e => e.Item_ID == this.Stcok_Row[i]["Item_ID"])[0]["Free"];
         
          Disc_Per_ =this.Temp_Order_Row.filter(e => e.Uni_Code == this.Stcok_Row[i]["Uni_Code"]).filter(e => e.Item_ID == this.Stcok_Row[i]["Item_ID"])[0]["Disc_Per_"];
          Disc_Amount_ =this.Temp_Order_Row.filter(e => e.Uni_Code == this.Stcok_Row[i]["Uni_Code"]).filter(e => e.Item_ID == this.Stcok_Row[i]["Item_ID"])[0]["Disc_Amt_"];
          Disc_Type_ = this.Temp_Order_Row.filter(e => e.Uni_Code == this.Stcok_Row[i]["Uni_Code"]).filter(e => e.Item_ID == this.Stcok_Row[i]["Item_ID"])[0]["Disc_Type"];
   
       
          Qty = parseFloat(Qty.toString());
          Rate = parseFloat(Rate.toString());
          Free = parseFloat(Free.toString());

        } catch {

          Qty = 0;
          Rate = 0;
          Free = 0;
        }

        try
        {
          Disc_Per_ =parseFloat(Disc_Per_.toString());
          Disc_Amount_ =parseFloat(Disc_Amount_.toString());
          
   
        }catch
        {
          Disc_Per_ =0;
          Disc_Amount_ =0;
        }

        if (Disc_Amount_ > 0) {
        if(Disc_Type_=="Per")
        {
          this.Stcok_Row[i]["Disc_Type_"] = Disc_Type_;
          this.Stcok_Row[i]["Disc_Amt_"] = Disc_Amount_;
          this.Stcok_Row[i]["Disc_Per_"] = Disc_Per_;
          this.Stcok_Row[i]["Discount"] = Disc_Per_;


        }
        else{
          this.Stcok_Row[i]["Disc_Type_"] = Disc_Type_;
          this.Stcok_Row[i]["Disc_Amt_"] = Disc_Amount_;
          this.Stcok_Row[i]["Disc_Per_"] = Disc_Per_;
          this.Stcok_Row[i]["Discount"] = Disc_Amount_;

        }
      }

        if (Rate > 0) {
          this.Stcok_Row[i]["Order_Rate"] = Rate;
        }

        if (Qty > 0) {
          this.Stcok_Row[i]["Order_Qty"] = Qty;
          this.Stcok_Row[i]["Model"] = Qty;

        }

        if (Free > 0) {
          this.Stcok_Row[i]["Free"] = Free;
        }
        Disc_Per_ =0;
        Disc_Amount_ =0;

      }
    } catch { }

    /*for (var i = 0; i < this.Stcok_Row.length; i++) {

      if(   Number(this.Stcok_Row[i]["Discount"] ==0))
      {
        this.Stcok_Row[i]["Discount"]=""
      }
    }*/

    this.Stcok_Row = this.Stcok_Row.sort((b, a) => Number(a["Model"]) - Number((b["Model"])));



  }

  public header_Row: any = {}
  public Details_Row = [];

  public Temp_Order_Row = [];

  add_one: number = 1;
  remove_one: number = -1;

  public Tax_Type = "exclusive";
  public GST_Type = "local";
  public cart_item = [

  ];

  /*{
  BAG: "1",
  CGST_PER: 0,
  CGST_TAX_AMT: 0,
  CUS_FREE: "0",
  DESCRIPTION: "bottle of 60 capsules",
  DISC_AMT: "0",
  DISC_PER: "0",
  DISC_TYPE: "",
  GST_PER: "0.00",
  HSNCode: "-",
  IGST_PER: 0,
  IGST_TAX_AMT: 0,
  ITEM_ID: "5",
  ITEM_NAME: "Inlife Green Tea Extract 500mg ",
  MRP: "0.00",
  NET_AMT: 870,
  NET_RATE: "290.00",
  PURCHASE_RATE: "0.000",
  Qty: 3,
  RESALE_RATE: "0.00",
  SALE_RATE: "290.00",
  SGST_PER: 0,
  SGST_TAX_AMT: 0,
  SOC_FREE: "0",
  TAXABLE_AMT: 870,
  TAX_REVYES: "",
  TAX_TYPE: "",
  TAX_YESNO: "",
  TOTAL_TAX: 0,
  TOT_AMT: 870,
  UNIT: "2"
  },
  {
  BAG: "1",
  CGST_PER: 0,
  CGST_TAX_AMT: 0,
  CUS_FREE: "0",
  DESCRIPTION: "bottle of 60 capsules",
  DISC_AMT: "0",
  DISC_PER: "0",
  DISC_TYPE: "",
  GST_PER: "0.00",
  HSNCode: "-",
  IGST_PER: 0,
  IGST_TAX_AMT: 0,
  ITEM_ID: "5",
  ITEM_NAME: "Inlife Green Tea Extract 500mg ",
  MRP: "0.00",
  NET_AMT: 870,
  NET_RATE: "290.00",
  PURCHASE_RATE: "0.000",
  Qty: 3,
  RESALE_RATE: "0.00",
  SALE_RATE: "290.00",
  SGST_PER: 0,
  SGST_TAX_AMT: 0,
  SOC_FREE: "0",
  TAXABLE_AMT: 870,
  TAX_REVYES: "",
  TAX_TYPE: "",
  TAX_YESNO: "",
  TOTAL_TAX: 0,
  TOT_AMT: 870,
  UNIT: "2"
  }
  ];  
  */


  Clear_Cart() {

    this.Selected_Customer = {};
    this.cart_item = [];
    try {
      this.Item_Master_Rows.forEach(x => x.Order_Qty = "0");
      // this.Item_Master_Rows.forEach(x => x.Rate = "0");
      // this.Item_Master_Rows.forEach(x => x.Rate = "0");
    } catch { }

  }




  Clear_Order_Cart() {

    this.Selected_Customer = {};
    this.cart_item = [];
    try {
      this.Item_Master_Rows.forEach(x => x.Order_Qty = "0");
      this.Perment_Item_Master_Row.forEach(x => x.Order_Qty = "0");

      this.Item_Master_Rows.forEach(x => x.Description = "");
      this.Perment_Item_Master_Row.forEach(x => x.Description = "");

    } catch { }

  }




  public assign = "Assign";

  public Pack = "Pack";

  add_item1(data, qty, pack, rate) {


    var qty_ = 0;
    var index = this.Item_Master_Rows.findIndex(function (item, i) {
      return item.ID === data.ID
    });


    var bag_Qty = this.Item_Master_Rows[index]["Bag_Qty"];

    if (index > -1) {

      qty_ = (Number(pack) * Number(bag_Qty)) + Number(qty);


      this.Item_Master_Rows[index]["Order_Qty"] = qty_;
      this.Item_Master_Rows[index]["Rate"] = rate;
      this.Item_Master_Rows[index]["Bag"] = pack;
      this.Item_Master_Rows[index]["Qty"] = qty;

      var inx = this.Perment_Item_Master_Row.findIndex(function (item, i) {
        return item.ID === data.ID
      });

      this.Perment_Item_Master_Row[inx]["Order_Qty"] = qty_;


      var ID = "";
      ID = this.Item_Master_Rows[index]["ID"];
      if (this.cart_item.length > 0) {
        var Temp_Index = this.cart_item.findIndex(function (item, i) {
          return item.ITEM_ID == ID;

        });

        if (Temp_Index > -1) {
          this.cart_item.splice(Temp_Index, 1);
        }
      }
    }




    var Disc_Rate = rate;
    var GST_per = data.GST_Per;
    var Final_Rate = 0.00;
    var Final_AMT = 0.00;
    var IGST_AMT = 0.00;
    var SGST_AMT = 0.00;
    var CGST_AMT = 0.00;

    var IGST_Per = 0.00;
    var SGST_Per = 0.00;
    var CGST_Per = 0.00;

    if (this.Tax_Type.toLowerCase() == "inclusive") {
      Final_Rate = (Number(Disc_Rate) / (100 + Number(GST_per))) * 100;
      Final_AMT = ((Number(Disc_Rate) / (100 + Number(GST_per))) * 100) * Number(qty_);
    }
    else {
      Final_Rate = Number(Disc_Rate);
      Final_AMT = Number(Disc_Rate) * Number(qty_);
    }

    if (this.GST_Type.toLowerCase() == "local") {
      IGST_AMT = 0.00;
      SGST_AMT = ((Number(Final_Rate) * Number(qty_)) / 100) * (Number(GST_per) / 2);
      CGST_AMT = ((Number(Final_Rate) * Number(qty_)) / 100) * (Number(GST_per) / 2);


      IGST_Per = 0.00;
      SGST_Per = (Number(GST_per) / 2);
      CGST_Per = (Number(GST_per) / 2);
    }
    else {
      IGST_AMT = ((Number(Final_Rate) * Number(qty_)) / 100) * (Number(GST_per));
      SGST_AMT = 0.00;
      CGST_AMT = 0.00;


      IGST_Per = (Number(GST_per));
      SGST_Per = 0.00;
      CGST_Per = 0.00;
    }





    var Temp_data = {

      'ITEM_ID': data.value,

      'ITEM_CODE': data.Part_No,
      'ITEM_NAME': data.label,
      'DESCRIPTION': data.Description,
      'HSNCode': data.HSN_Code,
      'UNIT': data.UOM,
      'BAG': '1',
      'Qty': qty_,
      'PURCHASE_RATE': data.Purchase_Rate,
      'RESALE_RATE': data.Wholesale_Rate,
      'SALE_RATE': rate,
      'MRP': data.compareAtPrice,
      'CUS_FREE': '0',
      'SOC_FREE': '0',
      'NET_RATE': rate,
      'TOT_AMT': Number(rate) * Number(qty_),
      'DISC_TYPE': '',
      'DISC_PER': '0',
      'DISC_AMT': '0',
      'TAXABLE_AMT': Final_AMT,
      'TAX_YESNO': '',
      'TAX_REVYES': '',
      'TAX_TYPE': '',
      'Bag': pack,
      'Pcs': qty,
      'GST_PER': data.GST_Per,
      'CGST_PER': CGST_Per,
      'SGST_PER': SGST_Per,
      'IGST_PER': IGST_Per,
      'CGST_TAX_AMT': CGST_AMT,
      'SGST_TAX_AMT': SGST_AMT,
      'IGST_TAX_AMT': IGST_AMT,
      'TOTAL_TAX': Number(CGST_AMT) + Number(SGST_AMT) + Number(IGST_AMT),
      'NET_AMT': Number(Final_AMT) + Number(CGST_AMT) + Number(SGST_AMT) + Number(IGST_AMT)
    }

    if (qty_ > 0) {
      this.cart_item.push(Temp_data)
    }

    this.cart_item.sort((a, b) => a["ITEM_NAME"].localeCompare(b["ITEM_NAME"]))

    this.C_Sub_Total = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.NET_AMT), 0)).toFixed(2);

    this.C_Taxable = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.TAXABLE_AMT), 0)).toFixed(2);
    this.C_IGST = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.IGST_TAX_AMT), 0)).toFixed(2);
    this.C_SGST = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.SGST_TAX_AMT), 0)).toFixed(2);
    this.C_CGST = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.CGST_TAX_AMT), 0)).toFixed(2);
    this.C_Tax = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.TOTAL_TAX), 0)).toFixed(2);

    this.C_Total_Payable = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.NET_AMT), 0)).toFixed(2);

    this.C_Savings = (this.cart_item.reduce((sum, current) => sum + ((parseFloat(current.MRP) - parseFloat(current.SALE_RATE)) * parseFloat(current.Qty)), 0)).toFixed(2);

  }


  add_item(data, qty) {




    var qty_ = 0;
    var index = this.Item_Master_Rows.findIndex(function (item, i) {
      return item.ID === data.ID
    });



    if (index > -1) {

      qty_ = Number(this.Item_Master_Rows[index]["Order_Qty"]) + Number(qty);

      this.Item_Master_Rows[index]["Order_Qty"] = qty_;
      var inx = this.Perment_Item_Master_Row.findIndex(function (item, i) {
        return item.ID === data.ID
      });

      this.Perment_Item_Master_Row[inx]["Order_Qty"] = qty_;


      var ID = "";
      ID = this.Item_Master_Rows[index]["ID"];
      if (this.cart_item.length > 0) {
        var Temp_Index = this.cart_item.findIndex(function (item, i) {
          return item.ITEM_ID == ID;

        });

        if (Temp_Index > -1) {
          this.cart_item.splice(Temp_Index, 1);
        }
      }
    }




    var Disc_Rate = data.Rate;
    var GST_per = data.GST_Per;
    var Final_Rate = 0.00;
    var Final_AMT = 0.00;
    var IGST_AMT = 0.00;
    var SGST_AMT = 0.00;
    var CGST_AMT = 0.00;

    var IGST_Per = 0.00;
    var SGST_Per = 0.00;
    var CGST_Per = 0.00;

    if (this.Tax_Type.toLowerCase() == "inclusive") {
      Final_Rate = (Number(Disc_Rate) / (100 + Number(GST_per))) * 100;
      Final_AMT = ((Number(Disc_Rate) / (100 + Number(GST_per))) * 100) * Number(qty_);
    }
    else {
      Final_Rate = Number(Disc_Rate);
      Final_AMT = Number(Disc_Rate) * Number(qty_);
    }

    if (this.GST_Type.toLowerCase() == "local") {
      IGST_AMT = 0.00;
      SGST_AMT = ((Number(Final_Rate) * Number(qty_)) / 100) * (Number(GST_per) / 2);
      CGST_AMT = ((Number(Final_Rate) * Number(qty_)) / 100) * (Number(GST_per) / 2);


      IGST_Per = 0.00;
      SGST_Per = (Number(GST_per) / 2);
      CGST_Per = (Number(GST_per) / 2);
    }
    else {
      IGST_AMT = ((Number(Final_Rate) * Number(qty_)) / 100) * (Number(GST_per));
      SGST_AMT = 0.00;
      CGST_AMT = 0.00;


      IGST_Per = (Number(GST_per));
      SGST_Per = 0.00;
      CGST_Per = 0.00;
    }








    var Temp_data = {

      'ITEM_ID': data.value,
      'ITEM_CODE': data.Item_Code,
      'ITEM_NAME': data.label,
      'DESCRIPTION': data.Description,
      'HSNCode': data.HSN_Code,
      'UNIT': data.UOM,
      'BAG': '1',
      'Qty': qty_,
      'PURCHASE_RATE': data.Purchase_Rate,
      'RESALE_RATE': data.Wholesale_Rate,
      'SALE_RATE': data.Rate,
      'MRP': data.compareAtPrice,
      'CUS_FREE': '0',
      'SOC_FREE': '0',
      'NET_RATE': data.Rate,
      'TOT_AMT': Number(data.Rate) * Number(qty_),
      'DISC_TYPE': '',
      'DISC_PER': '0',
      'DISC_AMT': '0',
      'TAXABLE_AMT': Number(data.Rate) * Number(qty_),
      'TAX_YESNO': '',
      'TAX_REVYES': '',
      'TAX_TYPE': '',
      'Bag': '1',
      'Pcs': '0',
      'GST_PER': data.GST_Per,
      'CGST_PER': CGST_Per,
      'SGST_PER': SGST_Per,
      'IGST_PER': IGST_Per,
      'CGST_TAX_AMT': CGST_AMT,
      'SGST_TAX_AMT': SGST_AMT,
      'IGST_TAX_AMT': IGST_AMT,
      'TOTAL_TAX': Number(CGST_AMT) + Number(SGST_AMT) + Number(IGST_AMT),
      'NET_AMT': (Number(data.Rate) * Number(qty_)) + Number(CGST_AMT) + Number(SGST_AMT) + Number(IGST_AMT)
    }

    if (qty_ > 0) {
      this.cart_item.push(Temp_data)
    }

    this.cart_item.sort((a, b) => a["ITEM_NAME"].localeCompare(b["ITEM_NAME"]))

    this.C_Sub_Total = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.NET_AMT), 0)).toFixed(2);

    this.C_Taxable = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.TAXABLE_AMT), 0)).toFixed(2);
    this.C_IGST = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.IGST_TAX_AMT), 0)).toFixed(2);
    this.C_SGST = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.SGST_TAX_AMT), 0)).toFixed(2);
    this.C_CGST = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.CGST_TAX_AMT), 0)).toFixed(2);
    this.C_Tax = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.TOTAL_TAX), 0)).toFixed(2);

    this.C_Total_Payable = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.NET_AMT), 0)).toFixed(2);

    this.C_Savings = (this.cart_item.reduce((sum, current) => sum + ((parseFloat(current.MRP) - parseFloat(current.SALE_RATE)) * parseFloat(current.Qty)), 0)).toFixed(2);

  }


  add_item2(data, qty, pack, rate) {


    var qty_ = 0;
    var index = this.Item_Master_Rows.findIndex(function (item, i) {
      return item.ID == data.ID
    });



    var bag_Qty = this.Item_Master_Rows[index]["Bag_Qty"];

    if (index > -1) {

      qty_ = (Number(pack) * Number(bag_Qty)) + Number(qty);


      this.Item_Master_Rows[index]["Order_Qty"] = qty_;
      this.Item_Master_Rows[index]["Rate"] = rate;
      this.Item_Master_Rows[index]["Bag"] = pack;
      this.Item_Master_Rows[index]["Qty"] = qty;

      var inx = this.Perment_Item_Master_Row.findIndex(function (item, i) {
        return item.ID === data.ID
      });

      this.Perment_Item_Master_Row[inx]["Order_Qty"] = qty_;


      var ID = "";
      ID = this.Item_Master_Rows[index]["ID"];
      if (this.cart_item.length > 0) {
        var Temp_Index = this.cart_item.findIndex(function (item, i) {
          return item.ITEM_ID == ID;

        });

        if (Temp_Index > -1) {
          this.cart_item.splice(Temp_Index, 1);
        }
      }
    }




    var Disc_Rate = data.Rate;
    var GST_per = data.GST_Per;
    var Final_Rate = 0.00;
    var Final_AMT = 0.00;
    var IGST_AMT = 0.00;
    var SGST_AMT = 0.00;
    var CGST_AMT = 0.00;

    var IGST_Per = 0.00;
    var SGST_Per = 0.00;
    var CGST_Per = 0.00;

    if (this.Tax_Type.toLowerCase() == "inclusive") {
      Final_Rate = (Number(Disc_Rate) / (100 + Number(GST_per))) * 100;
      Final_AMT = ((Number(Disc_Rate) / (100 + Number(GST_per))) * 100) * Number(qty_);
    }
    else {
      Final_Rate = Number(Disc_Rate);
      Final_AMT = Number(Disc_Rate) * Number(qty_);
    }

    if (this.GST_Type.toLocaleUpperCase() == "local") {
      IGST_AMT = 0.00;
      SGST_AMT = ((Number(Final_Rate) * Number(qty_)) / 100) * (Number(GST_per) / 2);
      CGST_AMT = ((Number(Final_Rate) * Number(qty_)) / 100) * (Number(GST_per) / 2);


      IGST_Per = 0.00;
      SGST_Per = (Number(GST_per) / 2);
      CGST_Per = (Number(GST_per) / 2);
    }
    else {
      IGST_AMT = ((Number(Final_Rate) * Number(qty_)) / 100) * (Number(GST_per));
      SGST_AMT = 0.00;
      CGST_AMT = 0.00;


      IGST_Per = (Number(GST_per));
      SGST_Per = 0.00;
      CGST_Per = 0.00;
    }





    var Temp_data = {

      'ITEM_ID': data.value,
      'ITEM_CODE': data.Part_No,
      'ITEM_NAME': data.label,
      'DESCRIPTION': data.Description,
      'HSNCode': data.HSN_Code,
      'UNIT': data.UOM,
      'BAG': '1',
      'Qty': qty_,
      'PURCHASE_RATE': data.Purchase_Rate,
      'RESALE_RATE': data.Wholesale_Rate,
      'SALE_RATE': rate,
      'MRP': data.compareAtPrice,
      'CUS_FREE': '0',
      'SOC_FREE': '0',
      'NET_RATE': rate,
      'TOT_AMT': Number(rate) * Number(qty_),
      'DISC_TYPE': '',
      'DISC_PER': '0',
      'DISC_AMT': '0',
      'TAXABLE_AMT': Final_AMT,
      'TAX_YESNO': '',
      'TAX_REVYES': '',
      'TAX_TYPE': '',
      'Bag': pack,
      'Pcs': qty,
      'GST_PER': data.GST_Per,
      'CGST_PER': CGST_Per,
      'SGST_PER': SGST_Per,
      'IGST_PER': IGST_Per,
      'CGST_TAX_AMT': CGST_AMT,
      'SGST_TAX_AMT': SGST_AMT,
      'IGST_TAX_AMT': IGST_AMT,
      'TOTAL_TAX': Number(CGST_AMT) + Number(SGST_AMT) + Number(IGST_AMT),
      'NET_AMT': Number(Final_AMT) + Number(CGST_AMT) + Number(SGST_AMT) + Number(IGST_AMT)
    }

    if (qty_ > 0) {
      this.cart_item.push(Temp_data)
    }

    this.cart_item.sort((a, b) => a["ITEM_NAME"].localeCompare(b["ITEM_NAME"]))

    this.C_Sub_Total = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.NET_AMT), 0)).toFixed(2);

    this.C_Taxable = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.TAXABLE_AMT), 0)).toFixed(2);
    this.C_IGST = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.IGST_TAX_AMT), 0)).toFixed(2);
    this.C_SGST = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.SGST_TAX_AMT), 0)).toFixed(2);
    this.C_CGST = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.CGST_TAX_AMT), 0)).toFixed(2);
    this.C_Tax = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.TOTAL_TAX), 0)).toFixed(2);

    this.C_Total_Payable = (this.cart_item.reduce((sum, current) => sum + parseFloat(current.NET_AMT), 0)).toFixed(2);

    this.C_Savings = (this.cart_item.reduce((sum, current) => sum + ((parseFloat(current.MRP) - parseFloat(current.SALE_RATE)) * parseFloat(current.Qty)), 0)).toFixed(2);

  }



  Search_Item_Category(data) {

    console.log(data);
    this.Selected_item_Category = data;
    if (data == "All") {
      this.Item_Master_Rows = this.Perment_Item_Master_Row;

    }
    else {
      this.Item_Master_Rows = this.Perment_Item_Master_Row.filter(e => e.Category.toLowerCase().includes(data.toLowerCase()));
    }

  }



  public Room_Check_OUT_Rows = [];
  get_Room_Check_out() {
    this.getc("Api/Transaction/get_Room_Check_OUT").subscribe((res: any) => {
      this.Room_Check_OUT_Rows = JSON.parse(res).record;
    });
  }




  public Advance_Collection_Rows = [];
  get_Advance_Collection() {
    this.get("Api/Transaction/get_Advance_Collection?From=" + this.From_Date.formatted + "&To=" + this.To_Date.formatted).subscribe((res: any) => {
      this.Advance_Collection_Rows = JSON.parse(res).record;
    });
  }

  public Room_Check_IN_Rows = [];
  get_Room_Check_IN() {
    this.getc("Api/Transaction/get_Room_Check_IN").subscribe((res: any) => {
      this.Room_Check_IN_Rows = JSON.parse(res).record;
    });
  }

  public ledger_count = 0;
  public Ledger_Order_by = "created_date desc";
  public Ledger_Master_Rows = [];
  public PLedger_Master_Rows = [];
  public SLedger_Rows = [];
  public LedgerLoad: boolean = false;
  public Entrywise_Ledger_Rows = [];

  get_Ledger_Master() {
    this.LedgerLoad = true;

    this.isload = true;
    this.Ledger_Master_Rows = [];
    this.get("Api/Master/get_Ledger_Master1?Lfrom=" + this.Lfrom + "&Area=" + this.S_Area + "&Order=" + this.Ledger_Order_by + "&Group_ID=" + this.Group_ID).subscribe((res: any) => {
      this.isload = false;
      this.LedgerLoad = false;

      this.Ledger_Master_Rows = JSON.parse(res).record;
      this.PLedger_Master_Rows = JSON.parse(res).record;
      this.Entrywise_Ledger_Rows=this.Ledger_Master_Rows;
      this.Entrywise_Ledger_Rows.sort((a, b) => a["Ledger_Name"].localeCompare(b["Ledger_Name"]))

      this.ledger_Filter();
      try {
        this.ledger_count = this.Ledger_Master_Rows.length;
      } catch { }
    });
  }



  get_Ledger_group(data)
  {
      return  this.Ledger_Master_Rows.filter(e=>e.Group_ID==data);
  }



  ledger_Filter() {

    this.SLedger_Rows = this.PLedger_Master_Rows.filter(e => e.Group_ID == this.Ledger_Type || e.Group_ID == "3");

  }

  public Member_Order_by = "Created_Date desc";
  public Member_Details_Rows = [];
  get_Member_Details() {
    this.isload = true;
    this.Member_Details_Rows = [];
    this.get("Api/Master/get_Member_Details?From=" + this.S_From + "&To=" + this.S_To + "&User=" + this.CREATED_BY + "&Order=" + this.Member_Order_by).subscribe((res: any) => {
      this.isload = false;
      this.Member_Details_Rows = JSON.parse(res).record;
    });
  }


  public Room_Type_Rows = [];
  get_Room_Type() {
    this.getc("Api/Master/get_Room_Type").subscribe((res: any) => {
      this.Room_Type_Rows = JSON.parse(res).record;
    });
  }



  Generate_Bill() {
    this.isload = true;
    this.get("Api/Transaction/Generate_Bill?Month=" + this.S_From + "&User=" + this.CREATED_BY).subscribe((res: any) => {
      this.isload = false;
      this.toastr.success("Generated Success");
    });
  }

  Customer_Rows = [];
  get_Customer() {
    this.getc("Api/Master/get_ledger").subscribe((res: any) => {
      this.Customer_Rows = JSON.parse(res).record;
    });
  }

  Reference_Rows = [];
  get_Reference() {


    this.getc("Api/Master/get_reference").subscribe((res: any) => {
      this.Reference_Rows = JSON.parse(res).record;
      
      this.Area_Row = this.Reference_Rows.filter(e => e.Ref_ID == "Area");

      this.Exp_Category = this.Reference_Rows.filter(e => e.Ref_ID == "Exp_Category");

      this.get_pay_mode_ID();
    });
  }

  public Room_Master_Rows = [];
  get_rooms() {
    this.getc("Api/Master/get_rooms").subscribe((res: any) => {
      this.Room_Master_Rows = JSON.parse(res).record;


    });
  }





  public sales_compare = "0.00";
  public sales_compare_bar = 7.00;
  public Sales_DB = [{ Date: this.Today_Date, Amount: 0, Qty: 0 }, { Date: this.Y_Date, Amount: 1, Qty: 1 }];
  get_Sales_DB() {
    this.get("Api/Invoice/get_Sales_DB?Length=7").subscribe((res: any) => {

      if (res != "[]") {
        this.Sales_DB = JSON.parse(res).record;


        if (this.Sales_DB.length == 1) {
          this.Sales_DB.push({ Date: this.Y_Date, Amount: this.Sales_DB[0]["Amount"], Qty: this.Sales_DB[0]["Qty"] });
        }

        this.sales_compare = ((((this.Sales_DB[0]["Amount"]) - this.Sales_DB[1]["Amount"]) / this.Sales_DB[1]["Amount"]) * 100).toFixed(2);
        this.sales_compare_bar = Math.abs(Number(this.sales_compare));



      }
    });

  }
  public purchase_compare = "0.00";
  public purchase_compare_bar = 7.00;
  public purchase_DB = [{ Date: this.Today_Date, Amount: 0, Qty: 0 }, { Date: this.Y_Date, Amount: 1, Qty: 1 }];
  get_purchase_DB() {
    this.get("Api/Invoice/get_Purchase_DB?Length=7").subscribe((res: any) => {

      if (res != "[]") {
        this.purchase_DB = JSON.parse(res).record;


        if (this.purchase_DB.length == 1) {
          this.purchase_DB.push({ Date: this.Y_Date, Amount: this.purchase_DB[0]["Amount"], Qty: this.purchase_DB[0]["Qty"] });
        }

        this.purchase_compare = ((((this.purchase_DB[0]["Amount"]) - this.purchase_DB[1]["Amount"]) / this.purchase_DB[1]["Amount"]) * 100).toFixed(2);
        this.purchase_compare_bar = Math.abs(Number(this.purchase_compare));



      }
    });

  }
  public Expence_compare = "0.00";
  public Expence_compare_bar = 7.00;
  public Expence_DB = [{ Date: this.Today_Date, Amount: 0, Qty: 0 }, { Date: this.Y_Date, Amount: 1, Qty: 1 }];
  get_Expence_DB() {
    this.get("Api/Invoice/get_Expence_DB?Length=7").subscribe((res: any) => {

      if (res != "[]") {
        this.Expence_DB = JSON.parse(res).record;


        if (this.Expence_DB.length == 1) {
          this.Expence_DB.push({ Date: this.Y_Date, Amount: this.Expence_DB[0]["Amount"], Qty: this.Expence_DB[0]["Qty"] });
        }

        this.Expence_compare = ((((this.Expence_DB[0]["Amount"]) - this.Expence_DB[1]["Amount"]) / this.Expence_DB[1]["Amount"]) * 100).toFixed(2);
        this.Expence_compare_bar = Math.abs(Number(this.Expence_compare));



      }
    });

  }
  public Order_DB = [{ Date: this.Today_Date, Amount: 0, Qty: 0 }, { Date: this.Y_Date, Amount: 0, Qty: 0 }];
  get_Order_DB() {

    this.get("Api/Invoice/get_Order_DB?Length=7").subscribe((res: any) => {
      if (res != "[]") {
        this.Order_DB = JSON.parse(res).record;
      }
    });
  }


  public Pending_Order_DB = [{ Pending: 0, Delivery: 0, T_Pending: 0, T_Orders: 0, Y_Orders: 0, Y_Pending: 0, Y_Delivery: 0 }];
  get_Pending_Order_DB() {

    this.getc("Api/Invoice/get_Order_DB").subscribe((res: any) => {

      if (res != "[]") {
        this.Pending_Order_DB = JSON.parse(res).record;




      }

    });
  }

  public Collection_DB = [{ Date: this.Today_Date, Amount: 0, Qty: 0 }, { Date: this.Y_Date, Amount: 0, Qty: 0 }];;
  get_Collection_DB() {
    this.get("Api/Invoice/get_Collection_DB?Length=7").subscribe((res: any) => {
      if (res != "[]") {
        this.Collection_DB = JSON.parse(res).record;

        if (this.Collection_DB.length == 1) {
          this.Collection_DB.push({ Date: this.Y_Date, Amount: 0, Qty: 1 });
        }
      }
    });
  }

  public DB_Data = [{ Sales: 0, Collection: 0, Expense: 0, Out_standing: 0 }];;
  get_DB_Data() {
    this.getc("Api/Invoice/get_DB_Data").subscribe((res: any) => {
      if (res != "[]") {
        this.DB_Data = JSON.parse(res).record;


      }
    });
  }






  Make_Delivery(Order_No) {
    this.get("Api/Invoice/Make_Delivery?Order_No=" + Order_No).subscribe((res: any) => {

      this.toastr.success("Delivered  Success..", 'Msg');
      this.back();
      this.get_orders();
      this.get_Order_DB();
      this.get_Pending_Order_DB();
      this.Clear_Cart();

    });
  }




  public Room_CIN_Details = [];

  get_rooms_CIN_Details() {

    this.getc("Api/Master/get_room_CIN_ID").subscribe((res: any) => {
      this.Room_CIN_Details = JSON.parse(res).record;
    });

  }


  view_Data(data) {
    this.router.navigate(['view-order-details']);
  }


  public Bank_Master_Rows = [];
  get_Bank_Master() {
    this.getc("Api/Master/get_Bank_Master").subscribe((res: any) => {
      this.Bank_Master_Rows = JSON.parse(res).record;
    });
  }





  get_dmk_Data() {

    this.get_Thoguthi_Master();
    this.get_Ondriyam_Master();
    this.get_Ondriyam_Peruratchi_Master();
    this.get_Ondriyam_Peruratchi_Ward_Master();
    this.get_Ondriyam_Ooratchi_Master();
    this.get_Ondriyam_Ooratchi_Kilai_Master();
    this.get_Nagaram_Master();
    this.get_Nagaram_Ward_Master();
    this.get_Total_Members();

  }


  public Thoguthi_Master_Rows = [];
  get_Thoguthi_Master() {

    this.getc("Api/Master/get_Thoguthi_Details").subscribe((res: any) => {
      this.Thoguthi_Master_Rows = JSON.parse(res).record;
    });

  }
  public Area_Street_Rows = [];
  get_Area_Street() {
    this.isload = true;

    this.getc("Api/Master/get_Area_Street_Details").subscribe((res: any) => {
      this.Area_Street_Rows = JSON.parse(res).record;
      this.isload = false;

    });

  }




  public Copy_Type = "ORIGINAL";
  public Sales_Return_Status="Pending";
  public Printer_width = "58";

  Mobile_Print(data) {
    window.open(this.Server_URL + "report/Mobile_Print?User=Bill_Print&Company=" + this.Company + "&Bill_No=" + data.Bill_No + "&Format=" + this.Printer_width + "&Blue_Address=", "_blank");
  }


  Label_Print(data) {
    window.open(this.Server_URL + "report/Label_Print?User=Bill_Print&Company=" + this.Company + "&Bill_No=" + data.Bill_No + "&Format=" + this.Printer_width + "&Blue_Address=", "_blank");
  }


  open_pdf(data) {
    if (this.Bill_Format == "Format1") {
      var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + data.Bill_No.replace(/[^a-zA-Z0-9 ]/g, "_") + "&File_Type=pdf&Bill_No=" + data.Bill_No;
      window.open(this.Server_URL + "report/Print_Sales_Bills_Tally_Foramt?" + parm, "_blank");
    }

    else if (this.Bill_Format == "Receipt_2inch") {
      this.get("Api/invoice/Receipt_2inch?Bill_No=" + data.Bill_No).subscribe((res: any) => { });
    }
    else if (this.Bill_Format == "Re-Print") {
      this.get("Api/Hotel/Reprint_takeaway?Bill_No=" + data.Bill_No).subscribe((res: any) => { });
    }
    else if (this.Bill_Format == "Mobile_Shop") {
      var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + data.Bill_No.replace(/[^a-zA-Z0-9 ]/g, "_") + "&File_Type=pdf&Bill_No=" + data.Bill_No;
      window.open(this.Server_URL + "report/Print_Mobile_Shop_Foramt?" + parm, "_blank");
    }
    else if (this.Bill_Format == "Format2") {
      var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + data.Bill_No.replace(/[^a-zA-Z0-9 ]/g, "_") + "&File_Type=pdf&Bill_No=" + data.Bill_No;
      window.open(this.Server_URL + "PDF/Export_Invoice_1?" + parm, "_blank");

    } else if (this.Bill_Format == "Format3") {
      var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + data.Bill_No.replace(/[^a-zA-Z0-9 ]/g, "_") + "&File_Type=pdf&Bill_No=" + data.Bill_No;
      window.open(this.Server_URL + "PDF/Export_Invoice_2?" + parm, "_blank");

    }
    else if (this.Bill_Format == "Format4") {
      window.open(this.Server_URL + "report/Print_Sales_Bills?Bill_No=" + data.Bill_No + "&Company=" + this.Company, "_blank");

    }
    else if (this.Bill_Format == "Format5") {
      window.open(this.Server_URL + "report/Print_Sales_Bills1?Bill_No=" + data.Bill_No + "&Company=" + this.Company, "_blank");
    }
    else if (this.Bill_Format == "PRS_A4") {
      window.open(this.Server_URL + "report/PRS_Bill?User=" + this.CREATED_BY + "&File_Name=" + data.Bill_No.replace(/[^a-zA-Z0-9 ]/g, "_") + "&File_Type=pdf&Bill_No=" + data.Bill_No + "&Company=" + this.Company + "&Copy_Type=" + this.Copy_Type, "_blank");

    }
    else if (this.Bill_Format == "PRS_A3") {
      window.open(this.Server_URL + "report/PRS_Bill2?Bill_No=" + data.Bill_No + "&Company=" + this.Company, "_blank");

    }
    else if (this.Bill_Format == "Gnanavi_New") {
      window.open(this.Server_URL + "report/Gnanavi_New?Bill_No=" + data.Bill_No + "&Company=" + this.Company, "_blank");

    }
    else if (this.Bill_Format == "Gnanavika_Bill") {
      window.open(this.Server_URL + "report/Gnanavika_Bill?Bill_No=" + data.Bill_No + "&Company=" + this.Company, "_blank");

    }
    else if (this.Bill_Format == "SBR_A3") {
      window.open(this.Server_URL + "report/SBR_Bill?Bill_No=" + data.Bill_No + "&Company=" + this.Company, "_blank");

    }
    else if (this.Bill_Format == "PRS") {
      var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + data.Bill_No.replace(/[^a-zA-Z0-9 ]/g, "_") + "&File_Type=pdf&Bill_No=" + data.Bill_No;
      window.open(this.Server_URL + "report/SSN_Bill?" + parm, "_blank");


    }
    else if (this.Bill_Format == "SSN") {
      var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + data.Bill_No.replace(/[^a-zA-Z0-9 ]/g, "_") + "&File_Type=pdf&Bill_No=" + data.Bill_No;
      window.open(this.Server_URL + "report/SSN_Bill?" + parm, "_blank");


    }
    else if (this.Bill_Format == "LSP") {
      window.open(this.Server_URL + "report/GST_Bill?Bill_No=" + data.Bill_No + "&Company=" + this.Company, "_blank");
    } 
    else if (this.Bill_Format == "Yuvaraj") {
      window.open(this.Server_URL + "report/Shop_Bill?Bill_No=" + data.Bill_No + "&Company=" + this.Company, "_blank");
    }  
    else if (this.Bill_Format == "Badhri") {
      window.open(this.Server_URL + "report/Bill?Bill_No=" + data.Bill_No + "&Company=" + this.Company, "_blank");

    } 
    else if (this.Bill_Format == "jayashree") {
      window.open(this.Server_URL + "report/GST_Bill_?Bill_No=" + data.Bill_No + "&Company=" + this.Company, "_blank");
    }  
    else if (this.Bill_Format == "jayashree_New") {
      window.open(this.Server_URL + "report/Jayasree_New_Bill?Bill_No=" + data.Bill_No + "&Company=" + this.Company, "_blank");
    }   
    else if (this.Bill_Format == "PRS_NT_A3") {
      var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + data.Bill_No.replace(/[^a-zA-Z0-9 ]/g, "_") + "&File_Type=pdf&Bill_No=" + data.Bill_No;
      window.open(this.Server_URL + "report/Print_Sales_Bills_Non_Tax?" + parm, "_blank");

    }

    else if (this.Bill_Format == "Osai") {
      window.open(this.Server_URL + "report/Osai_Bill?User=" + this.CREATED_BY + "&File_Name=" + data.Bill_No.replace(/[^a-zA-Z0-9 ]/g, "_") + "&File_Type=pdf&Bill_No=" + data.Bill_No + "&Company=" + this.Company + "&Copy_Type=" + this.Copy_Type, "_blank");

    }
    else if (this.Bill_Format == "Klisters") {

      window.open(this.Server_URL + "report/Klisters_Sales_Bill?User=" + this.CREATED_BY + "&File_Name=" + data.Bill_No.replace(/[^a-zA-Z0-9 ]/g, "_") + "&File_Type=pdf&Bill_No=" + data.Bill_No + "&Company=" + this.Company + "&Copy_Type=" + this.Copy_Type, "_blank");


    }

 
    else if (this.Bill_Format == "Osai_Mobile") {

      window.open(this.Server_URL + "report/Mobile_Print?User=Bill_Print&Company=" + this.Company + "&Bill_No=" + data.Bill_No + "&Format=" + this.Printer_width + "&Blue_Address=", "_blank");
    }
  

  }
  public DC_Format = "Klisters";
  open_delivery(data) {


    if (this.DC_Format == "Klisters1") {
      var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + data.DC_No.replace(/[^a-zA-Z0-9 ]/g, "_") + "&File_Type=pdf&Bill_No=" + data.Bill_No;
      window.open(this.Server_URL + "report/Klisters_Delivery_Bill?" + parm, "_blank");

    }
    else if (this.DC_Format == "Klisters") {
      window.open(this.Server_URL + "report/Klisters_Delivery_Bill?Bill_No=" + data.DC_No + "&Company=" + this.Company, "_blank");

    }


  }
  open_pdf_Non_Tax(data) {


    if (this.NT_Bill_Format == "NT_Format1") {
      var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + data.Bill_No.replace(/[^a-zA-Z0-9 ]/g, "_") + "&File_Type=pdf&Bill_No=" + data.Bill_No;
      window.open(this.Server_URL + "report/Print_Sales_Bills_Non_Tax?" + parm, "_blank");

    }

    else if (this.NT_Bill_Format == "Klisters") {
      var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + data.Bill_No.replace(/[^a-zA-Z0-9 ]/g, "_") + "&File_Type=pdf&Bill_No=" + data.Bill_No;
      window.open(this.Server_URL + "report/Klisters_Non_Tax_Bill?" + parm, "_blank");


    }
  }
  public P_Invoice_Format = "Format1"
  proforma_pdf(data) {
    if (this.P_Invoice_Format == "Format1") {
      ``
      var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + data.Bill_No.replace(/[^a-zA-Z0-9 ]/g, "_") + "&File_Type=pdf&Bill_No=" + data.Bill_No;
      window.open(this.Server_URL + "PDF/Export_P_Invoice_1?" + parm, "_blank");
    }
    else if (this.P_Invoice_Format == "Klisters") {
      window.open(this.Server_URL + "report/Klisters_Proforma_Bill?Bill_No=" + data.Bill_No + "&Company=" + this.Company, "_blank");



    }
  }
  public Delivery_Format = "Format1"
  Delivery_pdf(data) {
    if (this.Delivery_Format == "Format2") {
      var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + data.Bill_No.replace(/[^a-zA-Z0-9 ]/g, "_") + "&File_Type=pdf&Bill_No=" + data.Bill_No;
      window.open(this.Server_URL + "PDF/Export_P_Invoice_1?" + parm, "_blank");
    }
    else if (this.Delivery_Format == "Format1") {
      window.open(this.Server_URL + "report/Print_Customerwise?Bill_No=" + data.Bill_No + "&Company=" + this.Company, "_blank");



    }
  }
  public PO_Format = "Format1";
  open_purchase_pdf(data) {
   
      window.open(this.Server_URL + "report/Klisters_PO_Bill?PO_No=" + data.PO_No + "&Company=" + this.Company, "_blank");

    


  }
  get_street(Area) {

    return this.Area_Street_Rows.filter(e => String(e.Area).toLowerCase() == String(Area).toLowerCase());
  }
  public Ondriyam_Master_Rows = [];
  get_Ondriyam_Master() {

    this.getc("Api/Master/get_Ondriyam_Details").subscribe((res: any) => {
      this.Ondriyam_Master_Rows = JSON.parse(res).record;

    });

  }

  get_Nagaram(data) {

    var r = [];
    if (this.Nagaram_Master_Rows) {
      r = this.Nagaram_Master_Rows.filter(e => String(e.Thoguthi).toLowerCase() == String(data).toLowerCase());
    }

    return r;
  }


  get_Nagaram_Ward(Thoguthi, data) {

    var r = [];
    if (this.Nagaram_Ward_Master_Rows) {
      r = this.Nagaram_Ward_Master_Rows.filter(e => String(e.Thoguthi).toLowerCase() == String(Thoguthi).toLowerCase());
      r = r.filter(e => String(e.Nagaram).toLowerCase() == String(data).toLowerCase());

    }

    return r;
  }


  get_Ondriyam(data) {

    var r = [];
    if (this.Ondriyam_Master_Rows) {
      r = this.Ondriyam_Master_Rows.filter(e => String(e.Thoguthi).toLowerCase() == String(data).toLowerCase())
    }

    return r;

  }



  get_Peruratchi(Thoguthi, data) {
    var r = [];
    if (this.Ondriyam_Peruratchi_Master_Rows) {
      r = this.Ondriyam_Peruratchi_Master_Rows.filter(e => String(e.Thoguthi).toLowerCase() == String(Thoguthi).toLowerCase());
      r = r.filter(e => String(e.Ondriyam).toLowerCase() == String(data).toLowerCase());
    }


    return r;
  }


  get_Peruratchi_Ward(Thoguthi, Ondriyam, data) {
    var r = [];
    if (this.Ondriyam_Peruratchi_Ward_Master_Rows) {
      r = this.Ondriyam_Peruratchi_Ward_Master_Rows.filter(e => String(e.Thoguthi).toLowerCase() == String(Thoguthi).toLowerCase());
      r = r.filter(e => String(e.Ondriyam).toLowerCase() == String(Ondriyam).toLowerCase());
      r = r.filter(e => String(e.Peruratchi).toLowerCase() == String(data).toLowerCase());
    }
    return r;
  }


  get_Ondriyam_Ooratchi(Thoguthi, data) {

    console.log(Thoguthi, data)

    console.log(this.Ondriyam_Ooratchi_Master_Rows)
    var r = [];
    if (this.Ondriyam_Ooratchi_Master_Rows) {
      r = this.Ondriyam_Ooratchi_Master_Rows.filter(e => String(e.Thoguthi).toLowerCase() == String(Thoguthi).toLowerCase());
      r = r.filter(e => String(e.Ondriyam).toLowerCase() == String(data).toLowerCase());
    }
    console.log(r);
    return r;
  }



  get_Ondriyam_Ooratchi_Kilai(Thoguthi, Ondriyam, data) {


    var r = [];
    if (this.Ondriyam_Ooratchi_Kilai_Master_Rows) {
      r = this.Ondriyam_Ooratchi_Kilai_Master_Rows.filter(e => String(e.Thoguthi).toLowerCase() == String(Thoguthi).toLowerCase());
      r = r.filter(e => String(e.Ondriyam).toLowerCase() == String(Ondriyam).toLowerCase());
      r = r.filter(e => String(e.Ooratchi).toLowerCase() == String(data).toLowerCase());
    }

    return r;
  }



  public Ondriyam_Peruratchi_Master_Rows = [];
  get_Ondriyam_Peruratchi_Master() {

    this.getc("Api/Master/get_Ondriyam_Peruratchi_Details").subscribe((res: any) => {
      this.Ondriyam_Peruratchi_Master_Rows = JSON.parse(res).record;
    });

  }
  public Ondriyam_Peruratchi_Ward_Master_Rows = [];
  get_Ondriyam_Peruratchi_Ward_Master() {

    this.getc("Api/Master/get_Ondriyam_Peruratchi_Ward_Details").subscribe((res: any) => {
      this.Ondriyam_Peruratchi_Ward_Master_Rows = JSON.parse(res).record;
    });

  }
  public Ondriyam_Ooratchi_Master_Rows = [];
  get_Ondriyam_Ooratchi_Master() {

    this.getc("Api/Master/get_Ondriyam_Ooratchi_Details").subscribe((res: any) => {
      this.Ondriyam_Ooratchi_Master_Rows = JSON.parse(res).record;
    });

  }
  public Ondriyam_Ooratchi_Kilai_Master_Rows = [];
  get_Ondriyam_Ooratchi_Kilai_Master() {

    this.getc("Api/Master/get_Ondriyam_Ooratchi_Kilai_Details").subscribe((res: any) => {
      this.Ondriyam_Ooratchi_Kilai_Master_Rows = JSON.parse(res).record;
    });

  }
  public Nagaram_Master_Rows = [];
  get_Nagaram_Master() {

    this.getc("Api/Master/get_Nagaram_Details").subscribe((res: any) => {
      this.Nagaram_Master_Rows = JSON.parse(res).record;
    });

  }
  public Nagaram_Ward_Master_Rows = [];
  get_Nagaram_Ward_Master() {

    this.getc("Api/Master/get_Nagaram_Ward_Details").subscribe((res: any) => {
      this.Nagaram_Ward_Master_Rows = JSON.parse(res).record;
    });

  }
  public Employee_Details_Rows = [];
  get_Employee_Details() {

    this.getc("Api/Master/get_Employee_Details").subscribe((res: any) => {
      this.Employee_Details_Rows = JSON.parse(res).record;
    });

  }

  get_Thoguthi(Data) {

    var data = this.Thoguthi_Master_Rows.filter(e => String(e.District).toLowerCase() == String(Data).toLowerCase()).sort((a, b) => (a["Thoguthi"]) - ((b["Thoguthi"])));
    return data;
  }



  pdfType = "Open";


  get(url) {


    this.access_tocken = this.tocken;
    var reqHeader = new HttpHeaders({
      'Content-Type': 'text/plain',
      'Authorization': 'Bearer ' + this.access_tocken
    });


    return this.http.get(this.Server_URL + "" + url + "&Company=" + this.Company, { headers: reqHeader })
  }

  getc(url) {

    this.access_tocken = this.tocken;
    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.access_tocken
    });

    return this.http.get(this.Server_URL + "" + url + "?Company=" + this.Company, { headers: reqHeader });
  }



  d_get(url) {


    this.access_tocken = this.tocken;
    var reqHeader = new HttpHeaders({
      'Content-Type': 'text/plain',
      'Authorization': 'Bearer ' + this.access_tocken
    });


    return this.http.get(url + "&Company=" + this.Company, { headers: reqHeader })
  }



  dir_get(url) {
    return this.http.get(url + "&Company=" + this.Company)
  }


  public load_page(data) {
    this.router.navigate([data], { replaceUrl: true });
  }

  public load_page1(data) {
    this.router.navigate([data]);
  }







  post_data(data, Table) {

    data.Company = this.Company;
    data.Created_by = this.CREATED_BY;
    data.Table_Name = Table;

    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });
    this.isload = true;
    this.http.post(this.Server_URL + 'api/Master/Post_Data', data, { headers: this.headers })
      .subscribe(
        (val: string) => {
          this.isload = false;
          return val;
        },
        response => {
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });
          return "Error Contact Admin";
        });

    //return "Problem"
  }



  public tocken = "";
  get_Token() {



    this.headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });
    this.isload = true;
    this.http.post(this.Server_URL + 'token', 'grant_type=password&UserName=admin&Password=admin', { headers: this.headers })
      .subscribe(
        (val) => {
          this.tocken = val['access_token'];

        },
        response => {
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });
          return "Error Contact Admin";
        });

    //return "Problem"
  }



  public length_of(dt) {

    var len = 0;
    try {
      len = dt.filteredValue.length;
      return len
    } catch { }


    if (len == 0) {
      try {
        len = dt.value.length;
      } catch { }
    }

    return len;
  }



  public sum_of(dt, field) {

    var len = 0;
    try {
      len = dt.filteredValue.length;
      return dt.filteredValue.reduce((sum, current) => sum + parseFloat(current[field]), 0);
    } catch { }


    if (len == 0) {
      try {
        len = dt.value.length;
        return dt.value.reduce((sum, current) => sum + parseFloat(current[field]), 0);
      } catch { }
    }

    return len;
  }



  public Excel_Data: any = {
    'ID': '',
    "item": '',
    "Header": '',
    "Footer": '',
    "Left_Data": '',
    "Right_Data": '',
    "Report_Name": ''

  };
  headers;

  export_excel() {

    this.Excel_Data.Company = this.Company;
    this.Excel_Data.User = this.CREATED_BY;
    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });

    this.http.post(this.Server_URL + 'api/master/JsontToExcel', this.Excel_Data, { headers: this.headers })
      .subscribe(
        (val: string) => {

          if (val == "True") {

            var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + this.File_Name + "&File_Type=xls";
            window.open(this.Server_URL + "Report/JsontToExcel?" + parm, "_blank");
            this.toastr.success("Data Exported  Successfully", 'Msg');

          }
          else {

            console.log(val);
            this.toastr.error(val, "Error", { timeOut: 3000 });
          }
        },
        response => {
          console.log(response);
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });

        });

  }
  upload_excel() {

    this.Excel_Data.Company = this.Company;
    this.Excel_Data.User = this.CREATED_BY;
    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });

    this.http.post(this.Server_URL + 'api/master/JsontToExcel', this.Excel_Data, { headers: this.headers })
      .subscribe(
        (val: string) => {

          if (val == "True") {

            var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + this.File_Name + "&File_Type=xls";
            window.open(this.Server_URL + "Report/JsontToExcel_Upload?" + parm, "_blank");
            this.toastr.success("Data Exported  Successfully", 'Msg');

          }
          else {

            console.log(val);
            this.toastr.error(val, "Error", { timeOut: 3000 });
          }
        },
        response => {
          console.log(response);
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });

        });

  }

  File_Name = "Data";

  export_pdf() {


    this.Excel_Data.Company_Data = JSON.parse(JSON.stringify(this.Current_User));
    this.Excel_Data.Company = this.Company;
    this.Excel_Data.User = this.CREATED_BY;

    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });

    this.http.post(this.Server_URL + 'api/master/JsontToExcel', this.Excel_Data, { headers: this.headers })
      .subscribe(
        (val: string) => {

          if (val == "True") {
            var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + this.File_Name + "&File_Type=pdf";
            window.open(this.Server_URL + "PDF/Export_Pdf?" + parm, "_blank");
            this.toastr.success("Data Exported  Successfully", 'Msg');

          }
          else {

            console.log(val);
            this.toastr.error(val, "Error", { timeOut: 3000 });
          }
        },
        response => {
          console.log(response);
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });

        });

  }

  S_Ledger_ID = "";
  S_Ledger_Name = "";
  Ledger_ID = "0";
  Ledger_cr = 0;
  Ledger_db = 0;
  Ledger_Total = 0;
  Ledger_File_Name = "Data";
  public BILLNO;
  export_pdf1() {


    this.Excel_Data.Company_Data = JSON.parse(JSON.stringify(this.Current_User));
    this.Excel_Data.Company = this.Company;
    this.Excel_Data.User = this.CREATED_BY;

    this.Excel_Data.Ledger_ID = this.Ledger_ID
    this.Excel_Data.From = this.S_From;
    this.Excel_Data.To = this.S_To;
    this.Excel_Data.cr = this.Ledger_cr;

    this.Excel_Data.db = this.Ledger_db;
    this.Excel_Data.Total = this.Ledger_Total;


    this.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });

    this.http.post(this.Server_URL + 'api/master/JsontToExcel', this.Excel_Data, { headers: this.headers })
      .subscribe(
        (val: string) => {

          if (val == "True") {
            var parm = "User=" + this.CREATED_BY + "&Company=" + this.Company + "&File_Name=" + this.File_Name + "&File_Type=pdf";
            window.open(this.Server_URL + "PDF/Export_Pdfx?" + parm, "_blank");
            this.toastr.success("Data Exported  Successfully", 'Msg');

          }
          else {

            console.log(val);
            this.toastr.error(val, "Error", { timeOut: 3000 });
          }
        },
        response => {
          console.log(response);
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });

        });

  }

}

import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
declare let $: any;

@Component({
  selector: 'app-add-item1',
  templateUrl: './add-item1.component.html',
  styleUrls: ['./add-item1.component.scss']
})
export class AddItem1Component implements OnInit {

  add:any={};
  Items=[];
  add1=[];
  date="";
  public btndisable:boolean=false;
  constructor(public datePipe:DatePipe,public appservice:AppService,private toastr: ToastrService, private http: HttpClient) {

    this.date = this.datePipe.transform(new Date(),'ddMMyyyyhhmmss');
    
    if(this.appservice.isEdit)
    {
      this.add=appservice.Edit_Row;
      this.get_uoms();

    }
    else
    {
      this.clear();
      this.add.ID="0";

    }
    this.server=appservice.Server_URL+"Api/Master/UploadItemImage?ID="+this.add.ID+"&Company="+appservice.Company+"&Name="+this.add.Item_Code+"&Description="+this.add.Description;
  

   }

   get_uoms()
   {
     
    this.Items=[];
    this.Items=this.appservice.Item_Master_Rows.filter(e => e.Item_Name === this.add.Item_Name);
    this.Items=this.Items.sort((b, a) => Number(b["Size"]) - Number((a["Size"])));

   }
   

   kg_Price=0.0;

   Change_Price()
   {


    this.Items.forEach(x => x.Rate = parseFloat(x.Size)*this.kg_Price);

    

   }



   Update_Price()
   {
    this.appservice.loading=true;

    for(var i=0;i<this.Items.length;i++)
    {
     
      this.Items[i].Company=this.appservice.Company;
      this.Items[i].Company_ID=this.appservice.Company;
      this.Items[i].Created_by=this.appservice.CREATED_BY;
      this.Items[i].Table_Name="Item_Master";
      this.Items[i].Item_Name=this.add.Item_Name;
      this.Items[i].Item_TamilName=this.add.Item_TamilName;
      this.Items[i].Item_Status=this.add.Item_Status;
      this.Items[i].Offer=this.add.Offer;
      this.Items[i].GST_Per=this.add.GST_Per;
      

      this.http.post(this.appservice.Server_URL + 'api/Master/Post_Data', this.Items[i], { headers: this.appservice.headers })
      .subscribe(
        (val: string) => {

         
          this.get_Item_Master();
            this.appservice.loading=true;          
       
        },
        response => {
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });
          
        });

    }
   
   }



   add_New_UOM()
   {

    this.appservice.loading=false;
  
    this.add.ID="0";
      this.add.Company=this.appservice.Company;
      this.add.Company_ID=this.appservice.Company;
      this.add.Created_by=this.appservice.CREATED_BY;
      this.add.Table_Name="Item_Master";
      this.add.Item_Name=this.add.Item_Name;

      this.http.post(this.appservice.Server_URL + 'api/Master/Post_Data', this.add, { headers: this.appservice.headers })
      .subscribe(
        (val: string) => {
          this.get_Item_Master();
       
        },
        response => {
          this.toastr.error('Error ', response, {
            timeOut: 3000
          });
          
        });

    
   }



   get_Item_Master() {

   
    this.appservice.loading = true;
    this.appservice.isload = true;
    this.appservice.Item_Master_Rows = [];
    this.appservice.get("Api/Master/get_Item_Master?Order_by=" + this.appservice.Item_Orderby_Name).subscribe((res: any) => {
      this.appservice.Item_Master_Rows = JSON.parse(res).record;

      this.appservice.Perment_Item_Master_Row = JSON.parse(res).record;
      this.appservice.Reset_Item_Master_Row = JSON.parse(res).record;
      
     
      
      if(this.appservice.RM_all_item==true)
      {
        this.appservice.Item_Master_RM_Rows=this.appservice.Perment_Item_Master_Row;
      }else
      {
        this.appservice.Item_Master_RM_Rows=this.appservice.Perment_Item_Master_Row.filter(e=>e.Item_Group=="2");
      }
      
      

      this.appservice.loading = false;
      this.appservice.isload = false;


      try {
        this.appservice.Item_Row_Cout = this.appservice.Item_Master_Rows.length;

      } catch { }

        this.appservice.Item_Category = [...new Set(this.appservice.Item_Master_Rows.filter(e=>e.Item_Group=='1').map(item => item.Category))];
        this.get_uoms();
     
    });
  }
  
   clear()
   {
     
    this.appservice.get_fields_of('Item_Master').forEach((activity) => {
      if(activity.Default_Value=="T_Date")
      {
        this.add[activity.Field]=this.appservice.T_Date;
      }
      else  if(activity.Default_Value=="code_auto")
      {
        this.Auto_Code=true;

      }
      else{
      this.add[activity.Field]=activity.Default_Value;
      }
    });

    if(this.Auto_Code)
    {
      this.get_item_Code()
    }
   }

   get_item_Code()
   {
    
      if(this.appservice.isEdit==false)
      {
      this.appservice.getc("Api/Master/get_Item_Code").subscribe((res: any) => { 
        this.add.Item_Code=res;
          
      }); 
    }
      
   }

   Auto_Code:boolean=false;
  
   uploadedFiles: any[] = [];

   server;
   onUpload(event) {
       for(let file of event.files) {
           this.uploadedFiles.push(file);
       }
 
       this.toastr.success("File Uploaded  Success", 'Msg');
       
   }
 
   addData(f)
   {
    
      this.add.Company=this.appservice.Company;
      this.add.Company_ID=this.appservice.Company;
      this.add.Created_by=this.appservice.CREATED_BY;
      this.add.Table_Name="Item_Master";
      if (f.invalid === false)
      {
  
      this.btndisable=true;
            this.http.post(this.appservice.Server_URL + 'api/Master/Post_Data', this.add, { headers: this.appservice.headers })
              .subscribe(
                (val: string) => {
                  this.btndisable=false;

                  if(val=="True")
                  { this.toastr.success("Details Salved Success", 'Msg');
              
                  if(this.appservice.isEdit && this.appservice.From_Purchase==false)
                  {
                    this.appservice.back();

                  }
                
                  else
                  {
                  this.appservice.isEdit=false;
                  this.appservice.get_Item_Master();
                  $(".cls_0").focus();
                  f.submitted=false;
                  this.clear();
                  }
                }
                else{
                  this.toastr.error(val, "Error", { timeOut: 3000 });
                }
                },
                response => {
                  this.toastr.error('Error ', response, {
                    timeOut: 3000
                  });
                  
                });
     
    }
   }

  ngOnInit(): void {
  }

}

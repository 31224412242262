import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';

import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  isload:boolean=false;
  data :any={}
  constructor(public http: HttpClient,public appservice: AppService,private toastr: ToastrService, public router: Router) {

    if (this.getData()) {

      this.isload=true;
      this.data=JSON.parse(localStorage.getItem('User_Data'));
      appservice.Current_User=this.data;
      appservice.Company="_"+this.data.UM_Company;
      this.get_Token();
         
      this.appservice.get("Api/Common/get_Month?Date="+this.appservice.S_From).subscribe(( res: any) => {
        this.appservice.M_From=res;
        
        
        });
     
    }

  }

  

  get_Token() {

         this.appservice.headers = new Headers({'Content-Type': 'application/x-www-form-urlencoded'});
          this.isload=true;
          this.http.post(this.appservice.Server_URL + 'token', 'grant_type=password&UserName=admin&Password=admin', { headers: this.appservice.headers })
            .subscribe(
              (val) => {
                this.appservice.tocken=val['access_token'];
                this.get_Field_Setting();
              },
              response => {
                this.toastr.error('Error ', response, {
                  timeOut: 3000
                });
                return "Error Contact Admin";
              });
        
              //return "Problem"
      }

  
  Prow=[];
  page="";



  get_Field_Setting() {
    this.appservice.getc("Api/Setting/get_Field_Setting").subscribe((res: any) => {

      
      if(res=="No_Licence")
      {
        this.router.navigate(['/invalid-licence']);
      }
      else
      {
          this.appservice.Field_Setting = JSON.parse(res).record;
          this.get_Setting_Setting();    
      }
    });


  }


  get_variable_Value(data)
  {
    
    
   
    if (data == "Bill_Format") {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.Bill_Format = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }
    }
    else if (data == "STamilName") {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
         
          if((this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value)=="true")
          {
            this.appservice.STamilName=true;
          }
        }

      } catch { }
    }
    else if (data == "Grid_MPR_Disp") {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
         
          if((this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value)=="true")
          {
            this.appservice.Grid_MPR_Disp=true;
          }
        }

      } catch { }
    }

    else if (data == "Type_Based_Bill_No") {
      
      
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
         
          if((this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value)=="true")
          {
            this.appservice.Type_Based_Bill_No=true;
          }
        }

      } catch { }
      
    }
    else if (data == "Tax_Type") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.Tax_Type = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
    else if (data == "Bill_Format1") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.Bill_Format1 = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
    else if (data == "Quotation_Format") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.Quotation_Format = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
    else if (data == "Qubha_Quotation_Format") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.Qubha_Quotation_Format = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
    else if (data == "PO_Format") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.PO_Format = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
    else if (data == "P_Invoice_Format") 
    {
      try {``
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.P_Invoice_Format = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
    else if (data == "Delivery_Format") 
    {
      try {``
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.Delivery_Format = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
    else if (data == "DC_Format") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.DC_Format = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
    else if (data == "NT_Bill_Format") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.NT_Bill_Format = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
    else if (data == "GST_Type") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.GST_Type = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }

    else if (data == "Area_Map") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
         if((this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value)=="true")
          {
            this.appservice.Area_Map=true;
          }
        }

      } catch { }

    }
    
    else if (data == "Group_Enable") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
         if((this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value)=="true")
          {
            this.appservice.Group_Enable=true;
          }
        }

      } catch { }

    }
    
    else if (data == "DB_Vadi_display") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          if((this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value)=="true")
          {
            this.appservice.DB_Vadi_display=true;
          }
          
        }

      } catch { }

    }
    else if (data == "Ledger_Update") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          if((this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value)=="true")
          {
            this.appservice.Ledger_Update=true;
          }
          
        }

      } catch { }

    }
    else if (data == "Print_Button") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          if((this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value)=="true")
          {
            this.appservice.Print_Button=true;
          }
          
        }

      } catch { }

    }

    else if (data == "Print_Bill") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          if((this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value)=="true")
          {
            this.appservice.Print_Bill=true;
          }
          
        }

      } catch { }

    }
    else if (data == "Save_Print_Mobile") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          if((this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value)=="true")
          {
            this.appservice.Save_Print_Mobile=true;
          }
          
        }

      } catch { }

    }
    
    else if (data == "Sales_Disp_Text2_Visblle") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          if((this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value)=="true")
          {
            this.appservice.Sales_Disp_Text2_Visblle=true;
          }
          
        }

      } catch { }

    }
    else if (data == "img_visible") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          if((this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value)=="true")
          {
            this.appservice.img_visible=true;
          }
          
        }

      } catch { }

    }
    else if (data == "Sales_Disp_Text3_Visblle") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          if((this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value)=="true")
          {
            this.appservice.Sales_Disp_Text3_Visblle=true;
          }
          
        }

      } catch { }

    }
    else if (data == "Stockbase_Sales") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          if((this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value)=="true")
          {
            this.appservice.Stockbase_Sales=true;
          }
          
        }

      } catch { }

    }
    else if (data == "Purchse_Rate_Validate") 
    {
      

      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          if((this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value)=="true")
          {
           
            this.appservice.Purchse_Rate_Validate=true;
          }
          
        }

      } catch { }
      
    }
    else if (data == "RM_all_item") 
    {
    
      this.appservice.RM_all_item=false;
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          if((this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value)=="true")
          {
            this.appservice.RM_all_item=true;
          }
          
        }

      } catch { }
      
    }

    else if (data == "Save_Print") 
    {
    
      this.appservice.Save_Print=false;
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          if((this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value)=="true")
          {
            this.appservice.Save_Print=true;
          }
          
        }

      } catch { }
      
    }

    else if (data == "Dispg_code") 
    {
    
      this.appservice.Dispg_code=false;
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          if((this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value)=="true")
          {
            this.appservice.Dispg_code=true;
          }
          
        }

      } catch { }
      
    }
    else if (data == "Dispg_uom") 
    {
    
      this.appservice.Dispg_uom=false;
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          if((this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value)=="true")
          {
            this.appservice.Dispg_uom=true;
          }
          
        }

      } catch { }
      
    }


    
    else if (data == "Item_Rate_Update") 
    {
    
     
      this.appservice.Item_Rate_Update=false;
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          if((this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value)=="true")
          {
            this.appservice.Item_Rate_Update=true;
          }
          
        }
        

      } catch { }
      
    }
    else if (data == "Sales_Profit") 
    {
      

      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          if((this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value)=="true")
          {
           
            this.appservice.Sales_Profit=true;
          }
          
        }

      } catch { }
      
    }
    else if (data == "Check_Stock") 
    {
      

      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          if((this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value)=="true")
          {
           
            this.appservice.Check_Stock=true;
          }
          
        }

      } catch { }
      
    }
    else if (data == "Rate_From_Item_Master") 
    {
      

      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          if((this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value)=="true")
          {
           
            this.appservice.Rate_From_Item_Master=true;
          }
          
        }

      } catch { }
      
    }
    else if (data == "Hot_Stock_Display") 
    {
      

      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          if((this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value)=="true")
          {
           
            this.appservice.Hot_Stock_Display=true;
          }
          
        }

      } catch { }
      
    }
    else if (data == "UOM_Price_List") 
    {

      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          if((this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value)=="true")
          {
           
            this.appservice.UOM_Price_List=true;
          }
          
        }

      } catch { }
      
    }
    
    else if (data == "hotel_Menu") 
    {
      

      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          if((this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value)=="true")
          {
           
            this.appservice.hotel_Menu=true;
          }
          
        }

      } catch { }
      
    }
    else if (data == "Direct_Settlement") 
    {
      

      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          if((this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value)=="true")
          {
            this.appservice.Direct_Settlement=true;
          }
          
        }

      } catch { }
      
    }

    
    else if (data == "Logo_Name") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.Logo_Name = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
    else if (data == "Filter_Type") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.Filter_Type = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
    else if (data == "Vadi_Format") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.Vadi_Format = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
    else if (data == "Logo_Sub_Name") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.Logo_Sub_Name = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
    else if (data == "Purchase_entry_page") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.Purchase_entry_page = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
    else if (data == "Phone_No") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.Phone_No = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
    else if (data == "Lable_Takeaway") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.Lable_Takeaway = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
   
    else if (data == "Lable_Order_entry") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.Lable_Order_entry = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
    else if (data == "Lable_Dining") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.Lable_Dining = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
    else if (data == "Lable_Purchase") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.Lable_Purchase = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
    else if (data == "Sales_Disp_Text1") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.Sales_Disp_Text1 = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
    else if (data == "DF_Bill_Type") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.DF_Bill_Type = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
    else if (data == "NT_Sales_Disp_Text1") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.NT_Sales_Disp_Text1 = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
    else if (data == "Sales_Disp_Text2") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.Sales_Disp_Text2 = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
    else if (data == "Lable_Sales") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.Lable_Sales = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
    else if (data == "Lable_Receipt") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.Lable_Receipt = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
    else if (data == "Lable_Payment") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.Lable_Payment = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
    else if (data == "Lable_Expence") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.Lable_Expence = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
    else if (data == "Lable_Daybook") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.Lable_Daybook = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
    else if (data == "Hotel_Reprint") 
    {

    
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.Hotel_Reprint = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

        
    }
    else if (data == "Condi_Sales") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.Condi_Sales = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
    
    else if (data == "Lable_Receivable") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.Lable_Receivable = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
    else if (data == "Lable_Payable") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.Lable_Payable = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
    else if (data == "I_Group") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.I_Group = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
    else if (data == "Lable_Item_Master") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.Lable_Item_Master = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
    else if (data == "Lable_Ledger") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.Lable_Ledger = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
    else if (data == "Sales_Entry_Page") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.Sales_Entry_Page = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
    else if (data == "Balance_SMS") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.Balance_SMS = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
    else if (data == "Receipt_Print") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.Receipt_Print = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
    else if (data == "Lable_Reports") 
    {
      try {
        if (this.appservice.SM_Row.filter(e => e.S_Variable == data).length > 0) {
          this.appservice.Lable_Reports = this.appservice.SM_Row.filter(e => e.S_Variable == data)[0].S_Value;
        }

      } catch { }

    }
   

  }
  
  get_Setting_Setting() {
    this.appservice.getc("Api/Common/Get_Setting_Master").subscribe((res: any) => {
      this.appservice.SM_Row = JSON.parse(res).record;

      try{
    for(var i=0;i<this.appservice.SM_Row.length;i++)
    {
      this.get_variable_Value(this.appservice.SM_Row[i]["S_Variable"]);
    }
  }catch{}

      this.get_Item_Master();    
    });

  }



  get_Item_Master() {

    this.appservice.loading = true;
    this.appservice.isload=true;
    this.appservice.Item_Master_Rows=[];
    this.appservice.get("Api/Master/get_Item_Master?Order_by="+this.appservice.Item_Orderby_Name+"&Category="+this.appservice.I_Category.replace("&", "~")+"&Sub_Category="+this.appservice.Sub_Category+"&Item_Status="+this.appservice.Item_status).subscribe((res: any) => {
      this.appservice.Item_Master_Rows = JSON.parse(res).record;
   
      try
      {
    
       
for(var i=0;i<this.appservice.Item_Master_Rows.length;i++)
{
 
 
  if(Number(this.appservice.Item_Master_Rows[i]["Rate"])>0)
{
  this.appservice.Item_Master_Rows[i]["Margin"] = ((((Number(this.appservice.Item_Master_Rows[i]["MRP"])*Number(this.appservice.Item_Master_Rows[i]["Box_Qty"]))/Number(this.appservice.Item_Master_Rows[i]["Rate"]))*100)-100).toFixed(2);
}
else{
  this.appservice.Item_Master_Rows[i]["Margin"]=0;
}

}
      }catch{}


      this.appservice.Perment_Item_Master_Row = JSON.parse(res).record;
      this.appservice.Reset_Item_Master_Row = JSON.parse(res).record;
      this.appservice.loading = false;
      this.appservice.isload=false;

      
      if(this.appservice.RM_all_item==true)
      {
        this.appservice.Item_Master_RM_Rows=this.appservice.Perment_Item_Master_Row;
      }else
      {
        this.appservice.Item_Master_RM_Rows=this.appservice.Perment_Item_Master_Row.filter(e=>e.Item_Group=="2");
      }
      

      this.get_Reference();
      try{
        
        this.appservice.Item_Category = [...new Set(this.appservice.Item_Master_Rows.filter(e=>e.Item_Group=='1').map(item => item.Category))];
    // this.appservice.Item_Category = [...new Set(this.appservice.Item_Master_Rows.map(item => item.Category))];
      }catch{}
    });
  }




  get_Reference() {


    this.appservice.get_Area_Street();
    this.appservice.getc("Api/Master/get_reference").subscribe((res: any) => {
     this.appservice.Reference_Rows = JSON.parse(res).record;

     
     this.appservice.get_Ledger_Master();
     this.appservice.get_Ledger_Group();
     this.appservice.get_pay_mode_ID();
     this.appservice.Sub_Category_();
     this.appservice.get_Field_Setting();
     this.appservice.get_Role_Rights(this.data.UM_Rights);
     this.appservice.get_Item_Group() ;
   this.appservice.get_Bank_Master();
     this.appservice.get_Table_Master();
     this.appservice.get_Bill_Print_Settingc();
     this.appservice.get_Item_Name_MRP();

     this.appservice.get_User_Master();
     this.appservice.Sales_Person=this.data.UM_User_Name;
     this.appservice.CREATED_BY=this.data.UM_User_Name;
     this.appservice.Rights_ID=this.data.UM_Rights;
     try{
     this.Prow = this.appservice.Reference_Rows.filter(e => e.Ref_ID=="User_Role");
     this.appservice.Exp_Category=this.appservice.Reference_Rows.filter(e => e.Ref_ID=="Exp_Category");
     this.appservice.Rights_Name=this.Prow.filter(e => e.RGV_iID.toLowerCase()==this.data.UM_Rights)[0].RGV_vDesciption;
     this.appservice.U_Rights=this.appservice.Rights_Name.toLowerCase();

     this.appservice.Header_Disp = this.data.CM_Name;

     this.appservice.Rights_Name_=this.appservice.Rights_Name;
     if((this.appservice.Rights_Name.toLowerCase()=="admin1"))
     {
      this.appservice.Rights_Name="admin";
     }

    if(!(this.appservice.Rights_Name.toLowerCase()=="admin"))
    {
      this.appservice.Search_User=this.data.UM_User_Name;
    }

    if(this.appservice.Area_Map)
    {
     
     this.get_Area(this.appservice.Rights_Name,this.data.UM_ID);
    }
     else
     {
    this.appservice.Area_Row=this.appservice.get_ref('Area');
    this.appservice.S_Area="All";
     }
     }catch{}
     

    
     this.appservice.dashboard_data();
try
{

   this.page=this.Prow.filter(e => e.RGV_iID.toLowerCase()==this.data.UM_Rights)[0].RGV_vCode;

     this.router.navigate([this.page]);
}catch{
  this.router.navigate([this.page]);
}
    });
  }


  get_Area(Rights,user) {

    this.appservice.get("Api/Setting/Get_User_Area?Rights="+Rights+"&User="+user).subscribe((res: any) => {
    this.appservice.Area_Row = JSON.parse(res).record;
    this.appservice.S_Area="All";
    });


    if(this.appservice.Rights_Name.toLowerCase()=="admin")
     {
      this.appservice.Search_User="All";

     
     }
     else
     {
      this.appservice.Search_User="All";
      //this.appservice.Search_User=this.data.UM_User_Name;
     }
  }

  getData() {
    return JSON.parse(localStorage.getItem('User_Data'));
  }
  public btndisable:boolean=false;
  public addD: any = {};
  rows = [];

  public error_Msg="";

  login(f) {

    this.btndisable=true;
    this.appservice.get("Api/Common/get_Lodge_Login?Username=" + f.form.value.Username + "&Password=" + f.form.value.Password).subscribe((res: any) => {

      
      this.btndisable=false;

      if (res!="") {

        this.rows = JSON.parse(res).record;
        /*this.userPostData.email = this.rows[0]["Email_ID"];
          this.userPostData.name = this.rows[0]["User_Name"];
          this.userPostData.provider = this.rows[0]["Provider"];
          this.userPostData.provider_id = this.rows[0]["Tocken"];
          this.userPostData.provider_pic = this.rows[0]["Provider"];
          this.userPostData.token = this.rows[0]["Tocken"];
          this.userPostData.company = this.rows[0]["Company_ID"];
          this.userPostData.Rights = this.rows[0]["Rights"];
          localStorage.setItem('Rights',  this.rows[0]["Rights"]);*/
        this.appservice.Company="_"+this.rows[0]["UM_Company"];
        localStorage.setItem('User_Data', JSON.stringify(this.rows[0]));
        localStorage.setItem('Area','All');
        this.appservice.Customer_Area="All"
        window.location.href ="\\";
        
        this.error_Msg="";
      }
      else
      {
        this.error_Msg="Invalid User Name and Password";
        this.toastr.error("Invalid User Name and Password", "Error", { timeOut: 3000 });
        
      }

    });
  }

onKeydownEvent(event: KeyboardEvent): void {
   if (event.keyCode === 13) {
    this.login1(this.addD.Username,this.addD.Password);
   }
}

  login1(user,Pwd) {
   

    this.btndisable=true;
    this.appservice.get("Api/Common/get_Lodge_Login?Username=" + user + "&Password=" + Pwd).subscribe((res: any) => {

      
      
      this.btndisable=false;

      if (res!="") {

        this.rows = JSON.parse(res).record;
        this.appservice.Company="_"+this.rows[0]["UM_Company"];
        localStorage.setItem('User_Data', JSON.stringify(this.rows[0]));
        localStorage.setItem('Area','All');
        this.appservice.Customer_Area="All"
        window.location.href ="\\";
        
        this.error_Msg="";
      }
      else
      {
        this.error_Msg="Invalid User Name and Password";
        this.toastr.error("Invalid User Name and Password", "Error", { timeOut: 3000 });
        
      }

    });
  }

  ngOnInit() {
  }

}
